import React, { useEffect, useMemo, useState } from 'react';
import { Icon, Createtask } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from '../../helpers/common';
import { Deadview, FlagIcon, Text } from '../calendarviewCard/styles';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import CalendarDropdownMenu from './calendarDropdownMenu';
import { updateDocumentData } from '../../services/projectServices';
import { updateDocData } from '../../actions/projectActions';
import { captureException } from '../../services/logService';
import { Dropdown } from '../Dropdown';

interface Props {
  data?: any;
  dueDate?: any;
  loadData?: any;
  isUpdateReducer?: any;
  setDate?: any;
  date?: any;
  isTaskDetails?: boolean;
  isCalendarhover?: boolean;
  isTemplateProject?: boolean;
  dueResult?: { overDue: boolean; dueTime: string };
  taskGrid?: boolean;
  isTaskListPage?: boolean;
  isShareDocModel?: boolean;
  isSelectedTime?: boolean;
  isRecurringTask?: boolean;
  isHideText?: boolean;
}

const CalendarPopUp: React.FC<Props> = (props) => {
  const {
    isUpdateReducer = false,
    isTemplateProject = false,
    dueResult,
    taskGrid = false,
    isRecurringTask,
    isHideText
  } = props;
  //States
  const [calanderDate, setCalanderDate] = useState(new Date());
  const [displayState, setDisplayState] = useState('');
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { auth: userDetailsSelector, project: projectSelector, task: taskSelector } = stateSelector || {};
  const { createTaskInput } = taskSelector;
  const { userDetails } = userDetailsSelector;
  const { docData } = projectSelector;
  //Other variables
  const dateToString = createTaskInput?.dueDate;
  const dateOnly = moment(dateToString).format(userDetails?.dateFormat);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.data?.dueDate) {
      setCalanderDate(new Date(props.data.dueDate));
    }
  }, [props.data?.dueDate]);

  const handleSetLinkDueDate = (value: string) => {
    dispatch(updateDocData({ propsName: 'expiryDate', value: value }));
    const payload = !value
      ? { UnsetExpireTime: true }
      : {
          ExpiryTime: value
        };
    try {
      if (docData?.id) {
        dispatch(updateDocumentData(docData?.id, payload));
      }
    } catch (error) {
      captureException(error);
      console.log('Failed to fetch date: ', error);
    }
  };

  const dropdownContent = useMemo(() => {
    return (
      <Icon
        className='calendar-icon'
        isApplyMargin={!isEmpty(createTaskInput?.dueDate) || !isEmpty(displayState)}
        isTaskDetails={props?.isTaskDetails}
        isCalendarhover={props?.isCalendarhover}
        isShareDocModel={props?.isShareDocModel}
        taskGrid={taskGrid}>
        {props?.isUpdateReducer && (
          <Createtask
            changeIconColor={!isEmpty(createTaskInput?.dueDate)}
            isHideText={isHideText}
            className={createTaskInput?.dueDate ? 'show-text' : ''}>
            <SVGIcon name='secound-calendar-icon' width='16' height='16' viewBox='0 0 16 16' className='secondicon' />
            {createTaskInput?.dueDate ? <div className='responsive'>{dateOnly}</div> : <h6>Due date</h6>}
          </Createtask>
        )}
        {!props?.isUpdateReducer && !taskGrid && !props?.isTaskListPage && (
          <SVGIcon name='calendar-icon' width='18' height='18' viewBox='0 0 18 18' className='fill-color' />
        )}

        {props?.isTaskListPage && !isEmpty(props?.data?.dueDate) && (
          <>
            {dueResult?.overDue && !props?.data?.status ? (
              <SVGIcon
                name='tasklist-calendar-duedate-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                className='calendar-due-fill-color'
              />
            ) : (
              <SVGIcon
                name='tasklist-calendar-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                className='calendar-fill-color'
              />
            )}
          </>
        )}
        {props?.isTaskDetails && (
          <p className='taskduadate'>
            {props?.data?.dueDate
              ? moment(props?.data?.dueDate).format(`ddd, ${userDetails?.dateFormat}`)
              : 'No Due Date'}
          </p>
        )}
        {taskGrid && (
          <>
            {!isEmpty(props?.data?.dueDate) ? (
              <Deadview>
                <FlagIcon>
                  {dueResult?.overDue && !props?.data?.status ? (
                    <SVGIcon name='flag-icon' width='12' height='14' viewBox='0 0 12 14' className='redflag' />
                  ) : (
                    <SVGIcon name='flag-icon' width='12' height='14' viewBox='0 0 12 14' className='grayflag' />
                  )}
                </FlagIcon>
                <Text isOverdue={dueResult?.overDue && !props?.data?.status}>{dueResult?.dueTime}</Text>
              </Deadview>
            ) : (
              <SVGIcon
                name='calendar-icon'
                width='18'
                height='18'
                viewBox='0 0 18 18'
                className='task-grid-calander-icon'
              />
            )}
          </>
        )}

        {props?.isShareDocModel && (
          <p className='sharedoc'>
            {docData?.expiryDate
              ? moment(docData?.expiryDate).format(`ddd, ${userDetails?.dateFormat}`)
              : 'Never expire'}
          </p>
        )}
      </Icon>
    );
  }, [
    createTaskInput?.dueDate,
    dateOnly,
    displayState,
    docData?.expiryDate,
    dueResult?.dueTime,
    dueResult?.overDue,
    isHideText,
    props?.data?.dueDate,
    props?.data?.status,
    props?.isCalendarhover,
    props?.isShareDocModel,
    props?.isTaskDetails,
    props?.isTaskListPage,
    props?.isUpdateReducer,
    taskGrid,
    userDetails?.dateFormat
  ]);
  return (
    <>
      <Dropdown isMinWidth={218} content={dropdownContent} trigger='click' isRemoveMaxHeight={true}>
        <>
          <CalendarDropdownMenu
            isTemplateProject={isTemplateProject}
            isUpdateReducer={isUpdateReducer}
            date={calanderDate}
            data={props?.data}
            loadData={props?.loadData}
            calanderDate={calanderDate}
            setCalanderDate={setCalanderDate}
            isTaskDetails={props?.isTaskDetails}
            isCalendarhover={props?.isCalendarhover}
            dueResult={dueResult}
            taskGrid={taskGrid}
            setDisplayState={(value: string) => setDisplayState(value)}
            setLinkDueDate={handleSetLinkDueDate}
            isShareDocModel={props?.isShareDocModel}
            isSelectedTime={props?.isSelectedTime}
            isRecurringTask={isRecurringTask}
          />
        </>
      </Dropdown>
    </>
  );
};

export default CalendarPopUp;

import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  CustomerInvoiceModal?: boolean;
}
const ProfileDropdown = styled.div`
  padding: 20px;
`;
const DropTitle = styled.h5`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin-bottom: 8px;
  margin-top: 0px;
  &.type {
    margin-bottom: 16px;
  }
`;
const Customer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  .angledown-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-primary);
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    z-index: 999;
  }
  .styles {
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  .dropdown-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-secondary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-input-focus);
  }
  &.ant-select-item-option-content {
    color: var(--text-primary);
  }
  .ant-select-single .ant-select-selector {
    color: var(--text-primary);
    ${Typography.body_lg_medium}
  }
`;
const Client = styled.div`
  cursor: pointer;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-button);
  border-radius: 66px;
  padding: 9px;
  /* height: 16px; */
  display: flex;
  align-items: center;
  svg {
    width: 18px;
    height: 18px;
  }
  :hover {
    background-color: var(--background-secondary);
  }
  .plus-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Invoisetype = styled.div`
  margin: 16px 0px;
  padding: 16px 0px;
  border-top: 0.5px solid var(--border-primary);
  border-bottom: 0.5px solid var(--border-primary);
`;
const Formbutton = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectDiv = styled.div<Props>`
  position: relative;
  min-width: 241px;
  width: 100%;
  padding: 5px 12px;
  border-radius: 6px;
  color: var(--text-primary);
  ${Typography.body_lg};
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    margin: 0;
    max-width: 242px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props) =>
    props.CustomerInvoiceModal &&
    css`
      min-width: 314px;
      max-width: 290px;
      p {
        max-width: 295px;
      }
    `}
`;

const ItemDiv = styled.div`
  max-height: 94px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;
const IconDiv = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 12px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .radio-Button {
    ${Typography.body_sm}
  }
`;
export {
  ProfileDropdown,
  Customer,
  DropTitle,
  Invoisetype,
  Client,
  Formbutton,
  SelectDiv,
  ItemDiv,
  IconDiv,
  RadioWrapper
};

import React, { useCallback, useEffect } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  ActiveTag,
  BottomContent,
  Card,
  Cards,
  CardSection,
  Content,
  Details,
  Dot,
  Doticon,
  Group,
  Header,
  Icon,
  LeftContent,
  Personaltext,
  SessionContent,
  SubHeader,
  TitleSection
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../../component/button';
import { getSessionList, removeAllSession, removeSession } from '../../../services/settingServices';
import { useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useSelector } from 'react-redux';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import moment from 'moment';
import { setSecurityAccessList } from '../../../actions/settingActions';

interface Props {
  iconName: string;
  title: string;
  location?: string;
  lastSeen: string;
  hasRevokeButton?: boolean;
  iconClassName?: string;
  onRevoke?: () => void;
}

// card component
const DeviceCard = (props: Props) => (
  <Cards>
    <LeftContent>
      <Icon>
        <SVGIcon name={props.iconName} width='18' height='18' viewBox='0 0 18 18' className={props.iconClassName} />
      </Icon>
      <TitleSection>
        <h2>{props.title}</h2>
        <Details>
          <p>{props.location}</p>
          <Doticon>
            <Dot />
          </Doticon>
          <p>{props.lastSeen}</p>
        </Details>
      </TitleSection>
    </LeftContent>
    {props.hasRevokeButton && <Button title='Revoke' secondary hasNotBoxshadow smallbutton onClick={props.onRevoke} />}
  </Cards>
);

function SecurityAccess() {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector } = stateSelector;
  const { securityAccessList } = settingsSelector;
  //other variables
  const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
  const currentUserSessions = securityAccessList?.filter((session) => session.DeviceId === currentUser?.device_id);
  const otherSessions = securityAccessList?.filter((session) => session.DeviceId !== currentUser?.device_id);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    await dispatch(getSessionList());
  }, [dispatch]);

  const handleRevokeSession = useCallback(
    async (sessionId: string) => {
      try {
        const payload = {
          sessionId: sessionId
        };
        const response = await dispatch(removeSession(payload));
        if (response) {
          const updatedSessions = securityAccessList?.filter((session) => session?.[`_id`] !== sessionId);
          dispatch(setSecurityAccessList(updatedSessions));
        }
      } catch (error) {
        console.error('Error removing session:', error);
      }
    },
    [dispatch, securityAccessList]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAllRevokeSession = useCallback(async () => {
    try {
      const response = await dispatch(removeAllSession());
      if (response) {
        dispatch(setSecurityAccessList(currentUserSessions));
      }
    } catch (error) {
      console.error('Error removing session:', error);
    }
  }, [dispatch, currentUserSessions]);

  const getDeviceIconName = useCallback((deviceType: string) => {
    if (deviceType?.startsWith('Chrome')) {
      return 'chrome-icon';
    } else if (deviceType?.startsWith('Arc')) {
      return 'arc-icon';
    } else if (deviceType?.startsWith('Teamcamp')) {
      return 'desktop-icon';
    } else if (deviceType?.startsWith('Ipad') || deviceType?.startsWith('Mobile')) {
      return 'mobile-device-icon';
    } else if (deviceType?.startsWith('Tracker')) {
      return 'desktop-icon';
    } else {
      return 'chrome-icon';
    }
  }, []);

  return (
    <AppLayout isPosition={true}>
      <Group>
        <Header>
          <Personaltext>Security and access</Personaltext>
        </Header>
        <BottomContent>
          <SessionContent>
            <Content>
              <h2>Sessions</h2>
              <p>Devices logged into your account</p>
            </Content>
            <Card>
              <LeftContent>
                <Icon>
                  <SVGIcon
                    name={getDeviceIconName(currentUserSessions?.[0]?.DeviceType || '')}
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    className='svg-icon'
                  />
                </Icon>
                <TitleSection>
                  <h2>{currentUserSessions?.[0]?.DeviceType || 'Unknown Device'}</h2>
                  {/* <p>Surat, IN</p> */}
                </TitleSection>
              </LeftContent>
              <ActiveTag>Active</ActiveTag>
            </Card>
          </SessionContent>
          {otherSessions.length > 0 && (
            <SessionContent>
              <SubHeader>
                <h2>Other sessions</h2>
                <Button
                  title='Revoke all'
                  onClick={handleAllRevokeSession}
                  secondary={true}
                  hasNotBoxshadow
                  smallbutton={true}
                />
              </SubHeader>
              <CardSection>
                {otherSessions.map((session) => (
                  <DeviceCard
                    key={session.DeviceId}
                    iconName={getDeviceIconName(session.DeviceType) || ''}
                    title={session.DeviceType || 'Unknown Device'}
                    lastSeen={`Last seen ${moment(session.LastSeen).fromNow()}`}
                    hasRevokeButton={true}
                    iconClassName='svg-icon'
                    onRevoke={() => handleRevokeSession(session?.[`_id`])}
                  />
                ))}
              </CardSection>
            </SessionContent>
          )}
        </BottomContent>
      </Group>
    </AppLayout>
  );
}

export default SecurityAccess;

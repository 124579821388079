import { PullRequest, RawFile, RawTask, TaskTimeLog } from '../interfaces/task';
import { rootStore } from '../rootStore';

export class TaskModel {
  // Common
  id: string;
  name: string;
  description: string;
  isArchived: boolean;
  createdOn: Date;
  dueDate: Date | null;
  estimate: number | null;
  updatedTime: Date;
  createdBy: string;
  updatedBy: string;

  // Relational
  projectId: string;
  milestoneId: string | null;
  groupId: string | null;
  parentTaskId: string | null;
  companyId: string;
  statusId: string;
  taskSubscribers: string[];
  usersIds: string[];

  // Others
  index: number | null;
  files: RawFile[];
  comments: any[];
  labels: string[];
  priority: number | null;
  status: boolean;
  subtaskIndex: number | null;
  taskNumber: number;
  pullRequests: PullRequest[];
  timeLogs?: TaskTimeLog[];

  constructor(rawTask: RawTask) {
    // Common
    this.id = rawTask.id;
    this.name = rawTask.name;
    this.description = rawTask.description;
    this.isArchived = rawTask.isArchived;

    // Dates
    this.createdOn = new Date(rawTask.createdOn);
    this.dueDate = rawTask.dueDate ? new Date(rawTask.dueDate) : null;
    this.estimate = rawTask.estimate;
    this.updatedTime = new Date(rawTask.updatedTime);

    // Relational
    this.createdBy = rawTask.createdBy;
    this.updatedBy = rawTask.updatedBy;
    this.companyId = rawTask.companyId;
    this.projectId = rawTask.projectId;
    this.milestoneId = rawTask.milestoneId;
    this.groupId = rawTask.groupId;
    this.parentTaskId = rawTask.parentTaskId;
    this.statusId = rawTask.statusId;
    this.taskSubscribers = rawTask.taskSubscribers;
    this.usersIds = rawTask.users;

    // Others
    this.index = rawTask.index;
    this.files = rawTask.files;
    this.comments = rawTask.comments;
    this.labels = rawTask.labels;
    this.priority = rawTask.priority ?? null;
    this.status = rawTask.status;
    this.subtaskIndex = rawTask.subtaskIndex;
    this.taskNumber = rawTask.taskNumber;
    this.pullRequests = rawTask.pullRequests;
    this.timeLogs = rawTask.timeLogs;
  }

  get users() {
    return rootStore.workspaceStore.currentWorkspace?.users.filter((user) => this.usersIds.includes(user.id)) || [];
  }

  get subTasks() {
    return rootStore.taskStore.tasks.filter((task) => task.parentTaskId === this.id) ?? [];
  }

  get parentTask(): TaskModel | null {
    if (!this.parentTaskId) return null;
    return rootStore.taskStore.tasks.find((task) => task.id === this.parentTaskId) ?? null;
  }

  get project() {
    return rootStore.projectStore.projects.find((project) => project.id === this.projectId) ?? null;
  }
}

export const createObservableTask = (rawTask: RawTask) => {
  return new TaskModel(rawTask);
};

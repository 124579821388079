import { LinkModalInterface } from '../interfaces/ProjectInterface';
import { ThemeState } from '../interfaces/SettingsInterface';
export const DATE_FORMAT = 'YYYY-MMM-DD';
export const PLATFORM_KEY_NAME = 'platform';
export const REQUEST_CANCEL_MESSAGE = 'CANCEL';
export const USER_PREFERENCES = 'UserPreferences';
export const ONESIGNLE_APP_ID = '146d5180-8b38-4d86-8422-890aa85f7ac1';

export const USER_ROLE = {
  USER: 0,
  ADMIN: 1,
  CLIENT: 2
};

export const PROJECT_STATUS = {
  NOT_STARTED: 1,
  ON_SCHEDULE: 2,
  BEHIND_SCHEDULE: 3,
  AT_RISK: 4
};

export const PROJECT_STATUS_TEXT: { [key: number]: string } = {
  1: 'Not Started yet',
  2: 'On Schedule',
  3: 'Behind Schedule',
  4: 'At Risk'
};

export const APP_INIT_RESPONSE_TYPE = {
  SUCCESS: 'sucess',
  FAILED: 'failed',
  REDIRECT: 'redirect'
};

export const PROJECT_STATUS_COLOR: { [key: number]: string } = {
  1: 'var(--neutral-primary)',
  2: 'var(--accent-success)',
  3: 'var(--accent-warning)',
  4: 'var(--accent-highlight)'
};

export const TASK_TYPE = {
  MINE: 2,
  ALL: 3
};

export const CREATE_INVOICE_TYPE = {
  TRACKED_TIME_AND_EXPENSE: 'tracked time and expense',
  BLANK_INVOICE: 'blank invoice'
};

export const MONTHS: { [key: number]: string } = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
};

export const NOTIFICATION_TYPE = {
  INVITE: 1,
  TASK: 2,
  COMMENT: 3,
  PROJECT_INVITE: 4
};

export const TASK_LIST_TYPE = {
  TASKS: 1,
  TASK_GRID: 2,
  CALANDAR_VIEW: 3
};

export const FILE_TYPES = {
  FOLDER: 1,
  UPLOAD: 2,
  DOCS: 3,
  DRIVE: 4,
  DROPBOX: 5,
  ONEDRIVE: 6,
  FIGMA: 7,
  WHITEBOARD: 8,
  NOTION: 9,
  RESOURCES: 10,
  ADD_LINK: 11
};

export const INBOX_FILTER_TYPE = {
  ALL: 1,
  ASSIGN_TO_ME: 2,
  MENTION: 3
};

export const ACTIVITY_DETAILS_TYPE = {
  FILE: 9,
  TASK: 7,
  DOCUMENT: 10
};

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const INVOICE_TYPE = {
  OPEN: 1,
  ALL: 2
};

export const INVOICE_STATUS = {
  DRAFT: 1,
  SAVE: 3
};

export const BILLABLE_HOURES = {
  ALL_UNINVOICED_BILLABLE_HOURES: 1,
  INCLUDE_UNINVOICES_BILLABLE_HOURES_FROM: 2,
  DO_NOT_INCLUDE_ANY_HOURS: 3
};

export const DISPLAY_HOURES = {
  BY_TASK: 1,
  BY_PERSON: 2,
  BY_PROJECT: 3,
  BY_TASKGROUP: 4,
  DETAILED: 5
};

export const ACTIVITY_ACTION: { [key: number]: string } = {
  1: 'Added',
  3: 'Updated',
  6: 'Archived',
  4: 'Deleted'
};

export const ACTIVITY_TYPE_ID = {
  PROJECT_USERS: 5,
  PROJECT: 6,
  TASK: 7,
  FILE: 9,
  DOCUMENT: 10
};

export const ACTIVITY_TYPE: { [key: number]: string } = {
  5: 'Project Users',
  6: 'Project',
  7: 'Task',
  9: 'File',
  10: 'Document',
  13: 'Task Group',
  14: 'Priority Setting',
  15: 'Estimation Setting',
  16: 'Milestone Setting'
};

export const SHOW_TASK_FILTER_TYPE = {
  ALL: 0,
  OPEN: 1,
  CLOSE: 2
};

export const SHOW_TASK_GROUPING_TYPE = {
  NO_GROUPING: 0,
  DEFAULT: 1,
  GROUPING_BY_PRIORITY: 2,
  GROUPING_BY_USER: 3,
  GROUPING_BY_MILESTONE: 4,
  GROUPING_BY_STATUS: 5,
  GROUPING_BY_GROUP: 6
};

export const SHOW_TASK_BY_ORDER = {
  DUE_DATE: 1,
  CREATED_DATE: 2,
  CUSTOM: 3,
  PRIORITY: 4
};

export const INVOICE_STATUS_TYPE = {
  NONE: 0,
  DRAFT: 1,
  OVER_DUE: 2,
  PENDING: 3,
  PARTIALLY_PAID: 4,
  PAID: 5
};

export const INVOICE_STATUS_TEXT = {
  0: 'NONE',
  1: 'DRAFT',
  2: 'OVER DUE',
  3: 'PENDING',
  4: 'PARTIALLY PAID',
  5: 'PAID'
};

export const TASK_PRIORITY_LABELS: { [key: number]: string } = {
  0: 'No Priority',
  1: 'Urgent',
  2: 'High',
  3: 'Medium',
  4: 'Low'
};

export const TASK_PRIORITY = {
  NO_PRIORITY: 0,
  URGENT: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4
};

export const TASK_REPORT_TIME_TYPE = {
  PAST: 0,
  FUTURE: 1,
  NODATE: 2
};

export const TASK_REPORT_STATUS = {
  PENDING: 0,
  COMPLETED: 1
};

export const MY_TASKS_GROUP_FILTER_TYPE = {
  GROUP: 5,
  DUE_DATE: 1,
  PROJECT: 2,
  PRIORITY: 3,
  NONE: 4
};

export const MY_TASKS_GROUP_FILTER_TYPE_LABELS: { [key: number]: string } = {
  5: 'Group',
  1: 'Due Date',
  2: 'Project',
  3: 'Priority'
};

export const COMMENT_TYPE = {
  COMMENT: 0,
  SET_DUE_DATE: 1,
  CHANGE_DUE_DATE: 2,
  ADD_ASSIGNEE: 3,
  REMOVE_ASSIGNEE: 4,
  UNASSIGN: 5,
  CHANGE_PRIORITY: 6,
  CHANGE_GROUP: 7,
  UPDATE_TASK: 8,
  REMOVE_DUE_DATE: 9,
  STATUS_CHANGE: 10,
  ESTIMATE_TIME: 11,
  AUDIO: 12,
  SET_MILESTONE: 13,
  ADD_LABEL: 14,
  REMOVE_LABEL: 15,
  STATUS_ID_CHANGE: 16
  // ADD_BRANCH: 17
};

export const WORKSPACE_TEAM_TYPE: { [key: number]: string } = {
  1: 'Personal',
  2: 'Freelance Business',
  3: 'Team/Organization'
};

export const TIME_REPORT_VIEW_TYPE_ID = {
  BY_PROJECT: 1,
  BY_MEMBER: 2,
  BY_ACTIVITY: 3
};

export const TIME_REPORT_DATA_FILTER_ID = {
  WORKSUMMARY: 1,
  TIMESHEET: 2,
  ACTIVITYLOG: 3
};

export const TIME_DATE_SELECTED_TEXT = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  LAST_7_DAYS: 'Last 7 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_30_DAYS: 'Last 30 Days',
  CUSTOM: 'Custom'
};

export const TIME_REPORT_CHART_TYPE_ID = {
  PROJECT: 1,
  MEMBER: 2
};

export const COLOR_THEME = {
  LIGHT: 1,
  DARK: 2,
  SYSTEM_DEFAULT: 3
};

export const COLOR_THEME_TYPE = {
  LIGHT: 'light',
  DARK: 'dark',
  SYSTEM: 'system'
};

export const CUSTOMER_MEMBER_ROLE = {
  NO_ACCESS: 1,
  PROJECT_ONLY: 2,
  FULL_ACCESS: 3
};
export const FILE_EXTENTIONS = [
  'iconpdf',
  '.folder',
  '.docx',
  '.jpg',
  '.avif',
  '.png',
  '.jpeg',
  '.doc',
  '.pdf',
  '.mp4',
  '.webm',
  '.pptx',
  '.psd',
  '.skech',
  '.html',
  '.zip',
  '.mp3',
  '.ai',
  '.xd',
  '.xlsx',
  '.xls',
  '.figma'
];

export const FILES_LIST_TYPE = {
  LIST: 1,
  GRID: 2
};
export const SHARE_TYPE = {
  snap: 1,
  doc: 2
};

export const LABEL_COLOURS = [
  { label: 'Red', color: 'var(--accent-error)' },
  { label: 'Orange', color: 'var(--accent-caution)' },
  { label: 'Yellow', color: 'var(--accent-highlight)' },
  { label: 'Green', color: 'var(--accent-success)' },
  { label: 'Teal', color: 'var(--accent-primary)' },
  { label: 'Purple', color: 'var(--accent-secondary)' },
  { label: 'Dark grey', color: '#81878E' },
  { label: 'Grey', color: 'var(--neutral-primary)' }
];

export const LABEL_COLOUR_HASH: { [key: string]: string } = {
  Red: 'var(--accent-error)',
  Orange: 'var(--accent-caution)',
  Yellow: 'var(--accent-highlight)',
  Green: 'var(--accent-success)',
  Teal: 'var(--accent-primary)',
  Purple: 'var(--accent-secondary)',
  'Dark grey': '#81878E',
  Grey: 'var(--neutral-primary)'
};

export const CHAT_GROUP_TYPE = {
  PERSONAL: 1,
  PROJECT: 2
};

export const INVOICING_DUE_ISSUE = {
  UPON_RECEIPT: 0,
  TEN_DAYS: 10,
  FIFTEEN_DAYS: 15,
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60
};

export const imageExtensions = ['.jpg', '.png', '.jpeg'];
export const videoExtensions = ['.mp4', '.mov', '.mkv', '.webm'];
export const audioExtensions = ['.mp3'];

export const TIMER_STATUS = {
  START: 1,
  PAUSE: 2,
  STOP: 3
};

export const TABS_COMMAND_K = {
  1: 'All',
  2: 'Projects',
  3: 'People'
};

export const TABS_COMMAND_K_ID = {
  ALL: 1,
  PROJECTS: 2,
  PEOPLE: 3
};

export const dateFormats = [
  'DD MMM YYYY',
  'MMM DD, YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
  'YYYY-MM-DD',
  'MM-DD-YYYY',
  'DD-MM-YYYY',
  'YYYY.MM.DD',
  'MM.DD.YYYY',
  'DD.MM.YYYY',
  'YY/MM/DD',
  'DD/MM/YY',
  'MM/DD/YY'
];

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

export const STATUS_TYPE = {
  BACKLOG: 1,
  UNSTARTED: 2,
  IN_PROGRESS: 3,
  IN_REVIEW: 4,
  COMPLETED: 5,
  CANCELLED: 6
};

export const SHOW_COMPLETED_TASK_BY_DATE = {
  ALL: 1,
  YESTERDAY: 2,
  LAST_WEEK: 3,
  LAST_MONTH: 4,
  NONE: 5
};

export const SHOW_COMPLETED_TASK_FILTER_NAME: { [key: number]: string } = {
  1: 'All',
  2: 'Yesterday',
  3: 'Last Week',
  4: 'Last Month',
  5: 'None'
};

export const PRIORITY_ICON_NAMES: { [key: number]: string } = {
  0: 'No-priority',
  1: 'Urgent',
  2: 'High',
  3: 'Medium',
  4: 'low'
};
export const MENU_OPTIONS = {
  EDIT: 1,
  DELETE: 2,
  SETTING: 3,
  ARCHIVE: 4,
  CLONE: 5,
  VIEW: 6,
  DUPLICATE: 7,
  RESTORE: 8,
  COPYLINK: 9,
  ADDLINK: 10
};

export const FILES_MENU_OPTIONS = {
  VIEW: 1,
  RENAME: 2,
  MOVETOFOLDER: 3,
  DELETE: 4,
  DOWNLOAD: 5
};

export const SETTING_TEAMS_MENU_OPTION = {
  ADMIN: 1,
  DELETE: 2
};

export const PROJECT_FILTER_TYPE = {
  ALPHABETIC: 1,
  CREATED_DATE: 2
};

export const TEMPLATE_DOC_MENU_OPTION = {
  PRD_DOCUMENTATION: 1,
  PROJECT_FLOW: 2
};

export const UPLOAD_FILES_MENU_OPTION = {
  UPLOAD_FILE: 1,
  SELECT_FILE: 2
};

export const PROJECT_SETTINGS_MENU_OPTION = {
  EDIT: 1,
  SET_AS_DEFAULT: 2,
  DELETE: 3
};

export const THEME_STATES: { [key: string]: ThemeState } = {
  light: { light: true, dark: false, system: false },
  dark: { light: false, dark: true, system: false },
  system: { light: false, dark: false, system: true }
};

export const priorityList = [
  { key: 0, name: 'No Priority', iconName: 'Priority-No-priority-icon' },
  { key: 1, name: 'Urgent', iconName: 'Priority-Urgent-icon' },
  { key: 2, name: 'High', iconName: 'Priority-High-icon' },
  { key: 3, name: 'Medium', iconName: 'Priority-Medium-icon' },
  { key: 4, name: 'Low', iconName: 'Priority-low-icon' }
];
export const REPORT_PAYMENT_LOADINGSTATE_DATA = [
  {
    title: 'Invoice No',
    columnWidth: 100,
    contentWidth: 48
  },
  {
    title: 'Projects',
    columnWidth: 362,
    contentWidth: 270
  },
  {
    title: 'Client Company',
    columnWidth: 170,
    contentWidth: 92
  },
  {
    title: 'Paid On',
    columnWidth: 170,
    contentWidth: 59
  },
  {
    title: 'Paid Amount',
    columnWidth: 170,
    contentWidth: 92,
    alignPlace: '-webkit-right'
  }
];

export const REPORT_INVOICE_LOADINGSTATE_DATA = [
  {
    title: 'Status',
    columnWidth: 100,
    contentWidth: 64
  },
  {
    title: 'Paid On',
    columnWidth: 103,
    contentWidth: 64
  },
  {
    title: 'Due On',
    columnWidth: 99,
    contentWidth: 64
  },
  {
    title: 'Invoice No',
    columnWidth: 120,
    contentWidth: 64
  },
  {
    title: 'Projects Name',
    columnWidth: 320,
    contentWidth: 216
  },
  {
    title: 'Rate',
    columnWidth: 120,
    contentWidth: 82,
    alignPlace: '-webkit-right'
  },
  {
    title: 'Discount',
    columnWidth: 100,
    contentWidth: 82,
    alignPlace: '-webkit-right'
  }
];

type FileType =
  | 'FIGMA'
  | 'NOTION'
  | 'ONEDRIVE'
  | 'DROPBOX'
  | 'DRIVE'
  | 'WHITEBOARD'
  | 'DOCS'
  | 'FOLDER'
  | 'RESOURCES'
  | 'FILE'
  | 'ADDLINK';

export const LINK_FILE_DATA: Record<FileType, LinkModalInterface> = {
  FIGMA: {
    modalType: FILE_TYPES.FIGMA,
    modal: 'Figma',
    name: 'modal-figma-icon',
    title: 'Add files from Figma',
    placeholder: 'What’s the title for this?',
    label: 'Paste the Figma link here or',
    href: 'https://www.figma.com',
    placeholder2: 'Paste the Figma link here...'
  },
  NOTION: {
    modal: 'Notion',
    modalType: FILE_TYPES.NOTION,
    name: 'modal-notion-icon',
    title: 'Add files from Notion',
    placeholder: 'What’s the title for this?',
    label: 'Paste the Notion link here',
    placeholder2: 'Paste the Notion link here...'
  },
  ONEDRIVE: {
    modal: 'OneDrive',
    modalType: FILE_TYPES.ONEDRIVE,
    name: 'modal-onedrive-icon',
    title: 'Add files from OneDrive',
    placeholder: 'What’s the title for this?',
    label: 'Paste the OneDrive link here or',
    href: 'https://onedrive.live.com/?id=root',
    placeholder2: 'Paste the OneDrive link here...'
  },
  DROPBOX: {
    modal: 'Dropbox',
    modalType: FILE_TYPES.DROPBOX,
    name: 'modal-dropbox-icon',
    title: 'Add files from Dropbox',
    placeholder: 'What’s the title for this?',
    label: 'Paste the Dropbox link here or',
    href: 'https://www.dropbox.com/login',
    placeholder2: 'Paste the Dropbox link here...'
  },
  DRIVE: {
    modal: 'Google Drive',
    modalType: FILE_TYPES.DRIVE,
    name: 'modal-googledrive-icon',
    title: 'Add files from Google Drive',
    placeholder: 'What’s the title for this?',
    label: 'Paste the Google Drive link here or',
    href: 'https://drive.google.com/drive/my-drive',
    placeholder2: 'Paste the Google Drive link here...'
  },
  WHITEBOARD: {
    modalType: FILE_TYPES.WHITEBOARD,
    title: 'Create Whiteboard',
    placeholder: 'Enter Whiteboard name',
    name: 'Whiteboard'
  },
  DOCS: {
    modalType: FILE_TYPES.DOCS,
    title: 'Create Document',
    placeholder: 'Enter Document name',
    name: 'Document'
  },
  FOLDER: {
    modalType: FILE_TYPES.FOLDER,
    title: 'Create Folder',
    placeholder: 'Enter Folder name',
    name: 'Folder'
  },
  FILE: {
    modalType: FILE_TYPES.FOLDER,
    title: 'Rename File',
    placeholder: 'Enter File name',
    name: 'File'
  },
  RESOURCES: {
    modalType: FILE_TYPES.RESOURCES,
    name: 'copy-link-icon',
    title: 'Add link to project',
    updateTitle: 'Edit link',
    label1: 'Title (Optional)',
    placeholder: 'What’s the title for this?',
    label: 'URL',
    placeholder2: 'Paste the link here'
  },
  ADDLINK: {
    modalType: FILE_TYPES.ADD_LINK,
    name: 'copy-link-icon',
    title: 'Add link to task',
    updateTitle: 'Edit link',
    label1: 'Title (Optional)',
    placeholder: 'What’s the title for this?',
    label: 'URL',
    placeholder2: 'Paste the link here'
  }
};

export const CustomerDetailMoreMenu = [
  {
    key: MENU_OPTIONS.EDIT,
    label: 'Edit'
  },
  {
    key: MENU_OPTIONS.DELETE,
    label: 'Delete'
  }
];

export const TimeLogmenuItems = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'Last 7 days',
  'This month',
  'Last month'
];

export const dropdownMenuItems = [
  { key: MENU_OPTIONS.EDIT, label: 'Edit' },
  { key: MENU_OPTIONS.DELETE, label: 'Delete' }
];
export const roleOptions = [
  {
    key: 1,
    iconName: 'no-icon',
    label: 'No Access',
    description: 'No login access provided',
    roleValue: 1,
    iconTone: 'stroke'
  },
  {
    key: 2,
    iconName: 'project-only-icon',
    label: 'Project Only',
    description: 'This will have only project access',
    roleValue: 2
  },
  {
    key: 3,
    iconName: 'access-icon',
    label: 'Full Access',
    description: 'This will have project and invoice access',
    roleValue: 3
  }
];

export const InvoiceMoreMenuItems = [
  { key: MENU_OPTIONS.VIEW, label: 'View' },
  { key: MENU_OPTIONS.EDIT, label: 'Edit' },
  { key: MENU_OPTIONS.DUPLICATE, label: 'Duplicate' },
  { key: MENU_OPTIONS.DELETE, label: 'Delete' }
];

export const InvoiceDraftMoreMenuItem = [
  { key: MENU_OPTIONS.EDIT, label: 'Edit' },
  { key: MENU_OPTIONS.DUPLICATE, label: 'Duplicate' },
  { key: MENU_OPTIONS.DELETE, label: 'Delete' }
];

export const DRAFT_MESSAGE_TYPE = {
  MESSAGE: 1,
  COMMENT: 2
};

export const RESOURCE_TYPE = {
  FILE: 1,
  LINK: 2
};

export const PrioritySortOrder = [
  TASK_PRIORITY.URGENT,
  TASK_PRIORITY.HIGH,
  TASK_PRIORITY.MEDIUM,
  TASK_PRIORITY.LOW,
  TASK_PRIORITY.NO_PRIORITY
];

export const STATUS_ORDER = [
  STATUS_TYPE.UNSTARTED,
  STATUS_TYPE.IN_PROGRESS,
  STATUS_TYPE.IN_REVIEW,
  STATUS_TYPE.BACKLOG,
  STATUS_TYPE.COMPLETED,
  STATUS_TYPE.CANCELLED
];

export const DEFAULT_PROJECT_TASK_FILTER = {
  grouping: SHOW_TASK_GROUPING_TYPE.DEFAULT,
  order: SHOW_TASK_BY_ORDER.CUSTOM,
  viewType: TASK_LIST_TYPE.TASKS,
  showSubTask: false,
  showEmptyGroup: true,
  showRecentlyCompletedTask: false,
  showCompletedTaskByFilter: SHOW_COMPLETED_TASK_BY_DATE.LAST_WEEK
};

export const invoiceDueOptions = [
  { id: 'upon_receipt', value: INVOICING_DUE_ISSUE.UPON_RECEIPT, label: 'Upon receipt' },
  { id: 'ten_days', value: INVOICING_DUE_ISSUE.TEN_DAYS, label: 'After :num days (NET 10)' },
  { id: 'fifteen_days', value: INVOICING_DUE_ISSUE.FIFTEEN_DAYS, label: 'After :num days (NET 15)' },
  { id: 'thirty_days', value: INVOICING_DUE_ISSUE.THIRTY_DAYS, label: 'After :num days (NET 30)' },
  { id: 'sixty_days', value: INVOICING_DUE_ISSUE.SIXTY_DAYS, label: 'After :num days (NET 60)' }
];

export const displayHoursOptions = [
  {
    id: 'by-task',
    value: DISPLAY_HOURES.BY_TASK,
    label: 'By Task: Combine all hours for one task into one line'
  },
  {
    id: 'by-person',
    value: DISPLAY_HOURES.BY_PERSON,
    label: 'By Person: Combine all hours for one person into one line'
  },
  {
    id: 'by-project',
    value: DISPLAY_HOURES.BY_PROJECT,
    label: 'By Project: Combine all hours for one project into one line'
  }
];

export const invoiceTypeOptions = [
  {
    id: 'tracked-time',
    value: CREATE_INVOICE_TYPE.TRACKED_TIME_AND_EXPENSE,
    label: 'Create an invoice for tracked time and expenses'
  },
  {
    id: 'blank-invoice',
    value: CREATE_INVOICE_TYPE.BLANK_INVOICE,
    label: 'Create a blank invoice'
  }
];

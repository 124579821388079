import React, { useCallback, useMemo, useState } from 'react';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import {
  SubTitle,
  RightBar,
  Listening,
  RightItemBlock,
  RightSubTitle,
  TimeLog,
  TimerHead,
  TimeLogTitle,
  TimeLogContent,
  RightAssign,
  RightItemBlocks,
  Timer,
  Action,
  Record,
  Time,
  Text,
  OverLayDiv,
  StartButton,
  ProgressBar,
  ProgressWrapper,
  Timelog
} from './styles';
import AssignBox from '../../../../../component/dropdowns/assignPopup';
import { MilestoneListInterface, StatusListInterface } from '../../../../../interfaces/ProjectInterface';
import {
  isEmpty,
  floatToHHMMSS,
  getFloatTimeValue,
  getUTCDate,
  toHHMMSS,
  formatTime
} from '../../../../../helpers/common';
import moment from 'moment';
import CalendarPopUp from '../../../../../component/calendarPopUp';
import Prioritydopdown from '../../../../../component/dropdowns/priorityDropdown';
import { COMMENT_TYPE } from '../../../../../global/constants';
import Estimatedropdown from '../../../../../component/dropdowns/estimatedropdown';
import TaskStatusDropdown from '../../../../../component/dropdowns/taskStatusDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import {
  createNewComment,
  getMyTaskDetailsData,
  getTimelogData,
  updateTaskDetails
} from '../../../../../services/taskServices';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import { setSuccessMessage } from '../../../../../actions/messageActions';
import {
  TaskDetailsInterface,
  TaskGroupInterface,
  UpdateCommentReducer
} from '../../../../../interfaces/TaskInterface';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import { updateMyTaskDetailsData } from '../../../../../actions/taskActions';
import { nanoid } from 'nanoid';
import {
  addTimeLogDetails,
  getActiveTime,
  startTracker,
  stopTracker,
  updateTimeLogDetails
} from '../../../../../services/timeTrackingServices';
import { TrackingActivityInterface } from '../../../../../interfaces/TimeTrackingInterface';
import { LabelDropdown } from '../../../../../component/dropdowns/labeldropdown/labelDropdownMenu';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';
import { DropdownButton, DropdownItem, Dropdown } from '../../../../../component/Dropdown';
import Statusdropdown from '../../../../../component/dropdowns/statusDropdown';
import Tooltip from '../../../../../component/Tooltip';

interface Props {
  onUpdateStatus: (
    status: StatusListInterface,
    previousStatus: StatusListInterface,
    task: TaskDetailsInterface,
    isSubTask?: boolean
  ) => void;
  loadData: () => void;
  setIsMilestonmodelopen?: (value: boolean) => void;
  setAlertModalOpen?: (value: boolean) => void;
  isTaskDetailPreview?: boolean;
  taskId: string;
  updateSortedComment: (value: UpdateCommentReducer) => void;
}

export default function RightBlock(props: Props) {
  const { loadData, onUpdateStatus, setAlertModalOpen, isTaskDetailPreview, taskId, updateSortedComment } = props || {};
  // States
  const [date, setDate] = useState<Date>(new Date());
  const [isEstimateDropdownOpen, setIsEstimateDropdownOpen] = useState(false);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    task: taskSelector,
    project: projectSelector,
    workspace: workspaceSelector,
    auth: authSelector,
    timeTracking: timeTrackingSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { customerRole } = workspace;
  const { taskDetails, timelogData, taskGroups } = taskSelector;
  const { userDetails } = authSelector;
  const { trackingActivity, currentStartTime } = timeTrackingSelector;
  const { currentProject, milestoneList } = projectSelector;

  // Other variables
  const dispatch = useDispatch();

  const removeTaskMilestone = useCallback(async () => {
    if (!isEmpty(taskDetails?.milestoneId)) {
      dispatch(updateMyTaskDetailsData({ propsName: 'milestoneId', value: null }));
      const payloadTask = { milestoneIdUnset: true, projectId: taskDetails?.projectId };
      await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
    }
  }, [dispatch, taskDetails]);

  const updateTaskMilestone = useCallback(
    async (task: MilestoneListInterface) => {
      const updatedComments = {
        type: COMMENT_TYPE.SET_MILESTONE,
        group: task?.milestoneName
      };
      const updatedComment = updateSortedComment(updatedComments);
      dispatch(
        updateMyTaskDetailsData({
          propsName: 'updatedComments',
          value: updatedComment
        })
      );
      dispatch(updateMyTaskDetailsData({ propsName: 'milestoneId', value: task?.id }));
      const payloadTask = { milestoneId: task?.id, projectId: taskDetails?.projectId };
      const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(taskDetails?.id, {
          Type: COMMENT_TYPE.SET_MILESTONE,
          Group: task?.milestoneName
        })
      );
      if (result && commentResult) {
        trackAnalyticActivity(TASK_ANALYTICS.MILESTONE_SELECTED);
      }
    },
    [dispatch, taskDetails?.id, taskDetails?.projectId, updateSortedComment]
  );

  const getMilestoneName = useCallback(() => {
    const milestoneName = milestoneList.find((item) => item?.id === taskDetails?.milestoneId);
    return milestoneName?.milestoneName;
  }, [milestoneList, taskDetails?.milestoneId]);

  const onClickSetEstimateTime = useCallback(
    async (time: string) => {
      if (getFloatTimeValue(time) !== taskDetails?.estimate) {
        dispatch(updateMyTaskDetailsData({ propsName: 'estimate', value: time }));
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const updatedComments = [
          ...(taskDetails?.updatedComments || []),
          {
            id: nanoid(),
            type: COMMENT_TYPE.ESTIMATE_TIME,
            updatedBy: userDetails?.id,
            updatedTime: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            user: { ...userDetails, name: userDetails?.given_name },
            EstimateTime: getFloatTimeValue(time || '')
          }
        ];
        dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
        const payloadTask = { estimateTime: getFloatTimeValue(time || ''), projectId: taskDetails?.projectId };
        const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(taskDetails?.id, {
            Type: COMMENT_TYPE.ESTIMATE_TIME,
            EstimateTime: getFloatTimeValue(time || '')
          })
        );

        if (result && commentResult) {
          trackAnalyticActivity(TASK_ANALYTICS.ESTIMATION_SET);
          await dispatch(getMyTaskDetailsData(taskId));
        }
      }
    },
    [dispatch, taskId, taskDetails]
  );

  const startTime = useCallback(async () => {
    if (trackingActivity?.length > 0) {
      if (setAlertModalOpen) setAlertModalOpen(true);
      return;
    }
    const payload = {
      project_id: currentProject?.id,
      task_id: taskId,
      date: getUTCDate(new Date()).toISOString(),
      hours: 0,
      startTime: true
    };
    const result = await dispatch(addTimeLogDetails(payload));
    if (result) {
      dispatch(startTracker(taskId));
      dispatch(getActiveTime());
      dispatch(getTimelogData(currentProject?.id, taskId));
    }
  }, [trackingActivity?.length, currentProject?.id, taskId, dispatch, setAlertModalOpen]);

  const onClickStartStop = useCallback(
    async (itemId: string, startingTime: string, item: TrackingActivityInterface) => {
      const date1 = new Date(startingTime).getTime();
      const date2 = new Date().getTime();
      const timeDifference = date2 - date1; // Difference in milliseconds
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      const updatedHistory = item?.History?.map((history) => {
        const { __typename, ...other } = history;
        return other;
      });

      const historyHoursSum = item?.History?.reduce((total, historyItem) => {
        return total + (historyItem?.Hours || 0);
      }, 0);

      const payloadUpdate = {
        Hours: Number(Number(historyHoursSum || 0) + hoursDifference),
        TimerStartedAtUnset: true,
        History: [
          ...(updatedHistory || []),
          {
            _id: nanoid(),
            CreatedBy: userDetails?.id,
            CreatedTime: new Date().toISOString(),
            UpdatedBy: userDetails?.id,
            UpdatedTime: new Date().toISOString(),
            TimerStartedAt: startingTime,
            TimerEndedAt: new Date().toISOString(),
            Hours: hoursDifference
          }
        ]
      };
      const result = await dispatch(updateTimeLogDetails(itemId, payloadUpdate));

      if (result) {
        dispatch(stopTracker());
        dispatch(setSuccessMessage('Time Stopped!'));
        await dispatch(getTimelogData(currentProject?.id, taskId));
      }
      await dispatch(getActiveTime());
    },
    [currentProject?.id, dispatch, taskId]
  );

  const getTotalTrackedSeconds = useCallback(() => {
    const totalHours = timelogData.reduce((sum, item) => sum + item.hours, 0);
    return totalHours * 60 * 60;
  }, [timelogData]);

  const isTaskOverdue = useCallback(() => {
    if (
      taskDetails?.estimate &&
      taskDetails?.estimate > 0 &&
      getTotalTrackedSeconds() + currentStartTime?.time > taskDetails?.estimate * 3600
    )
      return true;
    else return false;
  }, [currentStartTime?.time, getTotalTrackedSeconds, taskDetails?.estimate]);

  // render milestone data
  const renderMilestoneData = useMemo(() => {
    return milestoneList?.map((mileStone) => (
      <DropdownItem
        key={mileStone['_id']}
        label={mileStone?.milestoneName}
        isSelected={String(taskDetails?.milestoneId) === mileStone?.id}
        onClick={() => {
          if (String(taskDetails?.milestoneId) === mileStone?.id) {
            removeTaskMilestone();
          } else {
            updateTaskMilestone(mileStone);
          }
        }}></DropdownItem>
    ));
  }, [milestoneList, removeTaskMilestone, taskDetails?.milestoneId, updateTaskMilestone]);

  const updateTaskGroup = useCallback(
    async (item: TaskGroupInterface) => {
      if (item?.id !== taskDetails?.groupId) {
        const previousGroup = taskGroups?.find((x) => x?.id === taskDetails?.groupId);
        const payload = {
          ...taskDetails,
          companyId: workspace?.id,
          groupId: item?.id
        };
        if (payload?.subscribers) {
          delete payload?.subscribers;
        }
        if (payload?.createdBy) {
          delete payload?.createdBy;
        }
        dispatch(updateMyTaskDetailsData({ propsName: 'groupId', value: item?.id }));
        const newComment = {
          type: COMMENT_TYPE.CHANGE_GROUP,
          previousGroup: previousGroup?.name,
          group: item?.name
        };
        const updatedComment = updateSortedComment(newComment);
        dispatch(
          updateMyTaskDetailsData({
            propsName: 'updatedComments',
            value: updatedComment
          })
        );
        const payloadTask = { groupId: item?.id, projectId: taskDetails?.projectId };
        const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(taskDetails?.id, {
            Type: COMMENT_TYPE.CHANGE_GROUP,
            PreviousGroup: previousGroup?.name,
            Group: item?.name
          })
        );

        if (result && commentResult) {
          trackAnalyticActivity(TASK_ANALYTICS.GROUP_CHANGED);
        }
      }
    },
    [taskDetails, taskGroups, workspace?.id, dispatch, updateSortedComment]
  );

  const getGroupName = useCallback(() => {
    if (!taskDetails?.groupId) return;
    const groupName = taskGroups.find((item) => item?.id === taskDetails?.groupId);
    return groupName;
  }, [taskGroups, taskDetails]);

  return (
    <>
      <RightBar isTaskDetailPreview={isTaskDetailPreview}>
        <Listening hideBorderBottom={!isEmpty(customerRole)}>
          {currentProject?.statusEnable && currentProject?.statusData?.length > 0 && (
            <RightItemBlocks>
              <RightSubTitle className='left'>Status</RightSubTitle>
              <TaskStatusDropdown
                defaultStatus={currentProject?.defaultStatus}
                statusList={currentProject?.statusData}
                currentStatusId={taskDetails?.statusId}
                onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                  onUpdateStatus(status, previousStatus, taskDetails, false)
                }
              />
            </RightItemBlocks>
          )}
          {currentProject?.isGroupEnabled && taskGroups?.length > 0 && (
            <RightItemBlocks>
              <RightSubTitle className='left'>Group</RightSubTitle>
              <Statusdropdown onUpdateGroup={(group) => updateTaskGroup(group)} value={getGroupName()} />
            </RightItemBlocks>
          )}
          {currentProject?.priority?.enabled === true && (
            <RightItemBlocks>
              <RightSubTitle className='left'>Priority</RightSubTitle>
              <Prioritydopdown
                taskData={taskDetails}
                currentProject={currentProject}
                isFeature={true}
                updateSortedComment={updateSortedComment}
              />
            </RightItemBlocks>
          )}
          {currentProject?.isMilestone === true && (
            <RightItemBlocks>
              <RightSubTitle className='left'>Milestone</RightSubTitle>
              <Dropdown
                content={
                  <DropdownButton
                    title={
                      taskDetails?.milestoneId && !isEmpty(getMilestoneName()) ? getMilestoneName() : 'Select Milestone'
                    }
                    iconName='milestone-option-icon'
                    variant='hover-border'
                    iconTone='stroke'
                    isActive
                  />
                }
                trigger='click'
                modalTitle='Milestones'>
                {renderMilestoneData}
              </Dropdown>
            </RightItemBlocks>
          )}
          {currentProject?.labelsEnabled && currentProject?.labelsList?.length > 0 && (
            <RightItemBlocks className={currentProject?.labelsEnabled && 'label'}>
              <RightSubTitle className='left'>Label</RightSubTitle>
              <LabelDropdown taskData={taskDetails} currentProject={currentProject} isFromTaskDetails={true} />
            </RightItemBlocks>
          )}
          {currentProject?.isEstimate && (
            <RightItemBlocks>
              <RightSubTitle className='left'>Estimate</RightSubTitle>
              <Estimatedropdown
                isEstimateDropdownOpen={isEstimateDropdownOpen}
                setIsEstimateDropdownOpen={(value: boolean) => setIsEstimateDropdownOpen(value)}
                isTaskDetails={true}
                taskData={taskDetails}
                setEstimate={(value: string) => onClickSetEstimateTime(value)}
              />
            </RightItemBlocks>
          )}
          <RightItemBlocks>
            <RightSubTitle className='left'>Due Date</RightSubTitle>
            <CalendarPopUp data={taskDetails} date={date} setDate={setDate} isTaskDetails={true} />
          </RightItemBlocks>
          <RightItemBlock isShowHover={true}>
            <RightSubTitle className='left'>Assign</RightSubTitle>
            <RightAssign className='right'>
              <AssignBox
                isDropdownOpen={isAssignDropdownOpen && taskDetails?.id === currentAssignBox}
                setIsDropdownOpen={setIsAssignDropdownOpen}
                isTaskdetail={true}
                loadData={loadData}
                projectUsers={currentProject?.users}
                currentTask={taskDetails}
                isShowUnAssign
                setCurrentAssignBox={(id) => setCurrentAssignBox(id)}
                currentAssignBox={currentAssignBox}
                isMultiAssignee={currentProject?.multiAssignee}
                // isAssignModal={mobile}
              />
            </RightAssign>
          </RightItemBlock>
        </Listening>
        {isEmpty(customerRole) && (
          <TimeLog>
            <TimerHead>
              <TimeLogTitle>Time Log</TimeLogTitle>
              {timelogData?.length > 0 ||
              (trackingActivity?.length > 0 &&
                trackingActivity?.some((x: { TaskId: string }) => x?.TaskId === taskId)) ? (
                //
                <Timer>
                  <Record
                    onClick={() => {
                      if (
                        trackingActivity?.length > 0 &&
                        trackingActivity?.some((x: { TaskId: string }) => x?.TaskId === taskId)
                      )
                        onClickStartStop(
                          trackingActivity[0]['_id'],
                          trackingActivity[0]?.TimerStartedAt,
                          trackingActivity[0]
                        );
                      else startTime();
                    }}>
                    {trackingActivity?.some((x: { TaskId: string }) => x?.TaskId === taskId) ? (
                      <SVGIcon
                        name='timer-stop-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='timer-stop-icon'
                      />
                    ) : (
                      <SVGIcon
                        name='timer-start-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='timer-start-icon'
                      />
                    )}
                  </Record>
                  <Action>
                    <ProgressWrapper>
                      <ProgressBar
                        // PROPS: TOTAL TIME OVER THE ESTIMATION TIME
                        isTimeOver={isTaskOverdue()}
                        progress={
                          taskDetails?.estimate && taskDetails?.estimate > 0
                            ? Math.min(
                                ((getTotalTrackedSeconds() + currentStartTime?.time) /
                                  (taskDetails?.estimate * 3600 || 1)) *
                                  100,
                                100
                              )
                            : 0
                        }></ProgressBar>
                    </ProgressWrapper>
                    <Tooltip
                      placement='bottomLeft'
                      maxWidth='170px'
                      title={
                        <>
                          <Timelog>
                            Total time:
                            {trackingActivity?.some((x: { TaskId: string }) => x?.TaskId === taskId) ? (
                              <span>{toHHMMSS(getTotalTrackedSeconds() + currentStartTime?.time)}</span>
                            ) : (
                              <span>{toHHMMSS(getTotalTrackedSeconds())}</span>
                            )}
                          </Timelog>
                          {taskDetails?.estimate && taskDetails?.estimate > 0 && (
                            <>
                              <Timelog>
                                Estimated time: <span>{formatTime(taskDetails?.estimate * 3600)}</span>
                              </Timelog>
                              {isTaskOverdue() && (
                                <Timelog>
                                  Exceed time:{' '}
                                  <span>
                                    {formatTime(
                                      getTotalTrackedSeconds() +
                                        (currentStartTime?.task_id === taskId ? currentStartTime?.time : 0) -
                                        taskDetails?.estimate * 3600
                                    )}
                                  </span>
                                </Timelog>
                              )}
                            </>
                          )}
                        </>
                      }>
                      <Time>
                        {trackingActivity?.some((x: { TaskId: string }) => x?.TaskId === taskId) ? (
                          <span>{toHHMMSS(getTotalTrackedSeconds() + currentStartTime?.time)}</span>
                        ) : (
                          <span>{toHHMMSS(getTotalTrackedSeconds())}</span>
                        )}
                      </Time>
                    </Tooltip>
                  </Action>
                </Timer>
              ) : (
                <StartButton onClick={startTime}>
                  <SVGIcon
                    name='timer-start-icon'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    className='timer-start-icon'
                  />
                </StartButton>
              )}
            </TimerHead>
            {timelogData?.map((item) => {
              const dueTime = moment(item?.createdOn).fromNow();
              return (
                <TimeLogContent key={item?.id}>
                  <SubTitle>
                    {item?.userName} <span>{floatToHHMMSS(item?.hours)} logged.</span>
                    <Tooltip
                      placement='topLeft'
                      maxWidth={'max-content'}
                      title={moment(item?.createdOn).format(`${userDetails?.dateFormat}, h:mm:ss a`)}>
                      <Text>{dueTime}</Text>
                    </Tooltip>
                  </SubTitle>
                </TimeLogContent>
              );
            })}
          </TimeLog>
        )}
      </RightBar>
      {isEstimateDropdownOpen && (
        <OverLayDiv className='dropdown-overlay' onClick={() => setIsEstimateDropdownOpen(false)} />
      )}
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AppLayout from '../../../../component/appLayout';
import Filedata from '../../../../component/filedata';
import {
  Group,
  Container,
  Content,
  Leftside,
  Title,
  Text,
  Icon,
  Rightside,
  BreadCrumb,
  BreadCrumbContent,
  BredCrumbBlock,
  BreadSlash,
  FileDetails,
  FileIconDiv
} from './styles';
import Accounting from '../../../../component/accounting';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getDocumentData,
  getMoveProjectFiles,
  getProjectFiles,
  updateFileData
} from '../../../../services/projectServices';
import { calculateDay, extensionName, formatBytes, getUrlParam, isEmpty } from '../../../../helpers/common';
import { setProjectFiles } from '../../../../actions/projectActions';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import { ProjectFileData, ProjectFilesInterface } from '../../../../interfaces/ProjectInterface';
import { FILES_LIST_TYPE, FILE_EXTENTIONS, FILE_TYPES, LINK_FILE_DATA } from '../../../../global/constants';
import { setErrorMessage } from '../../../../actions/messageActions';
import Fileloading from '../../../../component/loading/fileloading';
import FileSVGIcon from '../../../../assets/images/svg/filesIconSvg';
import FileListView from '../../../../component/filelistview';
import ModalCustom from '../../../../component/models/modal';
import Deletemodal from '../../../../component/models/deleteModel';
import { captureException } from '../../../../services/logService';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import FilesModal from '../../../../component/models/filesmodal';
import MoveFolderModal from './moveFolderModal';
import FileUploadCard from '../../../../component/FileUploadCard';

const Files: React.FC = () => {
  // States
  const [path, setpath] = useState([]);
  const [currentItem, setCurrentItem] = useState<ProjectFileData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isUploadFile, setUploadFile] = useState(false);
  const [uploadFileDetail, setUploadedFileDetail] = useState<ProjectFilesInterface[]>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { projectFiles, currentProject, projectAllFiles, loading, filesViewListType, uploadFilePercent } =
    projectSelector;

  // Other variables
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const folderId = getUrlParam(history.location.search, 'folder');
  const uploadFileLength = uploadFileDetail?.length || 0;

  // load initial data
  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      const result = await dispatch(getProjectFiles(params?.id, currentProject?.users));
      if (folderId) {
        const withoutParentData = result?.filter((x: { parentId: string }) => x?.parentId === folderId);
        dispatch(setProjectFiles(withoutParentData));
        let pathRef: any = [];
        let ref = result?.find((x: { id: string }) => x?.id === folderId);
        pathRef.push(ref);
        while (!isEmpty(ref?.parentId)) {
          // eslint-disable-next-line no-loop-func
          ref = result?.find((x: { id: string }) => x?.id === ref?.parentId);
          if (ref) {
            pathRef.push(ref);
          } else {
            return;
          }
        }
        setpath(pathRef);
      } else {
        const withoutParentData = result?.filter((x: { parentId: string }) => isEmpty(x?.parentId));
        dispatch(setProjectFiles(withoutParentData));
      }
    }
  }, [workspace?.id, dispatch, params?.id, currentProject?.users, folderId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(setProjectFiles([]));
    };
  }, [dispatch]);

  // manage file click functionality
  const onClickFile = useCallback(
    async (item: ProjectFilesInterface) => {
      if (item?.uploadFileType === FILE_TYPES.FOLDER) {
        const subFiles = projectAllFiles?.filter((x: any) => x?.parentId === item?.id);
        history.push(`${history.location.pathname}?folder=${item?.id}`);
        dispatch(setProjectFiles(subFiles));
      } else if (item?.uploadFileType === FILE_TYPES.UPLOAD) {
        history.push(`${history.location.pathname}/file-view/${item?.id}`);
      } else if (
        item?.uploadFileType === FILE_TYPES.DRIVE ||
        item?.uploadFileType === FILE_TYPES.DROPBOX ||
        item?.uploadFileType === FILE_TYPES.ONEDRIVE ||
        item?.uploadFileType === FILE_TYPES.FIGMA ||
        item?.uploadFileType === FILE_TYPES.NOTION
      ) {
        try {
          const url = new URL(item?.url);
          window.open(url, '_blank')?.focus();
        } catch (e) {
          captureException(e);
          dispatch(setErrorMessage('Can not open this URL file'));
        }
      } else if (item?.uploadFileType === FILE_TYPES.DOCS || item?.uploadFileType === FILE_TYPES.WHITEBOARD) {
        await dispatch(getDocumentData(item?.id));
        if (folderId && !isEmpty(item?.id)) {
          history.push(`/projects/files/${params?.id}/docs/${item?.id}?folder=${folderId}`);
        } else if (!isEmpty(item?.id) && !folderId) {
          history.push(`/projects/files/${params?.id}/docs/${item?.id}`);
        } else if (folderId && isEmpty(item?.id)) {
          history.push(`/projects/files/${params?.id}/docs/new?folder=${folderId}`);
        } else {
          history.push(`/projects/files/${params?.id}/docs/new`);
        }
      }
    },
    [dispatch, projectAllFiles, folderId, params?.id, history]
  );

  // redirect to files page
  const onClickFilesText = useCallback(() => {
    history.push(`${history.location.pathname}`);
  }, [history]);

  // move file to directory (api call)
  const moveFile = useCallback(
    async (file: ProjectFilesInterface) => {
      await dispatch(getMoveProjectFiles(file?.projectId, file?.id));
    },
    [dispatch]
  );

  // for open modal
  const openModal = useCallback(
    (file: ProjectFilesInterface) => {
      setIsModalOpen(true);
      moveFile(file);
    },
    [moveFile]
  );

  // for cancel model
  const onModalCancel = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
  };

  // for open delete model
  const openDeleteModel = () => {
    setDeleteModelOpen(true);
  };

  // for close delete model
  const closeDeleteModel = () => {
    setDeleteModelOpen(false);
    setCurrentItem(null);
  };

  // for delete file
  const deleteFile = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setDeleteLoading(true);
        const payload = {
          IsArchived: true
        };
        const result = await dispatch(updateFileData(currentItem?.id, payload));
        setDeleteModelOpen(false);
        if (result) {
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [currentItem?.id, dispatch, loadData]
  );

  // for cancel model
  const cancelModel = useCallback(() => {
    setIsOpen(false);
    setCurrentItem(null);
  }, []);

  // for rename file name
  const onClickRename = useCallback(() => {
    setIsOpen(true);
  }, []);

  // for render project files
  const renderProjectFiles = useMemo(() => {
    if (projectFiles.length > 0 && filesViewListType !== FILES_LIST_TYPE.LIST) {
      return (
        <Container>
          {projectFiles.map((item) => {
            const extension = extensionName(item).toLowerCase();
            const IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.avif'];

            return (
              <Filedata
                isImage={IMAGE_EXTENSIONS.includes(extension)}
                key={item?.id}
                header={
                  (!item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD) &&
                  !FILE_EXTENTIONS.includes(extension) ? (
                    <FileIconDiv>
                      <FileSVGIcon name={'blank'} height='60' width='60' viewBox='0 0 60 60' />
                      <div
                        style={{
                          fontSize: extension?.length === 5 ? 11 : 12
                        }}
                        className='file-text'>
                        {extension.split('.')?.pop()?.toUpperCase()}
                      </div>
                    </FileIconDiv>
                  ) : (
                    <>
                      {IMAGE_EXTENSIONS.includes(extension) ? (
                        <img alt='file_type_image' src={item?.url} height='100%' width='100%' />
                      ) : (
                        <FileSVGIcon
                          name={
                            !item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD
                              ? extension
                              : `icon_${item?.uploadFileType}`
                          }
                          height='60'
                          width='60'
                          viewBox='0 0 60 60'
                        />
                      )}
                    </>
                  )
                }
                title={item.name}
                itemData={item}
                endtext={`${calculateDay(item.createdOn)} by ${item?.user?.name}`}
                onClickRename={() => onClickRename()}
                OpenModal={() => openModal(item)}
                Opendeletemodel={() => openDeleteModel()}
                onClickFile={() => onClickFile(item)}
                fileType={item?.uploadFileType}
                currentItem={currentItem}
                setCurrentItem={(value: ProjectFileData | null) => setCurrentItem(value)}
              />
            );
          })}
        </Container>
      );
    }
  }, [projectFiles, filesViewListType, currentItem, onClickRename, openModal, onClickFile]);

  return (
    <>
      <AppLayout>
        <Group>
          <Accounting
            loadFilesData={loadData}
            setUploadFile={(value) => setUploadFile(value)}
            setUploadedFileDetail={(item) => setUploadedFileDetail(item)}
          />

          <FileDetails>
            {!isEmpty(folderId) && path?.length > 0 && (
              <BreadCrumb>
                <Icon>
                  <SVGIcon name='filepath-folder-icon' width='24' height='24' viewBox='0 0 24 24' />
                </Icon>
                <BreadCrumbContent>
                  <BredCrumbBlock onClick={onClickFilesText}>Files</BredCrumbBlock>
                  {path
                    ?.slice(0)
                    ?.reverse()
                    ?.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={item?.name}>
                          <BreadSlash>/</BreadSlash>
                          <BredCrumbBlock isActive={index === path?.length - 1} onClick={() => onClickFile(item)}>
                            {item?.name}
                          </BredCrumbBlock>
                        </React.Fragment>
                      );
                    })}
                </BreadCrumbContent>
              </BreadCrumb>
            )}
            {loading && projectFiles.length === 0 && (
              <>
                <Fileloading />
              </>
            )}
            {!loading && projectFiles.length === 0 && (
              <Content>
                <Leftside>
                  <Title>You have no Files</Title>
                  <Text>Tap + to add new files and documents</Text>
                  <Icon>
                    <SVGIcon
                      name='file-icon'
                      width='21'
                      height='21'
                      viewBox='0 0 21 21'
                      stroke='var(--text-secondary)'
                    />
                    <SVGIcon
                      name='folder-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 17 15'
                      fill='var(--text-secondary)'
                    />
                    <SVGIcon
                      name='google-drive-icon'
                      width='21'
                      height='21'
                      viewBox='0 0 16 16'
                      fill='var(--text-secondary)'
                    />
                    <SVGIcon
                      name='dropbox-icon'
                      width='21'
                      height='21'
                      viewBox='0 0 18 18'
                      fill='var(--text-secondary)'
                    />
                    <SVGIcon
                      name='onedrive-icon'
                      width='21'
                      height='21'
                      viewBox='0 0 18 18'
                      fill='var(--text-secondary)'
                    />
                  </Icon>
                </Leftside>
                <Rightside>
                  <SVGIcon name='wireframe-icon' width='79' height='80' viewBox='0 0 79 80' className='web-view' />
                  <SVGIcon
                    name='wireframe-mobile-icon'
                    width='59'
                    height='134'
                    viewBox='0 0 59 134'
                    className='mobile-view'
                  />
                </Rightside>
              </Content>
            )}
            {renderProjectFiles}
            {projectFiles.length > 0 && filesViewListType === FILES_LIST_TYPE.LIST && (
              <FileListView
                filesList={projectFiles}
                setSelectedItem={(item) => setCurrentItem(item)}
                onClickFile={(item) => onClickFile(item)}
                onClickRename={() => onClickRename()}
                OpenModal={(item) => openModal(item)}
                Opendeletemodel={() => openDeleteModel()}
                selectedItem={currentItem}
              />
            )}
          </FileDetails>
        </Group>
        <ModalCustom open={isOpen} onClose={cancelModel} width={462}>
          <FilesModal
            onClose={cancelModel}
            itemData={currentItem}
            loadFilesData={loadData}
            modalData={LINK_FILE_DATA.FOLDER}
          />
        </ModalCustom>
        <ModalCustom open={isModalOpen} onClose={onModalCancel} width={462}>
          <MoveFolderModal onModalCancel={onModalCancel} loadData={loadData} currentItem={currentItem} />
        </ModalCustom>
        <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={334}>
          <Deletemodal
            onClose={closeDeleteModel}
            onOk={deleteFile}
            loading={deleteLoading}
            modaltitle={`Delete ${currentItem?.fileType === String(FILE_TYPES.FOLDER) ? 'Folder?' : 'File?'}`}
            description={`Are you sure you want to delete this ${
              currentItem?.fileType === String(FILE_TYPES.FOLDER) ? 'Folder?' : 'File?'
            }`}
          />
        </ModalCustom>
        {isUploadFile && uploadFilePercent > 0 && uploadFileDetail && (
          <>
            <FileUploadCard
              file={uploadFileDetail?.[0]}
              progress={uploadFilePercent}
              fileSize={
                uploadFileLength > 1
                  ? `& ${uploadFileLength - 1} others`
                  : formatBytes(uploadFileDetail?.[0]?.size || 0)
              }
            />
          </>
        )}
      </AppLayout>
    </>
  );
};

export default Files;

import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import {
  setCustomerList,
  setCustomerLoader,
  setCustomerMembers,
  setCustomerProjectDetail,
  setFilteredCustomerList,
  setIndividualCustomerDetail
} from '../actions/customerActions';
import { updateInvoiceDetail } from '../actions/invoiceActions';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { getAPIErrorReason, isDev, isEmpty } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { CustomerListInterface, MemberInputInterface } from '../interfaces/CustomerInterface';
import { captureException } from './logService';

/**
 * @desc Customer - Get customer list
 * @param {*}
 */
export const getCustomerList: any =
  (currentPage?: number, currentSize?: number) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/customer/getCustomerList`);
      const { data } = response;
      if (data?.length > 0) {
        const updatelist = data?.map((item: CustomerListInterface) => {
          return {
            id: item['_id'],
            phone: item?.Phone,
            firstName: item?.FirstName,
            lastName: item?.LastName,
            address: item?.Address,
            email: item?.Email,
            companyName: item?.CompanyName,
            companyId: item?.CompanyId,
            key: item['_id'],
            value: item?.CompanyName,
            profileImage: item?.ProfileImage
          };
        });
        dispatch(setCustomerList(updatelist));
        let subData;
        if (currentPage && currentSize) {
          const currentIndex = currentSize * (currentPage - 1);
          subData = updatelist?.slice(currentIndex, currentIndex + currentSize);
        } else {
          subData = updatelist?.slice(0, 100);
        }
        dispatch(setFilteredCustomerList(subData));
        return updatelist;
      } else {
        dispatch(setCustomerList([]));
        dispatch(setFilteredCustomerList([]));
        return undefined;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get customer list please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - create new customer
 * @param {*}
 */
export const createNewCustomer: any =
  (payload: { CompanyName: string; Address: string }) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/customer/addCustomer`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create customer please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - update Customer Data
 * @param {*}
 */
export const updateCustomerData: any =
  (customer_id: string, payload: { Address?: string; CompanyName?: string; Email?: string; Phone?: number }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/customer/putCustomer/${customer_id}`, payload);
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update Customer Data please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return false;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - get individual customer detail
 * @param {*}
 */
export const getIndividualCustomerDetail: any = (customer_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/customer/getCustomerDetails/${customer_id}`);
    const { data } = response;
    if (data) {
      const updatelist = {
        id: data['_id'],
        phone: data?.Phone,
        firstName: data?.FirstName,
        lastName: data?.LastName,
        address: data?.Address,
        email: data?.Email,
        companyName: data?.CompanyName,
        companyId: data?.CompanyId,
        profileImage: data?.ProfileImage
      };
      dispatch(setIndividualCustomerDetail(updatelist));
      dispatch(updateInvoiceDetail({ propsName: 'customer', value: updatelist }));
      dispatch(updateInvoiceDetail({ propsName: 'companyId', value: updatelist?.companyId }));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get customer details please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc Customer - get individual customer projects
 * @param {*}
 */
export const getCustomerProjectDetail: any =
  (workspace_id: string, customer_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        customer_id,
        company_id: workspace_id,
        user_id: userDetails?.id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getProjectIdNameList`, { params });
      const { data } = response;
      if (data) {
        const updatedData = data?.map((item: { id: string; name: string }) => {
          return { ...item, key: item?.id, value: item?.name };
        });
        dispatch(setCustomerProjectDetail(updatedData));
        return updatedData;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Customer Project Details please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - add member
 * @param {*}
 */
export const addCustomerMember: any =
  (payload: MemberInputInterface, customerId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (isEmpty(payload?.name)) {
        dispatchCustomerError('Please enter name of member', dispatch);
        return false;
      } else if (isEmpty(payload?.email)) {
        dispatchCustomerError('Please enter email of member', dispatch);
        return false;
      } else if (isEmpty(payload?.role)) {
        dispatchCustomerError('Please select role for member', dispatch);
        return false;
      }
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const updatedPaylod = { ...payload, customerId, userId: userDetails?.id, isDev: isDev() };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/customer/addCustomerMember`, updatedPaylod);
      const { data } = response;
      if (data) {
        dispatchCustomerSucess(data?.message, dispatch);
        return data?.data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add member please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - update member
 * @param {*}
 */
export const updateCustomerMember: any =
  (payload: MemberInputInterface, customerId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (isEmpty(payload?.name)) {
        dispatchCustomerError('Please enter name of member', dispatch);
        return false;
      } else if (isEmpty(payload?.email)) {
        dispatchCustomerError('Please enter email of member', dispatch);
        return false;
      } else if (isEmpty(payload?.role)) {
        dispatchCustomerError('Please select role for member', dispatch);
        return false;
      }
      dispatch(clearResponseMessage(''));
      dispatch(setCustomerLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const updatedPaylod = { ...payload, customerId, userId: userDetails?.id, memberId: payload?.id, isDev: isDev() };
      const response = await axios.post(
        `${REACT_APP_TEAMCAMP_APIURL}/customer/putCustomerMemberDetails`,
        updatedPaylod
      );
      const { data } = response;
      if (data) {
        dispatchCustomerSucess(data?.message, dispatch);
        return data?.data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update member please try again';
      captureException(error);
      dispatchCustomerError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setCustomerLoader(false));
    }
  };

/**
 * @desc Customer - get members list
 * @param {*}
 */
export const getCustomerMembersList: any = (customerId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/customer/getCustomerMembersList/${customerId}`);
    const { data } = response;
    if (data) {
      dispatch(setCustomerMembers(data?.data || []));
      return data?.data || [];
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get member list please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc Customer - delete customer member
 * @param {*}
 */
export const deleteCustomerMember: any = (member_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCustomerLoader(true));
    const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/customer/deleteCustomerMember`, {
      params: { memberId: member_id, userId: currentUser?.id }
    });
    const { data } = response;
    if (data) {
      dispatchCustomerSucess(data?.message, dispatch);
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete member please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc Customer - get invoice list
 * @param {*}
 */
export const getCustomersInvoiceList: any = (customerId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceListByCustomer/${customerId}`);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Customers Invoice List please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc Customer - get Customers Project List
 * @param {*}
 */
export const getCustomersProjectList: any = (customer_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getCustomersProjectList/${customer_id}`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      return [];
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get customer projects please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return [];
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

function dispatchCustomerError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchCustomerSucess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}

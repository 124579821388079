import React from 'react';

import {
  Card,
  Content,
  FileIcon,
  FileInfo,
  FileName,
  FileSize,
  ProgressInfo,
  ProgressWrapper,
  FileDetail
} from './styles';
import FileSVGIcon from '../../assets/images/svg/filesIconSvg';
import { extensionName } from '../../helpers/common';
import { FILE_EXTENTIONS } from '../../global/constants';
import ProgressBar from '../progressBar';
import { ProjectFilesInterface } from '../../interfaces/ProjectInterface';
interface FileUploadCardProps {
  file: File | ProjectFilesInterface;
  progress: number;
  fileSize: string | number;
}

const FileUploadCard: React.FC<FileUploadCardProps> = ({ file, progress, fileSize }) => {
  const extension = extensionName(file).toLowerCase();
  const isCustomExtension = !FILE_EXTENTIONS.includes(extension);

  return (
    <Card>
      <Content>
        <FileDetail>
          {isCustomExtension ? (
            <FileIcon>
              <FileSVGIcon name={'blank'} height='32' width='32' viewBox='0 0 60 60' />
              <div
                style={{
                  fontSize: extension?.length === 5 ? 5 : 6
                }}
                className='upload-file-text'>
                {extensionName(file).toLowerCase().split('.')?.pop()?.toUpperCase()}
              </div>
            </FileIcon>
          ) : (
            <FileSVGIcon name={extension} height='32' width='32' viewBox='0 0 60 60' />
          )}
          <FileInfo>
            <FileName>{file?.name}</FileName>
            <FileSize>{fileSize}</FileSize>
          </FileInfo>
        </FileDetail>
        <ProgressInfo>{Math.round(progress)}%</ProgressInfo>
      </Content>
      <ProgressWrapper>
        <ProgressBar percent={Math.round(progress)} progressColor='var(--brand-primary)' showInfo={false} height={3} />
      </ProgressWrapper>
    </Card>
  );
};

export default FileUploadCard;

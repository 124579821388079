import { Icon } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useHistory } from 'react-router-dom';
interface Props {
  backNavigationPath?: string;
}
export default function Responsivnavbar({ backNavigationPath }: Props) {
  const history = useHistory();

  return (
    <>
      <Icon onClick={() => (backNavigationPath ? history.push(backNavigationPath) : history.goBack())}>
        <SVGIcon
          name='responsive-back-icon'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          className='line-icon'
        />
      </Icon>
    </>
  );
}

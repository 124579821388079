import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Card = styled.div`
  width: 100%;
  max-width: 230px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  padding: 11px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  @media (max-width: 449px) {
    transform: translateX(-50%);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const FileIcon = styled.div`
  position: relative;
  display: flex;

  .upload-file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 28%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-primary);
  }
`;

const FileInfo = styled.div`
  width: 149px;
  display: flex;
  flex-direction: column;
`;

const FileName = styled.div`
  ${Typography.body_md_medium}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
`;

const FileSize = styled.div`
  color: var(--text-secondary);
  ${Typography.body_xs}
`;

const ProgressWrapper = styled.div`
  display: flex;
  padding: 0;

  .progress {
    .ant-progress-inner {
      background-color: var(--background-tertiary);
    }
  }
`;

const ProgressInfo = styled.p`
  margin: 0;
  ${Typography.body_sm}
  color: var(--text-primary);
`;

const FileDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export { ProgressInfo, Card, Content, FileIcon, FileInfo, FileName, FileSize, ProgressWrapper, FileDetail };

/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import GroupSettingCard from './group';
import TeamsSettingCard from './team';
import PrioritySettingCard from './priorities';
import LabelsSettingCard from './labels';
import { LabelDetailsInterface } from '../../interfaces/ProjectInterface';
import { DropResult } from 'react-beautiful-dnd';
import {
  ProjectTemplateDetailsInterface,
  TemplateProjectUsersInterface,
  TemplateUsersInterface
} from '../../interfaces/SettingsInterface';
import ModalCustom from '../models/modal';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Button from '../button';
import Inviteteamsmodal from '../models/inviteteamsmodal';
import Deletemodal from '../models/deleteModel';
import Labelmodal from '../models/labelmodal';
import {
  Check,
  Inputvalue2,
  Invite,
  Members,
  ModalContent,
  Modalblock,
  Modalhead,
  Name,
  Profile,
  Searchbar,
  Taskbutton
} from '../../pages/project/details/setting/styles';
import {
  clearGroupInput,
  clearLabelInput,
  clearStatusInput,
  setGroupInput,
  setLabelInput,
  updateCurrentProject,
  updateGroupInput
} from '../../actions/projectActions';
import { useDispatch, useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import Avatar from '../avatar/Avatar';
import {
  GroupModalhead,
  Groupbody,
  Groupmodal,
  Groupmodel,
  Headline,
  Icon,
  Icon1,
  Inputvalues,
  Modeltitle,
  Todotext
} from './style';
import { updateProjectTemplateDetails } from '../../actions/settingActions';
import { nanoid } from 'nanoid';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { isEmpty } from '../../helpers/common';
import { createNewGroup, deleteTaskGroup, updateGroupDetail } from '../../services/taskServices';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { captureException } from '../../services/logService';
import { addInvitedMember, getCurrentProjectDetails, removeInvitedMember } from '../../services/projectServices';
import { setTaskGroupsList } from '../../actions/taskActions';
import { UsersWorkspaceInterface, WorkspaceInterfase } from '../../interfaces/WorkspaceInterface';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import StatusSettingCard from './status/status';
import AddStatusModal from '../models/addStatusmodal';
import { PROJECT_ANALYTICS } from '../../global/analyticsConstants';
import GithubSettingCard from './githubRepository';
import SettingCard from './settingCard';
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');

interface Props {
  currentProject: ProjectTemplateDetailsInterface;
  themeMode: { theme: string };
  onUpdateMileStone: (value: boolean) => void;
  onUpdateEstimate: (value: boolean) => void;
  onUpdateMultiAssignee?: (value: boolean) => void;
  onUpdateStatus: (value: boolean) => void;
  onChangeDefault: ({ key }: any) => void;
  onUpdatePriority: (value: boolean) => void;
  onUpdateLabels: (value: boolean) => void;
  taskGroups: any; // Use any type because of not able to fix type error for drag and drop funcionality
  loading: boolean;
  workspaceUsers: UsersWorkspaceInterface[];
  customersUsers: UsersWorkspaceInterface[];
  paramsId?: string;
  deleteLabel?: (id: string) => void;
  isTemplateProject?: boolean;
  loadData: () => void;
  setLoading: (value: boolean) => void;
  workspaceDetails: WorkspaceInterfase;
  onUpdateGroup: (value: boolean) => void;
  loadingButton: {
    group: boolean;
    status: boolean;
    priority: boolean;
    label: boolean;
    multiAssignee: boolean;
    estimation: boolean;
    milestone: boolean;
  };
}

const TemplateSettingCards: React.FC<Props> = (props) => {
  const {
    currentProject,
    themeMode,
    onUpdateMileStone,
    onUpdateEstimate,
    onUpdateMultiAssignee,
    onChangeDefault,
    onUpdatePriority,
    onUpdateLabels,
    taskGroups,
    loading,
    workspaceUsers,
    customersUsers,
    paramsId,
    deleteLabel,
    isTemplateProject = false,
    loadData,
    setLoading,
    workspaceDetails,
    onUpdateStatus,
    onUpdateGroup,
    loadingButton
  } = props;
  //States
  const [ModalOpen, setModalOpen] = useState(false);
  const [isMainModel, setMainModel] = useState(false);
  const [isSubModel, setSubModel] = useState(false);
  const [searchResult, setSearchResult] = useState<UsersWorkspaceInterface[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [query, setQuery] = useState('');
  const [isDeleteMemberModel, setIsDeleteMemberModel] = useState(false);
  const [isMemberId, setIsMemberId] = useState('');
  const [isDeletelabelModel, setIsDeleteLabelModel] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isWorkspaceUser, setIsWorkspaceUser] = useState(true);
  const [labelId, setLabelId] = useState('');
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [statusType, setStatusType] = useState(0);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { projectTemplateDetails } = settingsSelector;
  const { createGroupInput } = projectSelector;
  //Other variable
  const dispatch = useDispatch();

  const onChangeInput = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateGroupInput({ propsName, value }));
    },
    [dispatch]
  );

  const onClickDeleteLabel = useCallback(
    (labelId: string) => {
      if (isTemplateProject) {
        let labelListClone: LabelDetailsInterface[] = JSON.parse(JSON.stringify(currentProject?.labelsList));
        const labelIndex = labelListClone?.findIndex((item) => item?.id === labelId);
        if (labelIndex !== -1) {
          labelListClone?.splice(labelIndex, 1);
          dispatch(updateProjectTemplateDetails({ propsName: 'labelsList', value: labelListClone }));
        }
        setIsDeleteLabelModel(false);
        return;
      } else {
        if (deleteLabel) deleteLabel(labelId);
        setIsDeleteLabelModel(false);
      }
    },
    [currentProject?.labelsList, deleteLabel, dispatch, isTemplateProject]
  );

  const onCloseGroupModal = useCallback(() => {
    dispatch(clearGroupInput());
    setModalOpen(false);
  }, [dispatch]);

  const onClickEditGroup = useCallback(
    (group: any) => {
      dispatch(setGroupInput(group));
      setModalOpen(true);
    },
    [dispatch]
  );

  const onClickEditLabel = useCallback(
    (item: LabelDetailsInterface) => {
      dispatch(setLabelInput(item));
      setIsOpen(true);
    },
    [dispatch]
  );

  const onCreateNewLabel = () => {
    setIsOpen(true);
  };

  const onCloseNewLabel = () => {
    dispatch(clearLabelInput());
    setIsOpen(false);
  };

  const OpenDeleteMemberModel = (id: string) => {
    setIsDeleteMemberModel(true);
    setIsMemberId(id);
  };

  const onCloseDeleteMemberModel = () => {
    setIsDeleteMemberModel(false);
  };

  const onSubModel = () => {
    setMainModel(false);
    setSubModel(true);
  };

  const onCloseSubModal = () => {
    setSubModel(false);
  };

  const openDeleteLabelModel = (id: string) => {
    setIsDeleteLabelModel(true);
    setLabelId(id);
  };

  const closeDeleteLabelModel = () => {
    setIsDeleteLabelModel(false);
  };

  const searchQuery = useCallback(
    (value: string) => {
      let result;
      if (isWorkspaceUser) {
        result = workspaceUsers?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      } else {
        result = customersUsers?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      }
      if (result) {
        setSearchResult(result);
        return null;
      }
    },
    [customersUsers, isWorkspaceUser, workspaceUsers]
  );

  const onChangeSearch = useCallback(
    (e: { target: { value: string } }) => {
      const value = e.target.value;
      setQuery(value);
      let timeout;
      if (value) {
        if (!isSearch) setIsSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQuery(value);
        }, 300);
      } else {
        if (isSearch) setIsSearch(false);
        setSearchResult([]);
      }
    },
    [isSearch, searchQuery]
  );

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const updatedGroupNames = Array.from(taskGroups);
    const [removed] = updatedGroupNames.splice(result.source.index, 1);
    updatedGroupNames.splice(result.destination.index, 0, removed);

    const updated = updatedGroupNames?.map((item: any, index) => {
      return { ...(item || {}), index: index };
    });
    if (isTemplateProject) {
      dispatch(updateProjectTemplateDetails({ propsName: 'groups', value: updated }));
    } else {
      dispatch(setTaskGroupsList(updated));
      const updatePromises = updated?.map((item) => {
        const { id, index } = item;
        const payload = { itemId: id, index: index };
        return dispatch(updateGroupDetail(payload, true));
      });
      try {
        await Promise.all(updatePromises);
      } catch (error) {
        captureException(error);
        console.error('error', error);
      }
    }
  };

  const closeInviteModal = () => {
    setMainModel(false);
    setIsWorkspaceUser && setIsWorkspaceUser(true);
    setQuery('');
    setIsSearch(false);
  };

  const onClickWorkspaceButton = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (setIsWorkspaceUser) setIsWorkspaceUser(true);
      setQuery('');
      setIsSearch(false);
    },
    [setIsWorkspaceUser]
  );

  const onClickClientButton = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (setIsWorkspaceUser) setIsWorkspaceUser(false);
      setQuery('');
      setIsSearch(false);
    },
    [setIsWorkspaceUser]
  );

  const deletMemberFromTemplateProject = useCallback(
    (memberId: string) => {
      const projectUsersClone: TemplateProjectUsersInterface[] = JSON.parse(
        JSON.stringify(projectTemplateDetails?.projectUsers)
      );
      const usersClone: TemplateUsersInterface[] = JSON.parse(JSON.stringify(projectTemplateDetails?.users));
      const userIndex = projectUsersClone?.findIndex((item) => item['_id'] === memberId);
      if (userIndex !== -1) {
        projectUsersClone?.splice(userIndex, 1);
        usersClone?.splice(userIndex, 1);
        dispatch(updateProjectTemplateDetails({ propsName: 'projectUsers', value: projectUsersClone }));
        dispatch(updateProjectTemplateDetails({ propsName: 'users', value: usersClone }));
      }
    },
    [dispatch, projectTemplateDetails?.projectUsers, projectTemplateDetails?.users]
  );

  const onClickDeleteMember = useCallback(
    async (memberId: string) => {
      try {
        setLoading(true);
        if (isTemplateProject) {
          deletMemberFromTemplateProject(memberId);
        } else {
          const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
          const result = await dispatch(removeInvitedMember(userDetails?.id, currentProject?.id, memberId));
          if (result) {
            loadData();
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
      setIsDeleteMemberModel(false);
    },
    [currentProject?.id, deletMemberFromTemplateProject, dispatch, isTemplateProject, loadData, setLoading]
  );

  const onClickMemberBox = useCallback(
    async (userIndex: number, invitedUser: UsersWorkspaceInterface) => {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      try {
        setLoading(true);
        if (isTemplateProject) {
          if (userIndex === -1) {
            const newUser = {
              _id: invitedUser?.id,
              createdBy: userDetails?.id,
              createdTime: new Date().toISOString(),
              updatedBy: userDetails?.id,
              updatedTime: new Date().toISOString()
            };
            dispatch(
              updateProjectTemplateDetails({
                propsName: 'projectUsers',
                value: [...(projectTemplateDetails?.projectUsers || []), newUser]
              })
            );
            const newUserAllDetails = workspaceDetails?.users?.find((x) => x?.id === invitedUser?.id);
            dispatch(
              updateProjectTemplateDetails({
                propsName: 'users',
                value: [...(projectTemplateDetails?.users || []), newUserAllDetails]
              })
            );
          } else {
            deletMemberFromTemplateProject(invitedUser?.id);
          }
        } else {
          let result;
          let usersClone = JSON.parse(JSON.stringify(currentProject?.users));
          if (userIndex === -1) {
            const payload = {
              userId: userDetails?.id,
              newUserId: invitedUser?.id,
              companyId: workspaceDetails?.id,
              projectId: currentProject?.id
            };
            usersClone = [...(usersClone || []), invitedUser];
            dispatch(updateCurrentProject({ propsName: 'users', value: usersClone }));
            result = await dispatch(addInvitedMember(payload));
            trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_ADDED);
          } else {
            usersClone.splice(userIndex, 1);
            dispatch(updateCurrentProject({ propsName: 'users', value: usersClone }));
            result = await dispatch(removeInvitedMember(userDetails?.id, currentProject?.id, invitedUser?.id));
            trackAnalyticActivity(PROJECT_ANALYTICS.MEMBER_REMOVED);
          }
          if (result) {
            await dispatch(getCurrentProjectDetails(workspaceDetails?.id, currentProject?.id));
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      isTemplateProject,
      dispatch,
      projectTemplateDetails?.projectUsers,
      projectTemplateDetails?.users,
      deletMemberFromTemplateProject,
      currentProject?.users,
      currentProject?.id,
      workspaceDetails
    ]
  );

  const renderData = useCallback(
    (renderData: UsersWorkspaceInterface[]) => {
      return (
        <Modalblock>
          <ModalContent>
            <Searchbar>
              <Search placeholder='Search by name' className='search' value={query} onChange={onChangeSearch} />
              {customersUsers?.length > 0 && (
                <div style={{ display: 'flex', gap: 8, paddingTop: 12 }}>
                  <Taskbutton isActive={isWorkspaceUser} onClick={onClickWorkspaceButton}>
                    Workspace
                  </Taskbutton>
                  <Taskbutton isActive={!isWorkspaceUser} onClick={onClickClientButton}>
                    Client
                  </Taskbutton>
                </div>
              )}
            </Searchbar>
            <Members>
              {renderData?.map((userItem) => {
                const userIndex = currentProject?.users?.findIndex((user) => user?.id === userItem?.id);
                return (
                  <Profile key={userItem?.id} onClick={() => onClickMemberBox && onClickMemberBox(userIndex, userItem)}>
                    <Name>
                      <Avatar
                        imgSrc={userItem?.profile_photo}
                        name={userItem?.name ? userItem.name : 'U N'}
                        size={28}
                        isNotBorder={true}
                      />
                      <p>{userItem?.name}</p>
                    </Name>
                    <Check>
                      <Inputvalue2
                        type='checkbox'
                        className='checkbox-round'
                        checked={userIndex === -1 ? false : true}
                      />
                    </Check>
                  </Profile>
                );
              })}
            </Members>
            <Invite onClick={onSubModel}>Invite New Member</Invite>
          </ModalContent>
        </Modalblock>
      );
    },
    [
      query,
      onChangeSearch,
      customersUsers?.length,
      isWorkspaceUser,
      onClickWorkspaceButton,
      onClickClientButton,
      currentProject?.users,
      onClickMemberBox
    ]
  );

  const onCreateGroupButton = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setLoading(true);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        if (!isEmpty(createGroupInput?.id)) {
          if (isTemplateProject) {
            const newGroupObj = {
              id: createGroupInput?.id,
              name: createGroupInput?.name,
              projectId: projectTemplateDetails?.['_id'],
              createdBy: createGroupInput?.id,
              createdTime: createGroupInput?.createdTime,
              updatedBy: userDetails?.id,
              updatedTime: new Date().toISOString(),
              index: createGroupInput?.index
            };
            const updatedGroups = projectTemplateDetails?.groups?.map((item) => {
              if (item?.id === newGroupObj?.id) return newGroupObj;
              else return item;
            });
            dispatch(
              updateProjectTemplateDetails({
                propsName: 'groups',
                value: updatedGroups
              })
            );
            onCloseGroupModal();
            return;
          }
          const payload = { userId: userDetails?.id, itemId: createGroupInput?.id, name: createGroupInput?.name };
          const response = await dispatch(updateGroupDetail(payload));
          if (response) {
            onCloseGroupModal();
            loadData();
          }
        } else {
          if (isTemplateProject) {
            const newGroupObj = {
              id: String(nanoid()),
              name: createGroupInput?.name,
              projectId: projectTemplateDetails?.['_id'],
              createdBy: userDetails?.id,
              createdTime: new Date().toISOString(),
              updatedBy: userDetails?.id,
              updatedTime: new Date().toISOString(),
              index: projectTemplateDetails?.groups[projectTemplateDetails?.groups?.length - 1]?.index + 1
            };
            dispatch(
              updateProjectTemplateDetails({
                propsName: 'groups',
                value: [...(projectTemplateDetails?.groups || []), newGroupObj]
              })
            );
            onCloseGroupModal();
            return;
          }
          const payload = { userId: userDetails?.id, name: createGroupInput?.name, projectId: currentProject?.id };
          const response = await dispatch(createNewGroup(payload));
          if (response) {
            trackAnalyticActivity(PROJECT_ANALYTICS.GROUP_ADDED);
            onCloseGroupModal();
            loadData();
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [
      createGroupInput?.createdTime,
      createGroupInput?.id,
      createGroupInput?.index,
      createGroupInput?.name,
      currentProject?.id,
      dispatch,
      isTemplateProject,
      loadData,
      onCloseGroupModal,
      projectTemplateDetails,
      setLoading
    ]
  );

  const deleteGroup = useCallback(
    async (item: { id: string }) => {
      try {
        setLoading(true);
        if (isTemplateProject) {
          const groupsClone: ProjectTemplateDetailsInterface[] = JSON.parse(
            JSON.stringify(projectTemplateDetails?.groups || [])
          );
          const groupIndex = groupsClone?.findIndex((group) => group?.id === item?.id);
          if (groupIndex !== -1) {
            groupsClone?.splice(groupIndex, 1);
            dispatch(updateProjectTemplateDetails({ propsName: 'groups', value: groupsClone }));
          }
        } else {
          const result = await dispatch(deleteTaskGroup(item?.id));
          if (result) {
            loadData();
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, isTemplateProject, loadData, projectTemplateDetails?.groups, setLoading]
  );

  const onCloseStatusModal = useCallback(() => {
    setIsStatusModalOpen(false);
    dispatch(clearStatusInput());
  }, [dispatch]);

  const getRenderData = useCallback(() => {
    if (isSearch) {
      return renderData(searchResult);
    }
    return renderData(isWorkspaceUser ? workspaceUsers : customersUsers);
  }, [customersUsers, isSearch, isWorkspaceUser, renderData, searchResult, workspaceUsers]);

  return (
    <>
      <TeamsSettingCard
        currentProject={currentProject}
        setMainModel={(value) => setMainModel(value)}
        OpenDeleteMemberModel={(id) => OpenDeleteMemberModel(id)}
      />
      <GroupSettingCard
        themeMode={themeMode}
        handleDragEnd={(result) => handleDragEnd(result)}
        taskGroups={taskGroups}
        onClickEditGroup={(group) => onClickEditGroup(group)}
        deleteGroup={(group) => deleteGroup(group)}
        loading={loading}
        setModalOpen={(value) => setModalOpen(value)}
        currentProject={currentProject}
        onUpdateGroup={(value) => onUpdateGroup(value)}
        loadingButton={loadingButton?.group}
      />
      <StatusSettingCard
        themeMode={themeMode}
        loadingButton={loadingButton?.status}
        currentProject={currentProject}
        setModalOpen={(value) => setIsStatusModalOpen(value)}
        onUpdateStatus={(value) => onUpdateStatus(value)}
        setStatusType={(value) => setStatusType(value)}
        loadProjectData={loadData}
        isTemplateProject={isTemplateProject}
      />
      <PrioritySettingCard
        currentProject={currentProject}
        themeMode={themeMode}
        onChangeDefault={(key) => onChangeDefault(key)}
        onUpdatePriority={(value) => onUpdatePriority(value)}
        loadingButton={loadingButton?.priority}
      />
      <LabelsSettingCard
        currentProject={currentProject}
        onUpdateLabels={(value) => onUpdateLabels(value)}
        openDeleteLabelModel={(id) => openDeleteLabelModel(id)}
        onClickEditLabel={(item) => onClickEditLabel(item)}
        handleclick={onCreateNewLabel}
        loadingButton={loadingButton?.label}
      />

      {/* multiassign or single assign */}
      <SettingCard
        isToggle={currentProject?.multiAssignee}
        onUpdateSetting={(value) => {
          if (onUpdateMultiAssignee) onUpdateMultiAssignee(value);
        }}
        loadingButton={loadingButton?.multiAssignee}
        title='Multiple assignee'
        subtitle='To enable this feature, you can allow tasks to be assigned to multiple members.'
      />
      {/* Estimation on and off */}
      <SettingCard
        isToggle={currentProject?.isEstimate}
        onUpdateSetting={(value) => {
          if (onUpdateEstimate) onUpdateEstimate(value);
        }}
        loadingButton={loadingButton?.estimation}
        title='Estimation'
        subtitle='Estimates communicate task complexity and cycle capacity. Choose how to estimate with your team below.'
      />
      {/* Milestone on and off */}
      <SettingCard
        isToggle={currentProject?.isMilestone}
        onUpdateSetting={(value) => {
          if (onUpdateMileStone) onUpdateMileStone(value);
        }}
        loadingButton={loadingButton?.milestone}
        title='Milestone'
        subtitle='Milestones communicate progress, validate accomplishments, and help track adherence to schedules and objectives.'
      />
      {/* Github repository card */}
      <GithubSettingCard />
      <ModalCustom open={isMainModel} onClose={closeInviteModal} width={400}>
        <Modalhead>
          <Headline>Invite member to {currentProject?.name} project.</Headline>
          <Icon1 onClick={closeInviteModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='closeicon' />
          </Icon1>
        </Modalhead>
        {getRenderData()}
      </ModalCustom>
      <ModalCustom open={ModalOpen} onClose={onCloseGroupModal} width={462}>
        <Groupmodel onSubmit={onCreateGroupButton}>
          <GroupModalhead>
            <Todotext>{createGroupInput?.id ? 'Update Group' : 'Create Group'} </Todotext>
            <Icon onClick={onCloseGroupModal}>
              <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='closeicon' />
            </Icon>
          </GroupModalhead>
          <Groupbody>
            <Modeltitle>Group name</Modeltitle>
            <Inputvalues
              placeholder='Enter your group name'
              value={createGroupInput?.name}
              onChange={(e) => onChangeInput('name', e.target.value)}
            />
          </Groupbody>
          <Groupmodal>
            <Button
              type={'submit'}
              title={createGroupInput?.id ? 'Update Group' : 'Create Group'}
              isLoading={loading}
              modelbtn={true}
            />
          </Groupmodal>
        </Groupmodel>
      </ModalCustom>
      <ModalCustom open={isStatusModalOpen} onClose={onCloseStatusModal} width={334}>
        <AddStatusModal
          onCancel={onCloseStatusModal}
          statusType={statusType}
          projectId={currentProject?.id}
          loadData={loadData}
          isTemplateProject={isTemplateProject}
        />
      </ModalCustom>
      <ModalCustom open={isSubModel} onClose={onCloseSubModal} width={400}>
        <Inviteteamsmodal
          onCancel={onCloseSubModal}
          project_id={paramsId}
          isTextVisible={true}
          projectName={currentProject?.name}
          workspace_id={workspace_id}
        />
      </ModalCustom>
      <ModalCustom open={isDeleteMemberModel} onClose={onCloseDeleteMemberModel} width={334}>
        <Deletemodal
          onClose={onCloseDeleteMemberModel}
          loading={loading}
          buttonText='Remove'
          onOk={() => onClickDeleteMember(isMemberId)}
          modaltitle='Remove Member?'
          description='Are you sure you want to remove this member from the project?'
        />
      </ModalCustom>
      <ModalCustom open={isDeletelabelModel} onClose={closeDeleteLabelModel} width={334}>
        <Deletemodal
          onClose={closeDeleteLabelModel}
          loading={loading}
          onOk={() => onClickDeleteLabel(labelId)}
          modaltitle='Delete Label?'
          description='Are you sure you want to delete this label?'
        />
      </ModalCustom>
      <ModalCustom open={isOpen} onClose={onCloseNewLabel} width={462}>
        <Labelmodal isTemplateProject={isTemplateProject} onClose={onCloseNewLabel} />
      </ModalCustom>
    </>
  );
};

export default TemplateSettingCards;

import styled from 'styled-components';
const DrawerOverlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-overlay);
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
`;

const DrawerContent = styled.div<{ open: boolean; placement: string; width: string }>`
  position: fixed;
  background-color: var(--background-primary);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  width: ${({ width }) => width};
  height: 100%;
  ${({ placement, open, width }) => {
    switch (placement) {
      case 'right':
        return `
          right: ${open ? '0' : '-100%'};
          top: 0;
        `;
      case 'left':
        return `
          left: ${open ? '0' : '-100%'};
          top: 0;
        `;
      case 'top':
        return `
          top: ${open ? '0' : '-100%'};
          left: 0;
          width: 100%;
          height: ${width};
        `;
      case 'bottom':
        return `
          bottom: ${open ? '0' : '-100%'};
          left: 0;
          width: 100%;
          height: ${width};
        `;
      default:
        return '';
    }
  }}
`;

const DrawerBody = styled.div`
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export { DrawerOverlay, DrawerContent, DrawerBody };

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ROUTES } from '../../../routes/urls';
import {
  Backbutton,
  Div,
  IconView,
  Menu,
  MenuSection,
  NavbarMain,
  ResponsiveHeading,
  Submenu,
  Title,
  TitleBorder
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import ModalCustom from '../../models/modal';
import ConfirmationModal from '../../models/confirmationModal';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { saveProjectTemplateDetails, updateNotificationSettingDetails } from '../../../services/settingServices';
import { updateNotificationsSettingsDetails, updateProjectTemplateDetails } from '../../../actions/settingActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';
import { BottomNavigation } from '../../bottomNavigation';
import { useMobileDevice } from '../../../global/useMobile';

const SettingNavbar: React.FC = () => {
  //State
  const [alertModelOpen, setAlertModalOpen] = useState(false);
  const [navigatePath, setNavigatePath] = useState('');
  const [updatedDataName, setUpdatedDataName] = useState('');
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { projectTemplateDetails, loading: settingLoading, notificationSettingDetails } = settingsSelector;
  const { workspace } = workspaceSelector;
  //Other variable
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const prevLocation = location.pathname;

  const onClickNavbarItem = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    const unListen = history.listen((location) => {
      if (projectTemplateDetails.updated && location.pathname !== prevLocation) {
        setUpdatedDataName('projectTemplateDetails');
        history.push(prevLocation);
        setAlertModalOpen(true);
        setNavigatePath(location.pathname);
      } else if (notificationSettingDetails.updated && location.pathname !== prevLocation) {
        setUpdatedDataName('notificationSettingDetails');
        history.push(prevLocation);
        setAlertModalOpen(true);
        setNavigatePath(location.pathname);
      }
    });
    return () => {
      unListen();
    };
  }, [history, notificationSettingDetails.updated, prevLocation, projectTemplateDetails.updated]);

  const closeAlertModal = useCallback(() => {
    if (updatedDataName === 'projectTemplateDetails') {
      dispatch(updateProjectTemplateDetails({ propsName: 'updated', value: false }));
    } else if (updatedDataName === 'notificationSettingDetails') {
      dispatch(updateNotificationsSettingsDetails({ propsName: 'updated', value: false }));
    }
    setAlertModalOpen(false);
    if (navigatePath) {
      history.push(navigatePath);
    }
  }, [dispatch, history, navigatePath, updatedDataName]);

  const onClickSave = useCallback(async () => {
    if (updatedDataName === 'projectTemplateDetails') {
      const cloneDetails = JSON.parse(JSON.stringify(projectTemplateDetails));
      delete cloneDetails?.users;
      delete cloneDetails?.updated;
      trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_DATA_UPDATED);
      const result = await dispatch(saveProjectTemplateDetails(cloneDetails));
      if (result) closeAlertModal();
    } else if (updatedDataName === 'notificationSettingDetails') {
      const cloneDetails = JSON.parse(JSON.stringify(notificationSettingDetails));
      delete cloneDetails?.updated;
      const result = await dispatch(updateNotificationSettingDetails(cloneDetails));
      if (result) closeAlertModal();
    }
  }, [closeAlertModal, dispatch, notificationSettingDetails, projectTemplateDetails, updatedDataName]);

  return (
    <>
      <NavbarMain className='setting-navbar'>
        {!isMobile ? (
          <Backbutton onClick={() => onClickNavbarItem(ROUTES.INBOX)}>
            <SVGIcon
              name='setting-navbar-left-icon'
              width='13'
              height='13'
              viewBox='0 0 13 13'
              className='fill-color'
            />
            <p>Back</p>
          </Backbutton>
        ) : (
          <ResponsiveHeading>
            <SVGIcon name='settings-icon' width='24' height='24' viewBox='0 0 18 18' className='stroke-color' />
            <p>Settings</p>
          </ResponsiveHeading>
        )}
        <MenuSection>
          <Menu>
            <Title>
              <p>You</p>
              <TitleBorder />
            </Title>
            <Div>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/personal')}
                onClick={() => onClickNavbarItem(ROUTES.PERSONAL_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>My Profile</span>
              </Submenu>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/email')}
                onClick={() => onClickNavbarItem(ROUTES.EMAIL_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Change your Email</span>
              </Submenu>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/password')}
                onClick={() => onClickNavbarItem(ROUTES.PASSWORD_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Change Password</span>
              </Submenu>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/notification')}
                onClick={() => onClickNavbarItem(ROUTES.NOTIFICATION_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Notifications</span>
              </Submenu>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/security-access')}
                onClick={() => onClickNavbarItem(ROUTES.SECURITY_ACCESS_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Security and access</span>
              </Submenu>
              <Submenu
                isActive={location?.pathname?.startsWith('/settings/referEarn')}
                onClick={() => onClickNavbarItem(ROUTES.REFEREARN_SETTING)}
                className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Refer & Earn</span>
              </Submenu>
            </Div>
          </Menu>
          {workspace?.id && workspace?.isAdmin && (
            <Menu>
              <Title>
                <p>Workspace</p>
                <TitleBorder />
              </Title>
              <Div className='bottom-div'>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/general')}
                  onClick={() => onClickNavbarItem(ROUTES.GENERAL_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Organisation Profile</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/templates')}
                  onClick={() => onClickNavbarItem(ROUTES.TEMPLATES_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Templates</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/invoice')}
                  onClick={() => onClickNavbarItem(ROUTES.INVOICE_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Invoicing</span>
                </Submenu>
                {/* <Submenu
                isActive={location?.pathname?.startsWith('/settings/payment')}
                onClick={() => onClickNavbarItem(ROUTES.PAYMENT_SETTING)} className='menu-name'>
                <IconView className='pointer'>
                  <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                </IconView>
                <span>Online Payment</span>
              </Submenu> */}
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/team')}
                  onClick={() => onClickNavbarItem(ROUTES.TEAM_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Teams</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/subscription')}
                  onClick={() => onClickNavbarItem(ROUTES.SUBSCRIPTION_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Subscription</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/apikey')}
                  onClick={() => onClickNavbarItem(ROUTES.API_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>API Keys</span>
                </Submenu>
                <Submenu
                  isActive={
                    location?.pathname?.startsWith('/settings/integrations') ||
                    location?.pathname?.startsWith('/settings/integration/github')
                  }
                  onClick={() => onClickNavbarItem(ROUTES.INTEGRATIONS_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Integrations</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/apps')}
                  onClick={() => onClickNavbarItem(ROUTES.APPS_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Apps</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/custom-domain')}
                  onClick={() => onClickNavbarItem(ROUTES.BRANDDOMAIN_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Branding & Domain</span>
                </Submenu>
                <Submenu
                  isActive={location?.pathname?.startsWith('/settings/usage')}
                  onClick={() => onClickNavbarItem(ROUTES.USAGE_SETTING)}
                  className='menu-name'>
                  <IconView className='pointer'>
                    <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
                  </IconView>
                  <span>Usage</span>
                </Submenu>
              </Div>
            </Menu>
          )}
        </MenuSection>
      </NavbarMain>
      {/* bottom navigation bar */}
      <BottomNavigation />

      <ModalCustom open={alertModelOpen} onClose={closeAlertModal} width={334}>
        <ConfirmationModal
          loading={settingLoading}
          onOk={onClickSave}
          onClose={closeAlertModal}
          modaltitle='Save new changes?'
          description='Do you want to save new changes?'
        />
      </ModalCustom>
    </>
  );
};

export default SettingNavbar;

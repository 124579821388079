import React, { useMemo } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Default, Rightrow1, Statusrow, Status, Icon2 } from './styles';
import { Autotext, Box, Leftrow, Row, Subtitle, Title } from '../style';
import Button from '../../button';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { PrioritiesList } from '../../../global/row';

interface Props {
  currentProject: any; //Remove any
  themeMode: { theme: string };
  onChangeDefault: ({ key }: any) => void; //Remove any
  onUpdatePriority: (value: boolean) => void;
  loadingButton: boolean;
}

const PrioritySettingCard: React.FC<Props> = (props) => {
  const { currentProject, onChangeDefault, onUpdatePriority, loadingButton } = props;

  // Memoize the rendered list of priorities
  const priorityElements = useMemo(() => {
    return PrioritiesList.map((priority) => (
      <Statusrow key={priority.key}>
        <Rightrow1>
          <SVGIcon name={priority.icon} width='16' height='16' viewBox='0 0 16 16' />
          <Title>{priority.title}</Title>
        </Rightrow1>
        <Rightrow1>
          {currentProject?.priority?.default === priority.key ? (
            <Default>Default</Default>
          ) : (
            <Dropdown
              content={
                <Icon2>
                  <SVGIcon name='loading-more-icon' width='18' height='18' viewBox='0 0 18 18' />
                </Icon2>
              }
              trigger='click'>
              <DropdownItem
                onClick={() => {
                  if (priority.key !== undefined) {
                    onChangeDefault(priority.key);
                  }
                }}
                label='Set as Default'
              />
            </Dropdown>
          )}
        </Rightrow1>
      </Statusrow>
    ));
  }, [currentProject?.priority?.default, onChangeDefault]);

  return (
    <Box>
      <Row>
        <Leftrow>
          <Title>Priorities</Title>
          <Subtitle>
            Priorities communicate and prioritize tasks. Issues are linearly ordered by default, but can be changed in
            different views.
          </Subtitle>
        </Leftrow>
        <Autotext>
          {loadingButton ? (
            <Button isLoading={loadingButton} />
          ) : (
            <label className='switch'>
              <input
                type='checkbox'
                checked={currentProject?.priority?.enabled || false}
                onChange={() => onUpdatePriority(!currentProject?.priority?.enabled)}
              />
              <span className='slider round'></span>
            </label>
          )}
        </Autotext>
      </Row>
      {currentProject?.priority?.enabled === true && <Status>{priorityElements}</Status>}
    </Box>
  );
};

export default PrioritySettingCard;

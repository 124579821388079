import { CloseIcon, Content, FileBox, Header, Icon, IconSection, Maindiv, Uploaddiv } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import TiptapEditor from '../../tiptapEditor';
import { CommentFilesInterface } from '../../../interfaces/TaskInterface';
import RecordingComment from '../../comment/recordingComment';
import { Dropdown as AntdDropdown } from 'antd';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import Button from '../../button';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { handleFileUpload, isMediaRecordingSupported, removeFileFromFirebase } from '../../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useMobileDevice } from '../../../global/useMobile';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { RenderFilePreview } from '../../renderFilePreview';
import ModalCustom from '../modal';
import InsertGifModal from '../insertGifModal';
import { Dropdown } from '../../Dropdown';
import CommentDropdown from '../../dropdowns/commentDropdown/commentdropdown';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';

interface Props {
  onClose: () => void;
  sendComment: (files: CommentFilesInterface[]) => void;
  users: any;
  uploadedFiles: CommentFilesInterface[];
  setUploadedFiles: any;
  setValueContent: any;
  propsLoading: boolean;
  setPropsLoading: (value: boolean) => void;
  onClickSelectFile: () => void;
  handleOpenSnapModal: () => void;
  isEdit?: boolean;
  inlineRef?: any;
  disable: boolean;
  valueContent: string;
}

const Commentmodal = forwardRef((props: Props, ref) => {
  const {
    onClose,
    sendComment,
    users,
    uploadedFiles,
    setUploadedFiles,
    setValueContent,
    propsLoading,
    setPropsLoading,
    onClickSelectFile,
    handleOpenSnapModal,
    disable = false,
    valueContent
  } = props;
  //Refs
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  //States
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [isEmojiDropdownOpen, setIsEmojiDropdownOpen] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [openGifModal, setIsOpenGifModal] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector } = stateSelector || {};
  const { uploadFilePercent } = projectSelector;
  //Other variable
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  // Ref functions which are call from parent component
  useImperativeHandle(ref, () => ({
    addRecordingCard(fileUrl: string, fileName: string) {
      editorRef.current.addRecordingCard(fileUrl, fileName);
    },
    addImageInContent(fileUrl: string) {
      editorRef.current.addImage(fileUrl);
    },
    clearContent() {
      editorRef?.current?.clearValue();
    }
  }));

  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, videoId: string) => {
    editorRef?.current?.addRecordingCard(firebaseUrl, fileName, videoId);
  }, []);

  // On select emoji
  const handleEmojiSelected = useCallback((emojiData: EmojiClickData) => {
    const currentEmoji = UserPreferenceSingleton.getInstance().getUserUseEmoji();
    const updatedEmojis = [
      emojiData?.emoji,
      ...(Array.isArray(currentEmoji) ? currentEmoji.filter((e) => e !== emojiData?.emoji) : [])
    ]?.slice(0, 2);
    UserPreferenceSingleton.getInstance().setUserUseEmoji(updatedEmojis);
    editorRef.current.addContentExistingLine(emojiData.emoji);
  }, []);

  const openEmojiDropdown = useCallback((value: boolean) => {
    setIsEmojiDropdownOpen(value);
  }, []);

  const onSendComment = useCallback(() => {
    setUploadedFiles([]);
    setValueContent('');
    sendComment(uploadedFiles);
  }, [sendComment, setUploadedFiles, setValueContent, uploadedFiles]);

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files?.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        setFileUploading(true);
        const fileArray = Array.from(files); // Convert FileList to Array

        //call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(uploadedFiles || [], fileArray, dispatch, editorRef);
        const value = [...(uploadedFiles || []), ...(updatedFiles || [])];
        setUploadedFiles(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setFileUploading(false);
      }
    },
    [dispatch, setUploadedFiles, uploadedFiles]
  );

  const onClickClose = useCallback(
    (index: number, file: CommentFilesInterface) => {
      if (file && !file.uploadFileType && file.href.startsWith('https://firebasestorage.googleapis'))
        removeFileFromFirebase(file.href, dispatch);
      const filesClone = JSON.parse(JSON.stringify(uploadedFiles));
      filesClone.splice(index, 1);
      setUploadedFiles(filesClone);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [dispatch, uploadedFiles, setUploadedFiles]
  );

  const handleFileInput = useCallback(() => {
    queueMicrotask(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    });
  }, []);

  const addDroppedFiles = useCallback(
    (files: CommentFilesInterface[]) => {
      setUploadedFiles([...(uploadedFiles || []), ...(files || [])]);
    },
    [setUploadedFiles, uploadedFiles]
  );

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);

  //ctrl + enter click update the task details
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        onSendComment();
      }
    },
    [onSendComment]
  );

  return (
    <>
      <Maindiv>
        <Header>
          <p>Update comment</p>
          <CloseIcon onClick={onClose}>
            <SVGIcon
              name='close-icon'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              className='stroke-color'
            />
          </CloseIcon>
        </Header>
        <Content onKeyDown={handleKeyDown}>
          <TiptapEditor
            ref={editorRef}
            valueContent={valueContent}
            setValueContent={setValueContent}
            setLoading={(value: boolean) => {
              if (setPropsLoading) setPropsLoading(value);
              else setLoading(value);
            }}
            setStateLoading={(value: boolean) => setFileUploading(value)}
            addUploadedFiles={addDroppedFiles}
            users={users}
            placeHolder='Leave a comment...'
            recordSnapModalOpen={handleOpenSnapModal}
            handleFileInput={handleFileInput}
            handleGifModal={handleOpenGifModal}
          />
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
          {!disable && uploadedFiles?.length > 0 && (
            <FileBox>
              {uploadedFiles?.length > 0 &&
                uploadedFiles?.map((file: CommentFilesInterface, index: number) => {
                  return (
                    <>
                      <RenderFilePreview
                        key={file?.href}
                        file={file}
                        isClose={true}
                        maxWidth={211}
                        onClose={() => onClickClose(index, file)}
                      />
                    </>
                  );
                })}
            </FileBox>
          )}
          {fileUploading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
          <IconSection>
            <Dropdown
              activeClassName='active'
              content={
                <Icon>
                  <SVGIcon
                    name='comment-attachment-icon'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    className='svg-icon'
                  />
                </Icon>
              }>
              <CommentDropdown onClickUploadFile={handleFileInput} onClickSelectFile={onClickSelectFile} />
            </Dropdown>
            {isMediaRecordingSupported() && (
              <RecordingComment
                setLoading={(value: boolean) => setLoading(value)}
                fieldValue={valueContent}
                setValueContent={(value: string) => setValueContent(value)}
                iscommentModel={true}
                setButtonDisable={(value: boolean) => setButtonDisable(value)}
                handleOpenModal={handleOpenSnapModal}
                uploadSnapEmbed={uploadSnapEmbed}
              />
            )}
            <AntdDropdown
              trigger={['click']}
              placement={isMobile ? 'bottom' : 'bottomRight'}
              getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
              dropdownRender={() => (
                <>
                  <>
                    <div ref={emojiPickerRef}>
                      <EmojiPicker
                        searchPlaceholder={'Search Emoji'}
                        theme={Theme.AUTO}
                        width={258}
                        height={240}
                        onEmojiClick={handleEmojiSelected}
                      />
                    </div>
                  </>
                </>
              )}>
              <Icon onClick={() => openEmojiDropdown(!isEmojiDropdownOpen)}>
                <SVGIcon name='comment-emoji-icon' width='16' height='16' viewBox='0 0 16 16' className='svg-icon' />
              </Icon>
            </AntdDropdown>
            <Button
              title='Update'
              smallbutton={true}
              onClick={onSendComment}
              disabled={loading || propsLoading || buttonDisable}
              commentbtn={true}
              size={'small'}
            />
          </IconSection>
        </Content>
      </Maindiv>
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            editorRef.current.addImage(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
    </>
  );
});

export default Commentmodal;

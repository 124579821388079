import React, { useCallback, useEffect, useState } from 'react';
import ProfileDropdown from '../dropdowns/navigatedropdown';
import { NavbarMain, EmptyDiv } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTime, stopTracker, updateTimeLogDetails } from '../../services/timeTrackingServices';
import Navbarcontent from './navbarcomponent';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import { setSuccessMessage } from '../../actions/messageActions';
import { TrackingActivityInterface } from '../../interfaces/TimeTrackingInterface';
import ModalCustom from '../models/modal';
import PlanUpgradeModel from '../models/planUpgradeModel';
import { rootStore } from '../../mobx/rootStore';
import { observer } from 'mobx-react-lite';
import { getTimelogData } from '../../services/taskServices';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useLocation } from 'react-router-dom';

interface Props {
  setOpenContactUsModal: () => void;
}

const Navbar: React.FC<Props> = (Props: Props) => {
  const { setOpenContactUsModal } = Props;
  //State
  const [countInvoices, setCountInvoices] = useState(0);

  //use selector state variables with memoized selectors
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    workspace: workspaceSelector,
    timeTracking: timeTrackingSelector,
    app: appSelector,
    invoice: invoiceSelector,
    project: projectSelector
  } = stateSelector || {};

  const { invoices } = invoiceSelector || {};
  const { workspace } = workspaceSelector || {};
  const { isAdmin } = workspace || {};
  const { trackingActivity } = timeTrackingSelector || {};
  const { isNavbarShow } = appSelector || {};
  const { currentProject } = projectSelector;

  //Mobx store variable
  const { projectStore } = rootStore;
  const { favoriteProjects } = projectStore;

  const [isUpgradeModelOpen, setIsUpgradeModelOpen] = useState(false);
  //Other variable
  const dispatch = useDispatch();
  const location = useLocation();
  const taskId = new URLSearchParams(location?.search).get('task');

  const onClickStartStop = useCallback(
    async (itemId: string, startingTime: string, item: TrackingActivityInterface) => {
      if (!itemId) return;
      const date1 = new Date(startingTime).getTime();
      const date2 = new Date().getTime();
      const timeDifference = date2 - date1; // Difference in milliseconds
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      const historyUpdated = item?.History?.map((history: any) => {
        const { __typename, ...other } = history;
        return other;
      });

      const historyHoursSum = item?.History?.reduce((total, historyItem) => {
        return total + (historyItem.Hours || 0);
      }, 0);

      const payloadUpdate = {
        Hours: Number(Number(historyHoursSum || 0) + hoursDifference),
        TimerStartedAtUnset: true,
        History: [
          ...(historyUpdated || []),
          {
            _id: nanoid(),
            CreatedBy: userDetails?.id,
            CreatedTime: new Date().toISOString(),
            UpdatedBy: userDetails?.id,
            UpdatedTime: new Date().toISOString(),
            TimerStartedAt: startingTime,
            TimerEndedAt: new Date().toISOString(),
            Hours: hoursDifference
          }
        ]
      };
      const result = await dispatch(updateTimeLogDetails(itemId, payloadUpdate));

      if (result) {
        dispatch(stopTracker());
        dispatch(setSuccessMessage('Time Stopped!'));
        if (taskId && taskId === item?.TaskId) await dispatch(getTimelogData(currentProject?.id, taskId));
      }
      await dispatch(getActiveTime());
    },
    [currentProject?.id, dispatch, taskId]
  );

  const loadData = useCallback(async () => {
    const updatedList = invoices?.filter((item: any) => item?.statusText !== 'PAID');
    setCountInvoices(updatedList?.length);
  }, [invoices]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const clickOpenModel = () => {
    setIsUpgradeModelOpen(true);
  };
  const clickCloseModel = () => {
    setIsUpgradeModelOpen(false);
  };

  return (
    <>
      {!window.location.hostname.startsWith('embed') && isNavbarShow ? (
        <NavbarMain className={trackingActivity?.length > 0 ? 'timer-navbar' : 'navbar'}>
          <div>
            <ProfileDropdown />
            <Navbarcontent
              isAdmin={isAdmin}
              countInvoices={countInvoices}
              favouriteProjects={favoriteProjects}
              trackingActivity={trackingActivity}
              setOpenContactUsModal={setOpenContactUsModal}
              onClickOpenModel={clickOpenModel}
              onClickStartStop={(id, startingTime, item) => onClickStartStop(id, startingTime, item)}></Navbarcontent>
          </div>
        </NavbarMain>
      ) : (
        <EmptyDiv isNavbarHide={!isNavbarShow}></EmptyDiv>
      )}
      <ModalCustom open={isUpgradeModelOpen} isFreePlanModel={true} onClose={clickCloseModel} isZindex={true}>
        <PlanUpgradeModel />
      </ModalCustom>
    </>
  );
};

export default observer(Navbar);

import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DragDropContext,
  DropResult,
  Droppable,
  DraggableProvided,
  Draggable,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import Avatar from '../../../../../component/avatar/Avatar';

import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import MyCustomContextMenu from '../../../../../component/customContextMenu';
import AssignBox from '../../../../../component/dropdowns/assignPopup';
import Prioritydropdown from '../../../../../component/dropdowns/priorityDropdown';
import TaskStatusDropdown from '../../../../../component/dropdowns/taskStatusDropdown';
import { RenderFilePreview } from '../../../../../component/renderFilePreview';
import RenderHtml from '../../../../../component/renderHtml';
import RenderVideoFile from '../../../../../component/renderVideoFile';
import {
  COMMENT_TYPE,
  FILE_TYPES,
  LABEL_COLOUR_HASH,
  LINK_FILE_DATA,
  MENU_OPTIONS
} from '../../../../../global/constants';
import { calculateDueTime, categorizeFiles } from '../../../../../helpers/common';
import { StatusListInterface } from '../../../../../interfaces/ProjectInterface';
import Linkify from 'react-linkify';
import {
  HeaderContent,
  SubTaskList,
  Form,
  TextBlock,
  Subtasktitle,
  FormBlock,
  InputTitle,
  Maintask,
  Form2,
  Inputvalues,
  TaskLabel,
  Taskcount,
  Taskarea,
  TaskAreaFile,
  SubTaskSection,
  AddSubTaskSection,
  Addsubtask,
  SubtaskHeadLeft,
  TaskIndicator,
  TaskIndicatorText,
  SubtaskHeadRight,
  AddsubtaskIcon,
  IconPoint,
  Divsubtask,
  Subtask,
  Taskitem,
  Iconhidden,
  Form1,
  Label,
  Assigngroup,
  AvatarImage,
  Right,
  Multiplelabel,
  Dropblock,
  Doticon,
  Droplabel,
  Duedate,
  Detailtask,
  Bottomcontent,
  Dot,
  GithubMainDiv,
  GithubTitle,
  Githubsmallicon,
  Labelicon,
  MergeText,
  Mergedlabel,
  Texts,
  GithubPRSection,
  ButtonIcon,
  LinkSection,
  LinkCard,
  Content,
  SocialIcon,
  LinkText,
  LinkRightContent,
  LinkOpenIcon,
  TimeText,
  LinkMoreOption,
  AddLinkIcon,
  LinkTitle,
  TooltipContent
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubTaskList,
  setMyTaskDetailsData,
  setSubTaskList,
  updateCreateTaskInput,
  updateMyTaskDetailsData,
  updatSubTaskList
} from '../../../../../actions/taskActions';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { CommentFilesInterface, TaskDetailsInterface, TaskFileData } from '../../../../../interfaces/TaskInterface';
import {
  updateTaskDetails,
  getFigmaPreviewUrl,
  getMyTaskDetailsData,
  createNewComment,
  getTaskGroupList,
  deleteTaskLink
} from '../../../../../services/taskServices';
import { useMobileDevice } from '../../../../../global/useMobile';
import { nanoid } from 'nanoid';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import { useHistory } from 'react-router-dom';
import { RenderImagePreview } from '../../../../../component/renderImagePreview';
import { Dropdown, DropdownItem } from '../../../../../component/Dropdown';
import { captureException } from '../../../../../services/logService';
import ModalCustom from '../../../../../component/models/modal';
import Deletemodal from '../../../../../component/models/deleteModel';
import { LinkFileModal } from '../../../../../component/models/linkFileModal';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../../../actions/messageActions';
import Tooltip from '../../../../../component/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

interface Props {
  parentTask?: TaskDetailsInterface;
  setParentTask: (value: TaskDetailsInterface) => void;
  setFigmaRefreshLoading: (value: boolean) => void;
  taskId?: string | null;
  isTaskDetailPreview?: boolean;
  figmaRefreshLoading: boolean;
  setSubTaskOpen: (value: boolean) => void;
  loadSubTasksData: () => void;
  setOpen: (value: boolean) => void;
  handleOpenConfirmationModal: (value: boolean) => void;
  onUpdateStatus: (
    status: StatusListInterface,
    previousStatus: StatusListInterface,
    task: TaskDetailsInterface,
    isSubTask?: boolean,
    isParentTask?: boolean
  ) => void;
  onClickClone: (task: TaskDetailsInterface) => void;
  onClickEdit: (taskDetails: TaskDetailsInterface) => void;
  Opendeletemodel: (taskId: string, subTask: boolean) => void;
  loadData: () => void;
  subTaskOpen: boolean;
}
const TaskInfo: React.FC<Props> = (props) => {
  const {
    parentTask,
    setParentTask,
    setFigmaRefreshLoading,
    taskId,
    isTaskDetailPreview,
    onUpdateStatus,
    handleOpenConfirmationModal,
    setOpen,
    loadSubTasksData,
    setSubTaskOpen,
    figmaRefreshLoading,
    loadData,
    Opendeletemodel,
    onClickEdit,
    onClickClone,
    subTaskOpen
  } = props;

  //States
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');
  const [deleteModelopen, setDeleteModelopen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemData, setItemData] = useState<TaskFileData | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, task: taskSelector, auth: authSelector } = stateSelector || {};
  const { taskDetails, taskGroups, subTaskList } = taskSelector;
  const { imageFiles, videoFiles, audioFiles, otherFiles } = categorizeFiles(taskDetails?.Files || []);
  const { currentProject } = projectSelector;
  const { userDetails } = authSelector;

  // Other variables
  const emptyDescPattern = /^<p>(\s*|<br\s*\/?>)*<\/p>$/;
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const history = useHistory();
  const completedTaskPercentage = (subTaskList?.filter((item) => item.status).length / subTaskList?.length) * 100;
  let statusDetails: StatusListInterface | undefined, parentTaskStatus: StatusListInterface | undefined;

  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
    userSelect: 'none',
    background: isDragging ? 'var(--background-primary)' : 'none',
    borderRadius: isDragging ? 40 : 40,
    border: isDragging ? '0.5px solid var(--border-primary)' : '0.5px solid transparent',
    boxShadow: isDragging ? 'var(--shadow-card-hover)' : 'none',
    ...draggableStyle
  });

  if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
    if (!taskDetails?.statusId) statusDetails = currentProject?.defaultStatus;
    else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === taskDetails?.statusId);

    if (!parentTask?.statusId) parentTaskStatus = currentProject?.defaultStatus;
    else parentTaskStatus = currentProject?.statusData?.find((x) => x['_id'] === parentTask?.statusId);
  }

  //handle click user
  const onClickUsers = useCallback(
    (taskId: string) => {
      if (mobile) {
        setIsAssignDropdownOpen(true);
        setCurrentAssignBox(taskId);
      }
    },
    [mobile]
  );

  const getMidTaskIndex = (destinationIndex: number, sourceIndex: number, subTaskList: any[]): number => {
    let previousTaskIndex;
    let nextTaskIndex;
    if (destinationIndex > sourceIndex) {
      previousTaskIndex = subTaskList?.[destinationIndex]?.subtaskIndex || 0;
      nextTaskIndex = subTaskList?.[destinationIndex + 1]?.subtaskIndex || 0;
    } else {
      previousTaskIndex = subTaskList?.[destinationIndex - 1]?.subtaskIndex || 0;
      nextTaskIndex = subTaskList?.[destinationIndex]?.subtaskIndex || 0;
    }
    return (previousTaskIndex + nextTaskIndex) / 2;
  };

  //calculate the update task index
  const calculateUpdatedTaskIndex = (destinationIndex: number, sourceIndex: number): number => {
    let updatedTaskIndex;
    if (destinationIndex === 0) {
      const nextTaskIndex = subTaskList?.[0]?.subtaskIndex;
      if (nextTaskIndex) {
        updatedTaskIndex = nextTaskIndex - 500;
      } else {
        updatedTaskIndex = new Date().getTime();
      }
    } else if (destinationIndex === subTaskList?.length - 1) {
      const previousTaskIndex = subTaskList?.[subTaskList?.length - 1]?.subtaskIndex;
      if (previousTaskIndex) {
        updatedTaskIndex = previousTaskIndex + 500;
      } else {
        updatedTaskIndex = new Date().getTime();
      }
    } else {
      updatedTaskIndex = getMidTaskIndex(destinationIndex, sourceIndex, subTaskList);
    }
    return updatedTaskIndex;
  };

  //update subtask list
  const updateSubTaskList = (draggableId: string, updatedTaskIndex: number) => {
    const updatedList = subTaskList?.map((task) =>
      task?.id === draggableId ? { ...(task || {}), subtaskIndex: updatedTaskIndex } : task
    );
    return updatedList?.toSorted((a, b) => (a?.subtaskIndex ?? 0) - (b?.subtaskIndex ?? 0));
  };

  //subtask drag and drop
  const handleDragEnd = async (item: DropResult) => {
    try {
      const { destination, draggableId, source } = item || {};
      const { index: sourceIndex } = source || {};
      const { index: destinationIndex } = destination;
      const updatedTaskIndex = calculateUpdatedTaskIndex(destinationIndex, sourceIndex);
      const sortedList = updateSubTaskList(draggableId, updatedTaskIndex);
      dispatch(setSubTaskList(sortedList));
      const payload = {
        projectId: taskDetails?.projectId,
        subtaskIndex: updatedTaskIndex
      };
      dispatch(updateTaskDetails(draggableId, payload));
    } catch (error) {
      console.log('error', error);
    }
  };

  const onClickCheckBox = useCallback(
    async (body: HTMLBodyElement | null, htmlElement: HTMLElement | null | undefined) => {
      if (!htmlElement || !body) return;

      // Extract div content from htmlElement
      const htmlElementDiv = htmlElement.querySelector('div');
      if (!htmlElementDiv) return;

      const htmlElementDivHTML = htmlElementDiv.innerHTML.trim();

      // Find matching li in the parsed body
      const matchingLi = Array.from(body.querySelectorAll('li[data-type="taskItem"]')).find((li) => {
        const liDiv = li.querySelector('div');
        return liDiv && liDiv.innerHTML.trim() === htmlElementDivHTML;
      });

      if (matchingLi) {
        const input = matchingLi.querySelector('input[type="checkbox"]');
        if (input) {
          if (input.hasAttribute('checked')) {
            input.removeAttribute('checked');
            matchingLi.setAttribute('data-checked', 'false');
            // Remove checked if it exists
          } else {
            input.setAttribute('checked', 'true');
            matchingLi.setAttribute('data-checked', 'true');
            // Add checked without a value
          }
        }
      }
      await dispatch(updateMyTaskDetailsData({ propsName: 'description', value: body.innerHTML }));
      const payloadTask = { description: body.innerHTML, projectId: taskDetails?.projectId };
      await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
    },
    [dispatch, taskDetails?.id, taskDetails?.projectId]
  );

  const onClickHandleRefreshFigmaDescription = useCallback(
    async (event: React.MouseEvent, item: TaskDetailsInterface) => {
      try {
        setFigmaRefreshLoading(true);
        const clickedElement = event.target as HTMLElement;
        const clickedId = clickedElement.id;
        const parser = new DOMParser();
        const doc = parser.parseFromString(item?.description, 'text/html');
        const body = doc.querySelector('body');
        if (clickedId === 'figma-refresh-ref') {
          if (body) {
            const figmaUrlDiv = body.querySelector('.figma-link-div') as HTMLAnchorElement;
            const refreshImgDiv = body.querySelector('.refresh-icon');

            if (refreshImgDiv) {
              refreshImgDiv.classList.add('refresh-icon-spin');
            }

            dispatch(updateMyTaskDetailsData({ propsName: 'description', value: body.innerHTML }));
            const url = new URL(figmaUrlDiv?.href ?? '');
            const fileId = url.pathname.split('/')[2];
            const nodes = url.searchParams.get('node-id');
            const figmaData = await dispatch(getFigmaPreviewUrl({ fileId, nodes }));
            if (figmaData) {
              const previewImage = body.querySelector('.figma-preview-image') as HTMLImageElement;
              if (previewImage) {
                previewImage.src = figmaData?.filePreviewUrl; // Replace 'yourNewImageUrl' with the new URL
              }
              const description = body.querySelector('.figma-description');
              if (description) {
                description.textContent = figmaData?.frameNames; // Replace 'YourNewDescription' with the new text
              }
              if (refreshImgDiv) {
                refreshImgDiv.classList.remove('refresh-icon-spin');
              }
              const filename = body.querySelector('.figma-filename');
              if (filename) {
                filename.textContent = figmaData?.projectName; // Replace 'YourNewFilename' with the new text
              }
              const updatedHtmlString = body.innerHTML;
              dispatch(updateMyTaskDetailsData({ propsName: 'description', value: updatedHtmlString }));
              const payloadTask = { description: updatedHtmlString, projectId: taskDetails?.projectId };
              const result = await dispatch(updateTaskDetails(taskDetails?.id, payloadTask));
              if (result) {
                await dispatch(getMyTaskDetailsData(taskId));
              }
            }
          }
        } else {
          const subParentElement = clickedElement.parentElement;
          const parentElement = subParentElement?.parentElement;
          onClickCheckBox(body, parentElement);
        }
      } catch (e) {
        captureException(e);
      } finally {
        setFigmaRefreshLoading(false);
      }
    },
    [dispatch, onClickCheckBox, setFigmaRefreshLoading, taskDetails?.id, taskDetails?.projectId, taskId]
  );

  //all subtask done and click check box
  const onClickTaskCheckbox = useCallback(
    async (item: TaskDetailsInterface, value: boolean, isFromSubTask = false, isFromParentTask = false) => {
      let allSubTasksStatus = true;
      if (subTaskList && subTaskList?.length > 0) {
        for (const subTask of subTaskList) {
          if (subTask?.status === false) {
            allSubTasksStatus = false;
            break;
          }
        }
      }
      if (allSubTasksStatus === false && !item?.status && isEmpty(item?.parentTaskId)) {
        handleOpenConfirmationModal(true);
        return;
      }
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      if (!isFromSubTask && !isFromParentTask) {
        const previousUpdatedComment =
          !isEmpty(item?.updatedComments) && item?.updatedComments !== undefined ? item?.updatedComments : [];
        const updatedComments = [
          ...previousUpdatedComment,
          {
            id: nanoid(),
            type: COMMENT_TYPE.STATUS_CHANGE,
            updatedBy: userDetails?.id,
            updatedTime: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            user: { ...userDetails, name: userDetails?.given_name },
            status: value
          }
        ];
        dispatch(updateMyTaskDetailsData({ propsName: 'status', value: value }));
        dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
      }
      const payloadTask = { status: !item?.status, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: value
        })
      );
      if (result && commentResult) {
        if (isFromSubTask) {
          const taskDetailsClone = JSON.parse(JSON.stringify(item));
          taskDetailsClone.status = value;
          dispatch(updatSubTaskList(taskDetailsClone));
        }
        if (isFromParentTask) {
          const taskDetailsClone = JSON.parse(JSON.stringify(parentTask));
          taskDetailsClone.status = value;
          if (parentTask) setParentTask(taskDetailsClone);
        }
        if (value) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
      }
    },
    [dispatch, handleOpenConfirmationModal, parentTask, setParentTask, subTaskList]
  );

  // handle File card click in task description
  const handleTaskAreaFileCard = useCallback(
    (e: React.SyntheticEvent, x: CommentFilesInterface) => {
      if (
        x?.uploadFileType &&
        [
          FILE_TYPES.DRIVE,
          FILE_TYPES.DROPBOX,
          FILE_TYPES.NOTION,
          FILE_TYPES.FIGMA,
          FILE_TYPES.ONEDRIVE,
          FILE_TYPES.DOCS,
          FILE_TYPES.WHITEBOARD
        ].includes(x?.uploadFileType)
      ) {
        window.open(x?.href, '_blank', 'noopener,noreferrer');
        return;
      }
      const fileName = x?.name?.replaceAll(' ', '_');
      e.preventDefault();
      const path = x?.href?.startsWith('/projects/files')
        ? x?.href
        : `/projects/details/${taskDetails?.projectId}/files/file-view/${fileName}?fileUrl=${x?.href}`;
      history.push(path);
    },
    [history, taskDetails?.projectId]
  );

  const menuItems = [
    {
      key: MENU_OPTIONS.COPYLINK,
      label: 'Copy link'
    },
    {
      key: MENU_OPTIONS.EDIT,
      label: 'Edit link'
    },
    {
      key: MENU_OPTIONS.DELETE,
      label: 'Delete',
      tone: 'critical'
    }
  ];

  const handleClick = useCallback(
    async (key: number | string, file: TaskFileData) => {
      key = Number(key);
      if (key === MENU_OPTIONS.COPYLINK) {
        await copy(file.url);
        dispatch(setSuccessMessage(`Link copied successfully!`));
      } else if (key === MENU_OPTIONS.EDIT) {
        setItemData(file);
        setIsOpen(true);
      } else if (key === MENU_OPTIONS.DELETE) {
        setItemData(file);
        setDeleteModelopen(true);
      }
    },
    [dispatch]
  );

  const close = () => {
    setDeleteModelopen(false);
    setItemData(null);
  };

  // for open model
  const openModel = useCallback(() => {
    setIsOpen(true);
  }, []);

  // for cancel model
  const cancelModel = useCallback(() => {
    setIsOpen(false);
    setItemData(null);
  }, []);

  // For delete link
  const deleteLinkData = useCallback(async () => {
    try {
      setDeleteLoading(true);
      const response = await dispatch(deleteTaskLink(taskId, itemData?.id));
      if (response) {
        const resources = taskDetails?.links?.filter((link) => link['_id'] !== itemData?.id);
        dispatch(updateMyTaskDetailsData({ propsName: 'links', value: resources }));
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setDeleteLoading(false);
      close();
    }
  }, [dispatch, itemData?.id, taskDetails, taskId]);

  //create sub task
  const onClickSubTask = useCallback(async () => {
    dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
    await dispatch(getTaskGroupList(currentProject?.id, true));
    if (taskGroups?.length > 0) {
      dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
    }
    dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
    dispatch(updateCreateTaskInput({ propsName: 'parentTaskId', value: taskId }));
    setOpen(true);
  }, [dispatch, currentProject, taskGroups, taskId, setOpen]);

  //handle click sub task
  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      dispatch(clearSubTaskList());
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?task=${task?.id}`);
    },
    [dispatch, history]
  );

  // render pull request data
  const renderPullRequestData = useMemo(() => {
    if (!taskDetails?.pullRequests) {
      return null;
    }
    return (
      <>
        {taskDetails?.pullRequests?.map((pullData) => (
          <GithubMainDiv onClick={() => window.open(pullData.url, '_blank')} key={pullData.number}>
            <Githubsmallicon>
              <SVGIcon name='github-small-icon' width='14' height='14' viewBox='0 0 14 14' className='github-icon' />
            </Githubsmallicon>
            <Bottomcontent>
              <Mergedlabel>
                {pullData.state === 'open' ? (
                  <Labelicon>
                    <SVGIcon name='branch-opened-icon' width='14' height='14' viewBox='0 0 14 14' />
                  </Labelicon>
                ) : pullData.merged ? (
                  <Labelicon>
                    <SVGIcon name='branch-merged-icon' width='14' height='14' viewBox='0 0 14 14' />
                  </Labelicon>
                ) : (
                  <Labelicon>
                    <SVGIcon name='branch-closed-icon' width='14' height='14' viewBox='0 0 14 14' />
                  </Labelicon>
                )}
                <Texts>{pullData.number}</Texts>
                <GithubTitle>{pullData.title}</GithubTitle>
                <Dot />
                <MergeText>{pullData.state === 'open' ? 'Opened' : pullData.merged ? 'Merged' : 'Closed'}</MergeText>
              </Mergedlabel>
            </Bottomcontent>
          </GithubMainDiv>
        ))}
      </>
    );
  }, [taskDetails?.pullRequests]);
  return (
    <>
      <HeaderContent isTaskDetailPreview={isTaskDetailPreview}>
        <SubTaskList className={taskDetails?.parentTaskId && parentTask ? 'has-maintask' : ''}>
          <Linkify
            componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
              <a target='blank' href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}>
            <TextBlock>
              <Subtasktitle>
                <FormBlock>
                  <Form>
                    {statusDetails ? (
                      <TaskStatusDropdown
                        defaultStatus={currentProject?.defaultStatus}
                        statusList={currentProject?.statusData}
                        currentStatusId={taskDetails?.statusId}
                        isTaskCheckBox={true}
                        isStatusIcon={true}
                        onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                          onUpdateStatus(status, previousStatus, taskDetails)
                        }
                        isZindex={true}
                      />
                    ) : (
                      <div
                        className='img'
                        onClick={() => onClickTaskCheckbox(taskDetails, !taskDetails.status)}
                        style={{ cursor: 'pointer' }}>
                        {taskDetails?.status ? (
                          <SVGIcon
                            name='complete-icon'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            className='complete-icon'
                          />
                        ) : (
                          <SVGIcon
                            name='open-icon'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            className='task-open-icon'
                          />
                        )}
                      </div>
                    )}
                    <InputTitle>{taskDetails?.name}</InputTitle>
                  </Form>
                </FormBlock>
                {!isEmpty(taskDetails?.parentTaskId) && parentTask && (
                  <Maintask>
                    <span>Sub task of</span>
                    <Form2 onClick={() => onClickTaskDetails(parentTask)}>
                      {parentTaskStatus ? (
                        <TaskStatusDropdown
                          defaultStatus={currentProject?.defaultStatus}
                          statusList={currentProject?.statusData}
                          currentStatusId={parentTask?.statusId}
                          isTaskCheckBox={true}
                          onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                            onUpdateStatus(status, previousStatus, parentTask, false, true)
                          }
                        />
                      ) : (
                        <Inputvalues
                          type='checkbox'
                          className='checkbox-round'
                          checked={parentTask?.status}
                          onClick={(e) => {
                            e.stopPropagation();
                            parentTask && onClickTaskCheckbox(parentTask, !parentTask.status, false, true);
                          }}
                        />
                      )}
                      <Linkify
                        componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                          <a target='blank' href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}>
                        <TaskLabel>{parentTask?.name}</TaskLabel>
                      </Linkify>
                    </Form2>
                    {parentTask?.subTasks && (
                      <Taskcount>
                        {parentTask?.subTasks?.filter((x) => x?.status)?.length}/{parentTask?.subTasks?.length}
                      </Taskcount>
                    )}
                  </Maintask>
                )}
              </Subtasktitle>
              {((!emptyDescPattern.test(taskDetails?.description) && taskDetails?.description !== '') ||
                (taskDetails?.Files && taskDetails?.Files?.length > 0)) && (
                <>
                  {(taskDetails?.description || taskDetails?.Files?.length > 0) && (
                    <Taskarea
                      className='tiptap'
                      figmaRefreshLoading={figmaRefreshLoading}
                      onClick={(e) => onClickHandleRefreshFigmaDescription(e, taskDetails)}>
                      <RenderHtml htmlstring={taskDetails?.description || ''} />
                      {imageFiles?.length > 0 &&
                        imageFiles?.map((item) => {
                          return <RenderImagePreview imageUrl={item?.href} altText={item?.name} />;
                        })}
                      {videoFiles?.length > 0 &&
                        videoFiles?.map((item) => {
                          return <RenderVideoFile key={item?.href} videoUrl={item?.href} videoName={item?.name} />;
                        })}
                      {audioFiles?.length > 0 &&
                        audioFiles?.map((item) => {
                          return (
                            <audio key={item?.href} controls>
                              <source type='audio/mp3' src={item?.href} />
                            </audio>
                          );
                        })}
                      {otherFiles?.length > 0 && (
                        <TaskAreaFile>
                          {otherFiles?.map((x) => {
                            return (
                              <>
                                <RenderFilePreview
                                  key={x?.size}
                                  file={x}
                                  maxWidth={240}
                                  onClickUpdate={(e) => handleTaskAreaFileCard(e, x)}
                                />
                              </>
                            );
                          })}
                        </TaskAreaFile>
                      )}
                    </Taskarea>
                  )}
                </>
              )}
            </TextBlock>
          </Linkify>
          {/* GITHUB REPOSITORY UI */}
          {renderPullRequestData && <GithubPRSection>{renderPullRequestData}</GithubPRSection>}
          <SubTaskSection>
            <AddSubTaskSection>
              {subTaskList?.length === 0 ? (
                <>
                  {isEmpty(taskDetails?.parentTaskId) && (
                    <Addsubtask onClick={onClickSubTask} hasSubtasks={subTaskList?.length > 0}>
                      <SVGIcon name='add-task-icon' width='16' height='16' viewBox='0 0 16 16' className='plus-icon' />
                      <p>Add Subtask</p>
                    </Addsubtask>
                  )}
                </>
              ) : (
                <>
                  <SubtaskHeadLeft>
                    <p>Sub tasks</p>
                    <TaskIndicator completePercentage={completedTaskPercentage}>
                      <div className='circle'>
                        <div className='innerCircle' />
                      </div>
                      <TaskIndicatorText>{`${subTaskList?.filter((task) => task.status).length}/${
                        subTaskList?.length
                      }`}</TaskIndicatorText>
                    </TaskIndicator>
                  </SubtaskHeadLeft>
                </>
              )}
              {!taskDetails?.parentTaskId && (
                <SubtaskHeadRight>
                  {subTaskList?.length !== 0 && (
                    <AddsubtaskIcon onClick={onClickSubTask}>
                      <SVGIcon name='add-task-icon' width='20' height='20' viewBox='0 0 16 16' className='plus-icon' />
                    </AddsubtaskIcon>
                  )}
                  <Dropdown
                    content={
                      <IconPoint>
                        <SVGIcon
                          className='dot-icon'
                          name='task-detail-dot-icon'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                        />
                      </IconPoint>
                    }
                    trigger='click'>
                    <DropdownItem label='Link existing task as sub-Task…' onClick={() => setSubTaskOpen(!subTaskOpen)}>
                      <SVGIcon
                        name='existing-subtask-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        stroke='var(--text-primary)'
                      />
                    </DropdownItem>
                  </Dropdown>
                </SubtaskHeadRight>
              )}
            </AddSubTaskSection>

            <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
              <Droppable droppableId='subTask'>
                {(provided: DraggableProvided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {subTaskList?.length > 0 && (
                      <Divsubtask>
                        {subTaskList?.map((item, index) => {
                          let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;

                          if (item?.dueDate) {
                            dueResult = calculateDueTime(item.dueDate);
                          }

                          let statusSubDetails: StatusListInterface | undefined;
                          if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                            if (!item?.statusId) statusSubDetails = currentProject?.defaultStatus;
                            else
                              statusSubDetails = currentProject?.statusData?.find((x) => x['_id'] === item?.statusId);
                          }
                          return (
                            <>
                              <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  <MyCustomContextMenu
                                    targetId={item?.id}
                                    taskData={item}
                                    loadData={loadData}
                                    currentProject={currentProject}
                                    currentActiveTask={currentActiveTask}
                                    setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                                    onClickView={() => onClickTaskDetails(item)}
                                    onClickClone={() => onClickClone(item)}
                                    onClickEdit={() => onClickEdit(item)}
                                    onClickDelete={() => Opendeletemodel(item.id, true)}
                                    statusList={currentProject?.statusData || []}
                                    isTemplateProject={false}
                                    onUpdateStatus={(
                                      status: StatusListInterface,
                                      previousStatus: StatusListInterface
                                    ) => onUpdateStatus(status, previousStatus, item, true, false)}
                                    isSubTask={true}>
                                    <Subtask
                                      onClick={() => onClickTaskDetails(item)}
                                      isActive={item?.id === currentActiveTask}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...{
                                        style: getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                                      }}>
                                      <Taskitem>
                                        <Iconhidden
                                          onClick={(e) => e.stopPropagation()}
                                          // eslint-disable-next-line react/jsx-props-no-spreading
                                          {...provided.dragHandleProps}>
                                          <SVGIcon
                                            className='svgicon'
                                            name='three-dots-vertical-icon'
                                            width='16'
                                            height='16'
                                            viewBox='0 0 16 16'
                                            fill='none'
                                          />
                                        </Iconhidden>
                                        <Form1>
                                          <div style={{ display: 'flex', gap: 2 }}>
                                            {statusSubDetails ? (
                                              <TaskStatusDropdown
                                                defaultStatus={currentProject?.defaultStatus}
                                                statusList={currentProject?.statusData}
                                                currentStatusId={item?.statusId}
                                                isTaskCheckBox={true}
                                                onUpdateStatus={(
                                                  status: StatusListInterface,
                                                  previousStatus: StatusListInterface
                                                ) => onUpdateStatus(status, previousStatus, item, true, false)}
                                                isHoverIcon={true}
                                              />
                                            ) : (
                                              <ButtonIcon>
                                                <Inputvalues
                                                  type='checkbox'
                                                  className='checkbox-round'
                                                  checked={item?.status}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    onClickTaskCheckbox(item, !item.status, true);
                                                  }}
                                                />
                                              </ButtonIcon>
                                            )}
                                            {currentProject?.priority?.enabled && (
                                              <div
                                                style={{ position: 'relative', display: 'flex' }}
                                                onClick={(e) => e.stopPropagation()}>
                                                <Prioritydropdown
                                                  icon={true}
                                                  taskData={item}
                                                  currentProject={currentProject}
                                                  isTaskItem={true}
                                                  isSubTask={true}
                                                  isTaskCheckBox={true}
                                                  isHoverIcon={true}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <Linkify
                                            componentDecorator={(
                                              decoratedHref: string,
                                              decoratedText: string,
                                              key: number
                                            ) => (
                                              <a target='blank' href={decoratedHref} key={key}>
                                                {decoratedText}
                                              </a>
                                            )}>
                                            <Label>{item?.name}</Label>
                                          </Linkify>
                                        </Form1>
                                        <Assigngroup onClick={(e) => e.stopPropagation()} className='assign'>
                                          <ul onClick={() => onClickUsers(item?.id)}>
                                            {item?.users
                                              ?.filter((item) => !isEmpty(item?.id))
                                              ?.map((user) => {
                                                return (
                                                  <li key={user?.id}>
                                                    <AvatarImage>
                                                      <Avatar
                                                        imgSrc={user?.profile_photo}
                                                        name={user?.name ? user.name : 'U N'}
                                                        size={20}
                                                        isMilestoneBorder={true}
                                                        classname='avtar'
                                                      />
                                                    </AvatarImage>
                                                  </li>
                                                );
                                              })}
                                            <div
                                              style={{ position: 'relative', zIndex: 11, display: 'flex' }}
                                              onClick={(e) => e.stopPropagation()}>
                                              <AssignBox
                                                isDropdownOpen={isAssignDropdownOpen && currentAssignBox === item?.id}
                                                setIsDropdownOpen={setIsAssignDropdownOpen}
                                                projectUsers={currentProject?.users}
                                                currentTask={item}
                                                setCurrentAssignBox={(id) => setCurrentAssignBox(id)}
                                                currentAssignBox={currentAssignBox}
                                                loadData={loadSubTasksData}
                                                isMultiAssignee={currentProject?.multiAssignee}
                                                isSubtask
                                                isTaskdetail={true}
                                              />
                                            </div>
                                          </ul>
                                        </Assigngroup>
                                      </Taskitem>
                                      <Right>
                                        <Multiplelabel>
                                          {item?.labels?.map((label, index) => {
                                            return (
                                              <Dropblock className={index === 0 ? 'left-border' : ''} key={label?.id}>
                                                <Doticon color={`${LABEL_COLOUR_HASH[label?.color]}`} />
                                                <Droplabel>{label?.name}</Droplabel>
                                              </Dropblock>
                                            );
                                          })}
                                        </Multiplelabel>
                                        {!isEmpty(item?.dueDate) && (
                                          <Duedate onClick={(e) => e.stopPropagation()}>
                                            <Tooltip
                                              title={
                                                <>
                                                  <div>Due {dueResult?.dueParam}</div>
                                                  <div>
                                                    Due on {moment(item?.dueDate).format(userDetails?.dateFormat)}
                                                  </div>
                                                </>
                                              }
                                              placement='bottom'
                                              maxWidth={'max-content'}>
                                              <TooltipContent>
                                                <Detailtask isOverdue={dueResult?.overDue && !item?.status}>
                                                  {item.dueDate && dueResult?.dueTime}
                                                </Detailtask>

                                                {dueResult?.overDue && !item?.status ? (
                                                  <SVGIcon
                                                    name='tasklist-calendar-duedate-icon'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    className='calendar-due-fill-color'
                                                  />
                                                ) : (
                                                  <SVGIcon
                                                    name='tasklist-calendar-icon'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    className='calendar-fill-color'
                                                  />
                                                )}
                                              </TooltipContent>
                                            </Tooltip>
                                          </Duedate>
                                        )}
                                      </Right>
                                    </Subtask>
                                  </MyCustomContextMenu>
                                )}
                              </Draggable>
                            </>
                          );
                        })}
                        {provided?.placeholder}
                      </Divsubtask>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {taskDetails?.links && taskDetails.links.length > 0 && (
              <LinkSection>
                <LinkTitle>
                  <p>Links</p>
                  <AddLinkIcon onClick={openModel}>
                    <SVGIcon name='add-task-icon' width='20' height='20' viewBox='0 0 16 16' className='plus-icon' />
                  </AddLinkIcon>
                </LinkTitle>
                {taskDetails.links.map((link) => {
                  const fileData: TaskFileData = {
                    id: link['_id'],
                    name: link.Label || '',
                    url: link.Reference || ''
                  };
                  return (
                    <LinkCard key={link['_id']}>
                      <Content>
                        <SocialIcon>
                          {!isEmpty(link.IconUrl) ? (
                            <img src={link.IconUrl} alt='' width='20' height='20' />
                          ) : (
                            <SVGIcon
                              name={'copy-link-icon'}
                              width='20'
                              height='20'
                              viewBox={'0 0 20 20'}
                              className={'copy-link-icon'}
                            />
                          )}
                        </SocialIcon>
                        <LinkText>
                          <span>{link.Label}</span>
                        </LinkText>
                      </Content>
                      <LinkRightContent>
                        <TimeText>{moment(link.CreatedTime).fromNow()}</TimeText>
                        <LinkOpenIcon onClick={() => window.open(link.Reference, '_blank', 'noopener,noreferrer')}>
                          <SVGIcon name='external-link-icon' width='14' height='14' viewBox='0 0 14 14' />
                        </LinkOpenIcon>

                        <Dropdown
                          content={
                            <LinkMoreOption>
                              <SVGIcon name='task-detail-dot-icon' width='14' height='14' viewBox='0 0 24 24' />
                            </LinkMoreOption>
                          }
                          trigger='click'>
                          {link.Readonly ? (
                            <DropdownItem
                              label={'Copy link'}
                              key={MENU_OPTIONS.COPYLINK}
                              tone={'primary'}
                              onClick={() => handleClick(MENU_OPTIONS.COPYLINK, fileData)}
                            />
                          ) : (
                            menuItems.map((item) => (
                              <DropdownItem
                                label={item.label}
                                key={item?.key}
                                tone={item.label === 'Delete' ? 'critical' : 'primary'}
                                onClick={() => handleClick(item.key, fileData)}
                              />
                            ))
                          )}
                        </Dropdown>
                      </LinkRightContent>
                    </LinkCard>
                  );
                })}
              </LinkSection>
            )}
          </SubTaskSection>
        </SubTaskList>
      </HeaderContent>
      <ModalCustom open={deleteModelopen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          onOk={deleteLinkData}
          loading={deleteLoading}
          modaltitle={'Delete Link?'}
          description={'Are you sure you want to delete this Link?'}
        />
      </ModalCustom>
      <ModalCustom open={isOpen} onClose={cancelModel} width={462}>
        <LinkFileModal
          onClose={cancelModel}
          modalData={LINK_FILE_DATA.ADDLINK}
          taskId={taskDetails?.id}
          itemData={itemData}
        />
      </ModalCustom>
    </>
  );
};

export default TaskInfo;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentProject, updateProjectDetail } from '../../../actions/projectActions';
import DatePicker from '../../dateTime/datePicker';
import { isEmpty } from '../../../helpers/common';
import { getCustomerList } from '../../../services/customerServices';
import {
  Form,
  Formation,
  Formblock,
  Formbutton,
  Formview,
  IconDiv,
  Input,
  InputDiv,
  ItemDiv,
  Label,
  Modalblock,
  SelectedText,
  SelectInput
} from './styles';
import Button from '../../button';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { createNewProject, getProjectList, updateProjectData } from '../../../services/projectServices';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { captureException } from '../../../services/logService';
import { getProjectTemplateList } from '../../../services/settingServices';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { CreateProjectPayloadInterface } from '../../../interfaces/ProjectInterface';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { useMobileDevice } from '../../../global/useMobile';
import { rootStore } from '../../../mobx/rootStore';

interface Props {
  handleCloseModal?: () => void;
  loadProjectData?: () => void;
  onClose?: () => void;
  onClickCancel?: () => void;
  isCustomerDetail?: boolean;
}

const CreateNewProjectModal: React.FC<Props> = ({
  handleCloseModal,
  loadProjectData,
  onClose,
  onClickCancel,
  isCustomerDetail = false
}) => {
  //States
  const [loading, setLoading] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isTemplateDropdownOpen, setIsTemplateDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const isMobile = useMobileDevice();
  const isMinWidth = isMobile ? Math.max(window.innerWidth - 65) : 598;

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    workspace: workspaceSelector,
    customer: customerSelector
  } = stateSelector || {};
  const { projectTemplateList } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { newProjectItem } = projectSelector;
  const { name, customer, dueDate, startDate, template } = newProjectItem;
  const { customers } = customerSelector;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();

  const onUpdateInputValue = useCallback(
    async (propsName: string, value: any) => {
      await dispatch(updateProjectDetail({ propsName, value }));
    },
    [dispatch]
  );
  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      let promises = [dispatch(getCustomerList())];
      if (isEmpty(newProjectItem?.id)) promises.push(dispatch(getProjectTemplateList(workspace_id, true)));
      const result = await Promise.all(promises);
      const customerResponse = result?.[0];
      const customer = customerResponse?.find((x: any) => x.id === newProjectItem?.customerId);
      onUpdateInputValue('customer', customer);
    }
  }, [dispatch, workspace_id, newProjectItem?.id, newProjectItem?.customerId, onUpdateInputValue]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChangeSearch = useCallback((value: string) => {
    setIsSearch(true);
    setSearchQuery(value);
  }, []);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isEmpty(startDate)) {
        dispatch(setErrorMessage('Please select start date'));
        return;
      }
      if (dueDate && new Date(startDate) >= new Date(dueDate)) {
        dispatch(setErrorMessage('Start date must be before due date'));
        return;
      }
      try {
        setLoading(true);
        let result;
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        if (!isEmpty(newProjectItem?.id)) {
          if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't update project.`));
          if (isEmpty(name)) return dispatch(setErrorMessage('Please enter project name'));
          const updatePayload = {
            Name: name,
            CustomerId: !isEmpty(customer?.id) ? customer?.id : null,
            StartDate: !isEmpty(startDate) ? new Date(startDate).toISOString() : undefined,
            DueDate: !isEmpty(dueDate) ? new Date(dueDate).toISOString() : undefined,
            removeStartDate: isEmpty(startDate) ? true : undefined,
            removeDueDate: isEmpty(dueDate) ? true : undefined
          };
          result = await dispatch(updateProjectData(newProjectItem?.id, updatePayload));
          if (result) {
            trackAnalyticActivity(PROJECT_ANALYTICS.UPDATED);
            const projectList = (await dispatch(getProjectList(true))) || [];
            const current_project = projectList?.find((x: { id: string }) => x?.id === newProjectItem?.id);
            dispatch(setCurrentProject(current_project));
            rootStore.projectStore.setCurrentProject(current_project?.id);
            dispatch(setSuccessMessage('Project details updated successfully!'));
          }
        } else {
          if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't create project.`));
          if (isEmpty(name)) return dispatch(setErrorMessage('Please enter project name'));
          const createPayload: CreateProjectPayloadInterface = {
            name: name,
            companyId: workspace_id,
            isEstimate: false,
            userId: userDetails?.id,
            description: ''
          };
          if (!isEmpty(customer?.id)) {
            createPayload.customerId = customer?.id;
          }
          if (!isEmpty(template?.id)) {
            createPayload.templateId = template?.id;
          }
          if (!isEmpty(startDate)) {
            createPayload.startDate = new Date(startDate).toISOString();
          }
          if (!isEmpty(dueDate)) {
            createPayload.dueDate = new Date(dueDate).toISOString();
          }
          result = await dispatch(createNewProject(createPayload));
          if (result) {
            trackAnalyticActivity(PROJECT_ANALYTICS.CREATED, { from_template: !isEmpty(createPayload?.templateId) });
            if (isCustomerDetail) {
              dispatch(getProjectList(true));
            } else {
              await dispatch(getProjectList(true));
              history.push(`/projects/details/${result?.data['_id']}/tasks`);
            }
            dispatch(setSuccessMessage('Project Added successfully!'));
          }
        }
        if (result) {
          if (handleCloseModal) handleCloseModal();
          if (onClose) onClose();
          if (loadProjectData) {
            loadProjectData();
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [
      startDate,
      dispatch,
      newProjectItem?.id,
      name,
      dueDate,
      customer?.id,
      workspace_id,
      template?.id,
      isCustomerDetail,
      history,
      handleCloseModal,
      onClose,
      loadProjectData,
      workspace
    ]
  );
  const handleToggleCustomerDropdown = useCallback(() => {
    setIsCustomerDropdownOpen((prev) => !prev);
  }, []);

  const handleToggleTemplateDropdown = useCallback(() => {
    setIsTemplateDropdownOpen((prev) => !prev);
  }, []);

  const renderCustomerDropdown = useMemo(() => {
    const filteredCustomer = !isSearch
      ? customers
      : customers?.filter((x) => x?.value?.trim().toLowerCase().includes(searchQuery.toLowerCase()));
    return filteredCustomer?.map((item) => {
      const isSelected = item.id === customer?.id;
      return (
        <>
          <DropdownItem
            key={item.id}
            label={item.value}
            onClick={() => {
              setIsSearch(false);
              setSearchQuery('');
              onUpdateInputValue('customer', item);
              setIsCustomerDropdownOpen(false);
            }}
            isSuffix={
              isSelected && <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' />
            }
          />
        </>
      );
    });
  }, [customer?.id, customers, isSearch, onUpdateInputValue, searchQuery]);
  return (
    <>
      <Modalblock onSubmit={onSubmit}>
        <Formation>
          <Formblock>
            <Form>
              <Label>
                Project Name<span>*</span>
              </Label>
              <Input
                placeholder='Enter project name'
                value={name}
                onChange={(e) => {
                  onUpdateInputValue('name', e.target.value);
                }}
              />
            </Form>
          </Formblock>
          {customers?.length > 0 && (
            <Formview>
              <Form>
                <Label>Customer</Label>
                <Dropdown
                  isMinWidth={isMinWidth}
                  content={
                    <InputDiv onClick={handleToggleCustomerDropdown}>
                      <SelectInput
                        placeholder={isEmpty(customer?.companyName) ? 'Select a customer' : ''}
                        value={searchQuery}
                        onChange={(e) => onChangeSearch(e.target.value)}
                      />
                      {isEmpty(searchQuery) && <SelectedText>{customer?.companyName}</SelectedText>}
                      <IconDiv>
                        <SVGIcon
                          name='angledown-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className={`angledown-icon ${isCustomerDropdownOpen ? 'rotate' : ''}`}
                        />
                      </IconDiv>
                    </InputDiv>
                  }
                  trigger='click'>
                  <ItemDiv>{renderCustomerDropdown}</ItemDiv>
                </Dropdown>
              </Form>
            </Formview>
          )}

          {isEmpty(newProjectItem?.id) && projectTemplateList?.length > 0 && (
            <Formview>
              <Form>
                <Label>Template</Label>
                <Dropdown
                  isMinWidth={598}
                  content={
                    <InputDiv onClick={handleToggleTemplateDropdown}>
                      <SelectInput placeholder='Select Template' value={template?.value} />
                      <IconDiv>
                        <SVGIcon
                          name='angledown-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className={`angledown-icon ${isTemplateDropdownOpen ? 'rotate' : ''}`}
                        />
                      </IconDiv>
                    </InputDiv>
                  }
                  trigger='click'>
                  <ItemDiv>
                    {projectTemplateList.map((templateData) => {
                      const isSelected = templateData.id === template?.id;
                      return (
                        <DropdownItem
                          key={templateData.id}
                          label={templateData.value}
                          onClick={() => {
                            onUpdateInputValue('template', templateData);
                            setIsTemplateDropdownOpen(false);
                          }}
                          isSuffix={
                            isSelected && (
                              <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' />
                            )
                          }
                        />
                      );
                    })}
                  </ItemDiv>
                </Dropdown>
              </Form>
            </Formview>
          )}
          <Formblock>
            <Form>
              <Label>Start Date</Label>
              <DatePicker
                label='Select start date'
                value={startDate}
                onChange={(date) => {
                  onUpdateInputValue('startDate', date);
                }}
              />
            </Form>
            <Form>
              <Label>Estimated Due Date</Label>
              <DatePicker
                label='Select due date'
                value={!isEmpty(dueDate) ? dueDate : ''}
                onChange={(date) => {
                  onUpdateInputValue('dueDate', date);
                }}
              />
            </Form>
          </Formblock>
        </Formation>
        <Formbutton>
          <Button
            title='Cancel'
            onClick={onClickCancel}
            secondary={true}
            modelbtn={true}
            type='button'
            hasNotBoxshadow
          />
          <Button
            type={'submit'}
            title={isEmpty(newProjectItem?.id) ? 'Create Project' : 'Update Project'}
            isLoading={loading}
            modelbtn={true}
          />
        </Formbutton>
      </Modalblock>
    </>
  );
};

export default CreateNewProjectModal;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Group,
  Container,
  Details,
  Text,
  Formblock,
  Form,
  Label,
  Inputvalue,
  FormMain,
  PersonalHeader,
  Personaltext,
  Userprofile,
  Icon,
  Uploadtext,
  Title2,
  Header,
  Title1
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaceDetails, onUpdateWorkspaceDetails, sendDeleteRequest } from '../../services/workspaceService';
import Button from '../../component/button';
import { setErrorMessage } from '../../actions/messageActions';
import { getFirebaseUrlFromFile, isEmpty, isPhoneNumberValid } from '../../helpers/common';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { captureException } from '../../services/logService';
import emptylogo from '../../assets/images/emptystates/emptylogo.svg';
import darkemptylogo from '../../assets/images/emptystates/darkemptylogo.svg';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';
import ConfirmationModal from '../../component/models/confirmationModal';
import ModalCustom from '../../component/models/modal';
import { VerificationModal } from '../../component/models/verificationmodal';
import { WORKSPACE_ANALYTICS } from '../../global/analyticsConstants';
interface Props {
  secondary?: boolean;
}

interface workspaceInputInterface {
  name: string;
  email: string;
  phone: string;
}
const General: React.FC<Props> = () => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace: workspaceDetail } = workspaceSelector;
  const { id: workspace_id } = workspaceDetail;
  const { themeMode } = settingsSelector;
  // States
  const [image, setImage]: any = useState();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [workspaceInput, setWorkspaceInput] = useState<workspaceInputInterface>({
    name: workspaceDetail?.name || '',
    email: workspaceDetail?.email || '',
    phone: workspaceDetail?.phone || ''
  });
  // Other variables
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    await dispatch(getWorkspaceDetails(workspace_id));
  }, [workspace_id, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const item = {
          profile: fileReader.result,
          file: file
        };
        setImage(item);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files[0];
      let fileData = file?.name?.split('.');
      if (file?.size > 500000) {
        dispatch(setErrorMessage('Please upload less than 500kb photo size.'));
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return false;
      }
      if (fileData[1] === 'jpg' || fileData[1] === 'jpeg' || fileData[1] === 'png') {
        await convertBase64(file);
      } else {
        dispatch(setErrorMessage('Please upload a valid type photo.'));
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return false;
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (!workspaceDetail?.isAdmin) {
        dispatch(setErrorMessage('Only admin can update details!'));
        return false;
      }

      if (workspaceInput.phone && !isPhoneNumberValid(workspaceInput.phone)) {
        dispatch(setErrorMessage('Please enter a valid phone number.'));
        return false;
      }
      try {
        setLoading(true);
        let profile: string | undefined;
        if (image) {
          profile = await getFirebaseUrlFromFile(image, 'OrganizationProfiles/', dispatch);
        }

        const getLogoUrl = () => {
          if (!isEmpty(image?.profile)) return profile;
          if (!isEmpty(workspaceDetail?.logoUrl)) return workspaceDetail?.logoUrl;
          return null;
        };

        const payload = {
          name: workspaceInput.name,
          email: workspaceInput.email,
          LogoUrl: getLogoUrl(),
          phone: workspaceInput.phone
        };
        const result = await dispatch(onUpdateWorkspaceDetails(workspace_id, payload));
        if (result) {
          trackAnalyticActivity(WORKSPACE_ANALYTICS.UPDATED);
          loadData();
        }
      } catch (error) {
        captureException(error);
        setLoading(false);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [
      workspaceDetail?.isAdmin,
      workspaceDetail?.logoUrl,
      workspaceInput.phone,
      workspaceInput.name,
      workspaceInput.email,
      dispatch,
      image,
      workspace_id,
      loadData
    ]
  );

  const handleConfirmationOpen = useCallback(() => {
    setIsConfirmationOpen(true);
  }, []);

  const CloseConfirmationModal = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const handleVerificationModalOpen = useCallback(async () => {
    try {
      setDeleteLoading(true);
      const result = await dispatch(sendDeleteRequest());
      if (result) {
        setIsVerificationOpen(true);
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
      dispatch(setErrorMessage('Failed to send delete request. Please try again.'));
    } finally {
      setDeleteLoading(false);
    }
  }, [dispatch]);

  const CloseVerificationModal = useCallback(() => {
    setIsVerificationOpen(false);
    setIsConfirmationOpen(false);
  }, []);

  return (
    <>
      <AppLayout>
        <Group onSubmit={onSubmit} isMiddlecontainer={true}>
          <Container>
            <PersonalHeader>
              <Header>
                {isMobile && <ResponsiveHeader />}
                <Personaltext>Organisation Profile</Personaltext>
              </Header>
            </PersonalHeader>
            <FormMain>
              <Details>
                <Userprofile>
                  <input type='file' onChange={handleFileRead} ref={inputRef} style={{ display: 'none' }} />
                  <Icon>
                    {!isEmpty(image?.profile) || !isEmpty(workspaceDetail?.logoUrl) ? (
                      <>
                        <img
                          src={!isEmpty(image?.profile) ? image?.profile : workspaceDetail?.logoUrl}
                          alt='googlelogo'
                          width={60}
                          height={60}
                          className='profile-image'
                        />
                      </>
                    ) : (
                      <>
                        {themeMode?.theme === 'dark' ? (
                          <img
                            src={darkemptylogo}
                            alt='emptyprofile'
                            width={60}
                            height={60}
                            className='profile-image'
                          />
                        ) : (
                          <img src={emptylogo} alt='emptyprofile' width={60} height={60} className='profile-image' />
                        )}
                      </>
                    )}
                  </Icon>
                  <Uploadtext>
                    <Title2 onClick={handleUploadClick}> Upload new image </Title2>
                    <Text> Maximum file size allowed in 500 KB in png, jpeg, jpg format. </Text>
                  </Uploadtext>
                </Userprofile>
                <Formblock>
                  <Form>
                    <Label>Organization Name</Label>
                    <Inputvalue
                      type='text'
                      value={workspaceInput?.name || ''}
                      onChange={(e) => setWorkspaceInput({ ...workspaceInput, name: e.target.value })}
                    />
                  </Form>
                  <Form>
                    <Label>Mobile Number</Label>
                    <Inputvalue
                      type='text'
                      value={workspaceInput?.phone || ''}
                      onChange={(e) => setWorkspaceInput({ ...workspaceInput, phone: e.target.value })}
                    />
                  </Form>
                  <Form>
                    <Label>Email</Label>
                    <Inputvalue
                      type='text'
                      value={workspaceInput?.email || ''}
                      onChange={(e) => setWorkspaceInput({ ...workspaceInput, email: e.target.value })}
                    />
                  </Form>
                </Formblock>
                <Button title='Update' type='submit' isLoading={loading} disabled={loading} modelbtn />
              </Details>
              <Details isDelete>
                <Formblock>
                  <Title1>Delete workspace</Title1>
                  <Text className='text'>
                    As an administrator, you have the ability to delete this workspace. This action can’t be undone.
                  </Text>
                </Formblock>
                <Button
                  secondary
                  title='Delete workspace'
                  hasNotBoxshadow
                  onClick={(e: React.SyntheticEvent) => {
                    handleConfirmationOpen();
                    e.preventDefault();
                  }}
                  modelbtn
                />
              </Details>
            </FormMain>
          </Container>
        </Group>

        <ModalCustom open={isConfirmationOpen} onClose={CloseConfirmationModal} width={346}>
          <ConfirmationModal
            isDelete
            loading={deleteLoading}
            modaltitle='Delete workspace?'
            description='Are you sure you want to delete this workspace?'
            onClose={CloseConfirmationModal}
            onOk={() => handleVerificationModalOpen()}
          />
        </ModalCustom>
        <ModalCustom open={isVerificationOpen} onClose={CloseVerificationModal} width={462}>
          <VerificationModal onClose={CloseVerificationModal} />
        </ModalCustom>
      </AppLayout>
    </>
  );
};

export default General;

import React, { useCallback, useEffect, useState } from 'react';
import Accounting from '../../../../component/accounting';
import AppLayout from '../../../../component/appLayout';
import { Group, Title } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewLabel,
  deletelabel,
  getCurrentProjectDetails,
  updateProjectData,
  updateProjectListDataFromServer
} from '../../../../services/projectServices';
import { clearProjectStatus, updateCurrentProject } from '../../../../actions/projectActions';
import { useParams } from 'react-router-dom';
import { getTaskGroupList } from '../../../../services/taskServices';
import { isEmpty } from '../../../../helpers/common';
import { USER_ROLE } from '../../../../global/constants';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import { getWorkspaceDetails } from '../../../../services/workspaceService';
import { getCustomerMembersList } from '../../../../services/customerServices';
import { MemberInputInterface } from '../../../../interfaces/CustomerInterface';
import { captureException } from '../../../../services/logService';
import SettingCards from '../../../../component/projectSetting';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { PROJECT_ANALYTICS } from '../../../../global/analyticsConstants';
import { rootStore } from '../../../../mobx/rootStore';
import { UserModel } from '../../../../mobx/models/user';

const DetailsSetting: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState<UserModel[]>([]);
  const [customersUsers, setCustomersUsers] = useState<UserModel[]>([]);
  const [loadingButton, setLoadingButton] = useState({
    group: false,
    status: false,
    priority: false,
    label: false,
    multiAssignee: false,
    estimation: false,
    milestone: false
  });

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, task: tasksSelector } = stateSelector || {};
  const { taskGroups } = tasksSelector;
  const { themeMode } = settingsSelector;

  //Mobx store variables
  const { workspaceStore, projectStore } = rootStore;
  const { currentWorkspace } = workspaceStore;
  const { currentProject } = projectStore;
  const workspace_id = currentWorkspace?.id;

  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  const setSpecificLoading = (key: string, value: boolean) => {
    setLoadingButton((prevState) => ({
      ...(prevState || {}),
      [key]: value
    }));
  };

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        let result: any = await Promise.all([
          dispatch(getCurrentProjectDetails(workspace_id, params?.id)),
          dispatch(getWorkspaceDetails(workspace_id)),
          dispatch(getTaskGroupList(params?.id))
        ]);
        if (!isEmpty(result[0]?.customerId)) {
          const customerList = await dispatch(getCustomerMembersList(result[0]?.customerId));
          result.push(customerList);
        }
        const customerMembers: MemberInputInterface[] = result?.[3] || [];
        const workspace = result?.[1] || {};

        const filteredWorkspaceUsers: UserModel[] =
          workspace?.users?.filter((item: UserModel) => item?.role !== USER_ROLE.CLIENT) || [];
        const filteredCustomers: UserModel[] =
          customerMembers?.length > 0 && workspace?.users?.length > 0
            ? workspace?.users?.filter(
                (item: UserModel) =>
                  item?.role === USER_ROLE.CLIENT && customerMembers?.some((a: any) => a?.id === item?.id)
              )
            : [];
        setCustomersUsers(filteredCustomers);
        setWorkspaceUsers(filteredWorkspaceUsers);
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, params?.id, workspace_id]);

  useEffect(() => {
    trackAnalyticActivity(PROJECT_ANALYTICS.VIEW_SETTING);
    loadData();
    return () => {
      dispatch(clearProjectStatus());
      dispatch(updateProjectListDataFromServer());
    };
  }, [loadData, dispatch]);

  // for update priority
  const onUpdatePriority = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('priority', true);
        const payload = {
          Priority: {
            Enabled: value,
            Default: Number(currentProject?.priority?.default) || 0
          }
        };
        const response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.PRIORITY_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.PRIORITY_DISABLED);
          currentProject?.setPriorityEnabled(value);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('priority', false);
      }
    },
    [dispatch, currentProject]
  );

  // for update labels
  const onUpdateLabels = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('label', true);
        const payload = {
          LabelsEnabled: value
        };
        if ((!currentProject?.labelsList || currentProject?.labelsList?.length === 0) && value) {
          const payloadUI = {
            name: 'UI',
            color: 'Purple'
          };
          const payloadBUG = {
            name: 'BUG',
            color: 'Red'
          };
          await Promise.all([
            (dispatch(createNewLabel(payloadUI, currentProject?.id)),
            dispatch(createNewLabel(payloadBUG, currentProject?.id)))
          ]);
        }
        const response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.LABEL_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.LABEL_DISABLED);
          currentProject?.setLabelsEnabled(value);
          await dispatch(getCurrentProjectDetails(workspace_id, params?.id));
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('label', false);
      }
    },
    [currentProject, dispatch, workspace_id, params?.id]
  );

  // for set default priority
  const onChangeDefault = useCallback(
    async (key: any) => {
      dispatch(
        updateCurrentProject({
          propsName: 'priority',
          value: { ...(currentProject?.priority || {}), default: Number(key) }
        })
      );
      const payload = {
        Priority: {
          Enabled: currentProject?.priority?.enabled,
          Default: Number(key)
        }
      };
      await dispatch(updateProjectData(currentProject?.id, payload));
    },
    [dispatch, currentProject]
  );

  // for delete label
  const deleteLabel = useCallback(
    async (labelId: string) => {
      try {
        setLoading(true);
        const result = await dispatch(deletelabel(labelId, currentProject?.id));
        if (result) {
          await dispatch(getCurrentProjectDetails(workspace_id, currentProject?.id));
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [currentProject?.id, dispatch, workspace_id]
  );

  // for update estimate functionality
  const onUpdateEstimate = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('estimation', true);
        const payload = {
          IsEstimate: value
        };
        const response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.ESTIMATION_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.ESTIMATION_DISABLED);
          currentProject?.setEstimate(value);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('estimation', false);
      }
    },
    [currentProject, dispatch]
  );

  // for update mile stone
  const onUpdateMileStone = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('milestone', true);
        let response;
        const payload = {
          IsMilestone: value
        };
        response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.MILESTONE_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.MILESTONE_DISABLED);
          currentProject?.setMilestone(value);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('milestone', false);
      }
    },
    [currentProject, dispatch]
  );

  // for manage multi assignee
  const onUpdateMultiAssignee = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('multiAssignee', true);
        let response;
        const payload = {
          MultiAsignee: value
        };
        response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.MULTI_ASSIGNEE_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.MULTI_ASSIGNEE_DISABLED);
          currentProject?.setMultiAssignee(value);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('multiAssignee', false);
      }
    },
    [currentProject, dispatch]
  );

  // for update status
  const onUpdateStatus = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('status', true);
        let response;
        const payload = {
          StatusEnable: value
        };
        response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.STATUS_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.STATUS_DISABLED);
          currentProject?.setStatusEnabled(value);
          if (currentProject?.statusData?.length === 0) loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('status', false);
      }
    },
    [dispatch, currentProject, loadData]
  );

  // for update group
  const onUpdateGroup = useCallback(
    async (value: boolean) => {
      try {
        setSpecificLoading('group', true);
        let response;
        const payload = {
          IsGroupEnabled: value
        };
        response = await dispatch(updateProjectData(currentProject?.id, payload));
        if (response) {
          if (value) trackAnalyticActivity(PROJECT_ANALYTICS.GROUP_ENABLED);
          else trackAnalyticActivity(PROJECT_ANALYTICS.GROUP_DISABLED);
          currentProject?.setGroupEnabled(value);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setSpecificLoading('group', false);
      }
    },
    [currentProject, dispatch]
  );

  return (
    <>
      <AppLayout>
        <Accounting />
        <Group>
          <Title>Project Settings</Title>
          <SettingCards
            currentProject={currentProject!}
            themeMode={themeMode}
            onUpdateMileStone={(value) => onUpdateMileStone(value)}
            onUpdateEstimate={(value) => onUpdateEstimate(value)}
            onUpdateMultiAssignee={(value) => onUpdateMultiAssignee(value)}
            onUpdateStatus={(value) => onUpdateStatus(value)}
            onChangeDefault={(key) => onChangeDefault(key)}
            onUpdatePriority={(value) => onUpdatePriority(value)}
            onUpdateLabels={(value) => onUpdateLabels(value)}
            onUpdateGroup={(value) => onUpdateGroup(value)}
            taskGroups={taskGroups}
            loading={loading}
            workspaceUsers={workspaceUsers}
            customersUsers={customersUsers}
            paramsId={params?.id}
            deleteLabel={(id) => deleteLabel(id)}
            loadData={loadData}
            setLoading={(value: boolean) => setLoading(value)}
            workspaceDetails={currentWorkspace!}
            loadingButton={loadingButton}
          />
        </Group>
      </AppLayout>
    </>
  );
};

export default DetailsSetting;

import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { DrawerBody, DrawerContent, DrawerOverlay } from './style';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  width?: string;
  children: React.ReactNode;
  headerStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}

const Drawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  placement = 'right',
  width = '350px',
  children,
  headerStyle,
  bodyStyle
}) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && open) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'auto';
    };
  }, [open, onClose]);

  if (typeof document === 'undefined') return null;

  return createPortal(
    <>
      <DrawerOverlay open={open} onClick={onClose} />
      <DrawerContent open={open} placement={placement} width={width}>
        <DrawerBody style={bodyStyle}>{children}</DrawerBody>
      </DrawerContent>
    </>,
    document.body
  );
};

export default Drawer;

import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import { createReducer as createReducerOrig, PayloadAction, current } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { WorkspaceInterfase } from '../interfaces/WorkspaceInterface';

const setWorkspaceLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_WORKSPACE_LOADER
});

const setWorkspaceListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_WORKSPACE_LIST
});

const workspaceStorageReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_WORKSPACE_USAGE
});

const workspaceTotalTaskReducer = createReducer({
  initialState: 0,
  actionType: Actions.SET_WORKSPACE_TOTAL_TASKS
});

const initialStateCurrentWorkspace = {} as WorkspaceInterfase;
const setCurrentWorkspaceReducer = createReducerOrig(initialStateCurrentWorkspace, (builder) => {
  builder.addCase(
    Actions.SET_CURRENT_WORKSPACE,
    (state = initialStateCurrentWorkspace, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    }
  );
});

const initialState = {
  id: '',
  industry: '',
  invitedUsers: [],
  isAdmin: false,
  name: '',
  teamType: undefined,
  users: [],
  phone: '',
  email: ''
};
const workspaceDetailReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_WORKSPACE_DETAIL, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || initialState) };
    })
    .addCase(Actions.UPDATE_WORKSPACE_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      workspace[action.payload.propsName] = action.payload.value;
      return workspace;
    })
    .addCase(Actions.CLEAR_WORKSPACE_DETAIL_ITEM, () => {
      const initialCreateState = JSON.parse(JSON.stringify(initialState));
      return initialCreateState;
    });
});

const inputInitialState = {
  industry: '',
  name: '',
  teamType: undefined,
  users: [{}]
};
const workspaceInputDetailReducer = createReducerOrig(inputInitialState, (builder) => {
  builder
    .addCase(Actions.UPDATE_WORKSPACE_INPUT_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      workspace[action.payload.propsName] = action.payload.value;
      return workspace;
    })
    .addCase(Actions.ADD_USER_ITEM, (state, action: PayloadAction<any, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      const users = workspace.users?.slice();
      users.push(action.payload.item);
      workspace.users = users;
      return workspace;
    })
    .addCase(Actions.UPDATE_USER_ITEM, (state, action: PayloadAction<{ item: any; index: number }, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      const users = workspace.users?.slice();
      users[action.payload.index] = action.payload.item;
      workspace.users = users;
      return workspace;
    })
    .addCase(Actions.DELETE_USER_ITEM, (state, action: PayloadAction<{ index: number }, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      const users = workspace.users?.slice();
      if (action.payload.index !== -1) {
        users.splice(action.payload.index, 1);
      }
      workspace.users = users;
      return workspace;
    })
    .addCase(Actions.CLEAR_WORKSPACE_INPUT_DETAIL, () => {
      const initialCreateState = JSON.parse(JSON.stringify(inputInitialState));
      return initialCreateState;
    });
});

const workspaceReducer = combineReducers({
  loading: setWorkspaceLoaderReducer,
  workspaceDetail: workspaceDetailReducer,
  list: setWorkspaceListReducer,
  workspace: setCurrentWorkspaceReducer,
  workspaceInputDetails: workspaceInputDetailReducer,
  workspaceStorage: workspaceStorageReducer,
  workspaceTotalTask: workspaceTotalTaskReducer
});

export default workspaceReducer;

/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  isShowHover?: boolean;
  isActive?: boolean;
  isCommentModelContent?: boolean;
  isHover?: boolean;
  isGap?: boolean;
  ieTextdot?: boolean;
  iswidth?: boolean;
  color?: string;
  isOverdue?: boolean;
  isApplyBottomSpacing?: boolean;
  hasSubtasks?: boolean;
  isMessage?: boolean;
  isCreateTaskModal?: boolean;
  isProjectMessageDetail?: boolean;
  isProjectOverview?: boolean;
}
const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const FolderRight = styled.div<Props>`
  padding: 16px 0 0 24px;
  max-width: 306px;
  width: 100%;
  background: var(--background-secondary);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100dvh - 16px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isMessage &&
    css`
      padding: 0;
      max-width: 302px;
    `}
  @media (max-width: 449px) {
    max-width: unset;
    width: 100vw;
    padding: 0px;
    height: calc(100dvh + 1px);
    gap: 0px;
    margin: 0 -16px;
    ${(props: Props) =>
      props.isMessage &&
      css`
        margin: 0;
      `}
  }
  ${(props) =>
    props.isCreateTaskModal &&
    css`
      max-width: 302px;
      padding: 16px 24px 0 24px;
      border-left: 0.5px solid var(--border-primary);
    `}
  ${(props) =>
    props.isProjectOverview &&
    css`
      max-width: 302px;
      padding: 16px 0 0 24px;
      border-left: 0.5px solid var(--border-primary);
      height: auto;
      margin-left: 24px;
      @media (max-width: 449px) {
        margin: 0;
        border-left: none;
      }
    `}
`;
const RightHeader = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 20px;
  .fillColor {
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
    @media (max-width: 449px) {
      display: none;
    }
  }
  .back-arrow {
    display: none;
  }
  ${(props: Props) =>
    props.isProjectMessageDetail &&
    css`
      padding-top: 16px;
    `}
  ${(props: Props) =>
    props.isMessage &&
    css`
      padding-top: 24px;
    `}
  @media (max-width: 449px) {
    flex-direction: row-reverse;
    justify-content: start;
    padding: 14px 0;
    width: unset;
    background-color: var(--background-primary);
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 0.5px solid var(--border-primary);
    gap: 12px;
    .back-arrow {
      display: flex;
      path {
        stroke: var(--text-secondary);
      }
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 4px;
      }
    }
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        padding-top: 0px;
      `}
  }
`;
const Headertext = styled.p<Props>`
  ${Typography.heading_lg}
  margin: 0px;
  color: var(--text-primary);
  ${(props) =>
    props.isCreateTaskModal &&
    css`
      ${Typography.heading_md}
    `}
  ${(props) =>
    props.isProjectOverview &&
    css`
      ${Typography.heading_md};
    `}
`;
const CloseIcon = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  @media (max-width: 449px) {
    margin-left: 16px;
    .fillColor {
      display: none;
    }
    .back-arrow {
      width: 24px;
      height: 24px;
      padding-top: 1px;
    }
  }
`;
const Folderdiv = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  margin-bottom: 16px;
  ${(props) =>
    props.isMessage &&
    css`
      margin-bottom: 24px;
    `}
  @media (max-width: 449px) {
    padding: 12px 16px;
    background: var(--background-primary);
    gap: 12px;
    margin-bottom: 0;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 11px;
  gap: 12px;
  border-radius: 8px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  cursor: pointer;
  :hover {
    border: 0.5px solid var(--text-secondary);
    box-shadow: var(--shadow-card-hover);
  }
  @media (max-width: 449px) {
    border-radius: 12px;
  }
`;
const Leftcard = styled.div`
  width: 36px;
  height: 36px;
`;
const Rightcard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  padding-left: 12px;
  border-left: 0.5px solid var(--border-primary);
`;
const Cardtitle = styled.h2`
  ${Typography.body_md_medium}
  margin: 0;
  color: var(--text-primary);
  word-break: break-word;
`;
const Carddecs = styled.p`
  ${Typography.body_sm}
  color: var(--text-secondary);
  margin: 0px;
  word-break: break-word;
`;
const BreadSlash = styled.div`
  padding: 4px 0px;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0 2px;
`;

const BredCrumbBlock = styled.div<Props>`
  padding: 4px 8px;
  ${Typography.body_md}
  color: var(--text-primary);
  cursor: pointer;

  :hover {
    color: var(--text-secondary);
  }

  ${(props: Props) =>
    props.isActive &&
    css`
      color: var(--brand-primary);
    `}
`;

const BreadCrumb = styled.div`
  display: flex;
`;

const BreadCrumbContent = styled.div`
  display: flex;
`;

const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;

const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
`;
export {
  Icon,
  RightHeader,
  Headertext,
  Folderdiv,
  FolderRight,
  Card,
  Rightcard,
  Cardtitle,
  Carddecs,
  BreadSlash,
  BredCrumbBlock,
  BreadCrumb,
  BreadCrumbContent,
  Leftcard,
  FileIconDiv,
  CloseIcon,
  EmptyBox
};

/* eslint-disable no-template-curly-in-string */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentProject } from '../../../actions/projectActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { rootStore } from '../../../mobx/rootStore';
import { ProjectName } from './styles';
import CircularProgress from '../../CircularProgress';

interface ProjectNameInterface {
  item: any;
  onCloseNavbar?: () => void;
}
const ProjectItem: React.FC<ProjectNameInterface> = (props) => {
  const { item, onCloseNavbar } = props;
  //Reducer variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector } = stateSelector;
  const { favouriteProjects } = projectSelector;
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isProject = location?.pathname?.startsWith(`/projects/details/${item?.id}`);

  const onClickProject = useCallback(() => {
    const projectData = favouriteProjects.find((project) => project?.id === item?.id);
    if (projectData) dispatch(setCurrentProject(projectData));
    rootStore.projectStore.setCurrentProject(item?.id);
    history.push(`/projects/details/${item?.id}/tasks`);
    onCloseNavbar?.();
  }, [dispatch, favouriteProjects, history, item?.id, onCloseNavbar]);

  return (
    <>
      <ProjectName
        onClick={onClickProject}
        isActive={isProject}
        className={isProject ? 'active-project-name' : 'project-name'}>
        <CircularProgress progressPercentage={Number(item?.progressData.progress)} status={item?.progressData.status} />
        <p>{item?.name}</p>
      </ProjectName>
    </>
  );
};

export { ProjectItem };

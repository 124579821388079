import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Addtask,
  Autotext,
  Features,
  Formcontent,
  Group,
  Icon,
  InputNameTitle,
  InputAddressTitle,
  InputSection,
  Inputfield,
  Inputside,
  Inputvalue1,
  Invoicedue,
  NameInput,
  PersonalHeader,
  Personaltext,
  Pesonalbtns,
  Projectcontent,
  Subcontent,
  Tax,
  Text,
  Text1,
  Title2,
  Title4,
  Uploadtext,
  Userprofile,
  Plusicon,
  Header
} from './styles';
import Button from '../../component/button';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoicingDetail, updateInvoicingDetail } from '../../actions/settingActions';
import { invoiceDueOptions } from '../../global/constants';
import { getFirebaseUrlFromFile, isEmpty, isFloatOrNumber } from '../../helpers/common';
import { setErrorMessage } from '../../actions/messageActions';
import { getInvoicingSettingsData, updateInvoicingSettings } from '../../services/settingServices';
import { captureException } from '../../services/logService';
import emptylogo from '../../assets/images/emptystates/emptylogo.svg';
import darkemptylogo from '../../assets/images/emptystates/darkemptylogo.svg';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';
import RadioButton from '../../component/radioButton';

const Invoice: React.FC = () => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  // States
  const [image, setImage]: any = useState();
  const [loading, setLoading] = useState(false);
  // use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { invoicingDetail, themeMode } = settingSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        const result = await dispatch(getInvoicingSettingsData(workspace_id));
        dispatch(
          setInvoicingDetail({
            ...result,
            ...((isEmpty(result?.tax) || !result?.tax || result?.tax?.length === 0) && {
              tax: [{ name: '', value: null }]
            })
          })
        );
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onAddItems = useCallback(async () => {
    let itemClone = JSON.parse(JSON.stringify(invoicingDetail?.tax));
    let Clone = {
      name: '',
      value: null
    };
    const newItems = [...itemClone, Clone];
    dispatch(updateInvoicingDetail({ propsName: 'tax', value: newItems }));
  }, [dispatch, invoicingDetail?.tax]);

  const onDeleteItem = useCallback(
    (index: number) => {
      let items = JSON.parse(JSON.stringify(invoicingDetail?.tax));
      if (items?.length <= 1) {
        items = [
          {
            name: '',
            value: 0
          }
        ];
      } else {
        items.splice(index, 1);
      }
      dispatch(updateInvoicingDetail({ propsName: 'tax', value: items }));
    },
    [invoicingDetail?.tax, dispatch]
  );

  const onChangeItemsInputField = useCallback(
    (index: number, propsName: string, value: string) => {
      if (propsName === 'value' && !isFloatOrNumber(value)) {
        dispatch(setErrorMessage('Please enter only digits in tax amount!'));
        return;
      }
      let items = JSON.parse(JSON.stringify(invoicingDetail?.tax));
      items[index][propsName] = value;
      dispatch(updateInvoicingDetail({ propsName: 'tax', value: items }));
    },
    [invoicingDetail?.tax, dispatch]
  );

  const onChangeInputField = useCallback(
    (propsName: string, value: string | number) => {
      // Convert value to number if it's a string and we're dealing with dueDays
      const processedValue = propsName === 'dueDays' ? Number(value) : value;
      dispatch(updateInvoicingDetail({ propsName, value: processedValue }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        let profile;
        if (image) {
          profile = await getFirebaseUrlFromFile(image, 'InvoiceLogo/', dispatch);
        }
        if (Array.isArray(invoicingDetail?.tax)) {
          for (const item of invoicingDetail.tax) {
            if (!isEmpty(item?.name) && isEmpty(item?.value)) {
              dispatch(setErrorMessage('Tax rate should not be empty!'));
              return;
            } else if (isEmpty(item?.name) && !isEmpty(item?.value)) {
              dispatch(setErrorMessage('Tax name should not be empty!'));
              return;
            }
          }
        }
        const taxRates = invoicingDetail?.tax
          ?.map((item) => ({
            ...item,
            value: Number(item?.value)
          }))
          ?.filter((item) => !isEmpty(item?.value) && !isEmpty(item.name));
        const payload = {
          name: !isEmpty(invoicingDetail?.name) ? invoicingDetail?.name : undefined,
          address: !isEmpty(invoicingDetail?.address) ? invoicingDetail?.address : undefined,
          tax: !isEmpty(taxRates) ? taxRates : undefined,
          note: !isEmpty(invoicingDetail?.note) ? invoicingDetail.note : undefined,
          dueDays: !isEmpty(invoicingDetail?.dueDays) ? invoicingDetail.dueDays : undefined,
          invoiceLogo: !isEmpty(profile)
            ? profile
            : !isEmpty(invoicingDetail?.invoiceLogo)
            ? invoicingDetail?.invoiceLogo
            : undefined
        };
        const result = await dispatch(updateInvoicingSettings(workspace_id, payload));
        if (result) {
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [
      dispatch,
      image,
      invoicingDetail?.address,
      invoicingDetail.dueDays,
      invoicingDetail?.invoiceLogo,
      invoicingDetail?.name,
      invoicingDetail.note,
      invoicingDetail?.tax,
      loadData,
      workspace_id
    ]
  );

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const item = {
          profile: fileReader.result,
          file: file
        };
        setImage(item);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files[0];
      let fileData = file?.name?.split('.');
      if (file?.size > 500000) {
        dispatch(setErrorMessage('Please upload less than 500kb photo size.'));
        return false;
      }
      const extension = fileData[fileData?.length - 1];
      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
        await convertBase64(file);
      } else {
        dispatch(setErrorMessage('Please upload a valid type photo.'));
        return false;
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    }
  };

  return (
    <>
      <AppLayout>
        <Group onSubmit={onSubmit}>
          <PersonalHeader>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Invoicing</Personaltext>
            </Header>
            <Pesonalbtns>
              <Button title='Save' type='submit' isLoading={loading} modelbtn />
            </Pesonalbtns>
          </PersonalHeader>
          <Projectcontent>
            <Subcontent>
              <Userprofile>
                <input type='file' onChange={handleFileRead} ref={inputRef} style={{ display: 'none' }} />
                {!isEmpty(image?.profile) || !isEmpty(invoicingDetail?.invoiceLogo) ? (
                  <Icon>
                    <img
                      src={!isEmpty(image?.profile) ? image?.profile : invoicingDetail?.invoiceLogo}
                      alt='invoice-logo'
                      width={40}
                      height={40}
                      className='invoice-logo-image'
                    />
                  </Icon>
                ) : (
                  <Icon>
                    {themeMode?.theme === 'dark' ? (
                      <img
                        src={darkemptylogo}
                        alt='emptyprofile'
                        width={40}
                        height={40}
                        className='invoice-logo-image'
                      />
                    ) : (
                      <img src={emptylogo} alt='emptyprofile' width={40} height={40} className='invoice-logo-image' />
                    )}
                  </Icon>
                )}
                <Uploadtext>
                  <Title2 onClick={handleUploadClick}> Upload new invoice logo </Title2>
                  <Text> Maximum file size allowed in 500 KB in png, jpeg, jpg format. </Text>
                </Uploadtext>
              </Userprofile>
              <Autotext>
                <Title4>Company information</Title4>
                <Text>
                  Information about your company, which automatically shows up on all future and existing invoices.
                </Text>
                <InputSection>
                  <InputNameTitle>Name</InputNameTitle>
                  <NameInput
                    type='text'
                    placeholder='Enter name'
                    value={invoicingDetail?.name}
                    onChange={(e) => onChangeInputField('name', e.target.value)}
                  />
                  <InputAddressTitle>Address</InputAddressTitle>
                  <Inputfield
                    placeholder='Enter Address'
                    value={invoicingDetail?.address}
                    onChange={(e) => onChangeInputField('address', e.target.value)}
                  />
                </InputSection>
              </Autotext>
              <Tax>
                <Autotext>
                  <Title4> Taxes Rates </Title4>
                  <Text> Set the tax rates which will be available when creating invoices.</Text>
                  <Formcontent>
                    {invoicingDetail?.tax?.map((item, index: number) => {
                      return (
                        <Inputside key={item?.name}>
                          <Inputvalue1
                            type='text'
                            placeholder='Name'
                            className='first'
                            value={item?.name || ''}
                            onChange={(e) => onChangeItemsInputField(index, 'name', e.target.value)}
                          />
                          <Inputvalue1
                            type='text'
                            placeholder='Tax rate'
                            className='second'
                            value={item?.value != null ? item?.value : ''}
                            onChange={(e) => onChangeItemsInputField(index, 'value', e.target.value)}
                          />
                          <Icon isdeleticonhover={true} onClick={() => onDeleteItem(index)}>
                            <SVGIcon
                              name='delete-setting-icon'
                              width='18'
                              height='18'
                              viewBox='0 0 18 18'
                              className='delete-icon'
                            />
                          </Icon>
                        </Inputside>
                      );
                    })}
                    {invoicingDetail?.tax?.length < 2 && (
                      <Addtask onClick={onAddItems}>
                        <Plusicon>
                          <SVGIcon name='plus-icon' viewBox='0 0 18 18' width='18' height='18' className='plus-icon' />
                        </Plusicon>
                        <Text1>Add Tax Rate</Text1>
                      </Addtask>
                    )}
                  </Formcontent>
                </Autotext>
              </Tax>
              <Autotext>
                <Title4> Note Templates </Title4>
                <Text>Add frequently used notes (eg. payment instructions).</Text>
                <Formcontent>
                  <Inputfield
                    placeholder='Enter note template'
                    value={invoicingDetail?.note}
                    onChange={(e) => onChangeInputField('note', e.target.value)}
                  />
                </Formcontent>
              </Autotext>
              <Features>
                <Title4> Issued Invoice Due </Title4>
                <Invoicedue>
                  {invoiceDueOptions.map((option) => (
                    <RadioButton
                      id={option.id}
                      name='due_days'
                      value={option.value}
                      checked={invoicingDetail?.dueDays === option.value}
                      label={option.label}
                      onChange={(value) => onChangeInputField('dueDays', value)}
                    />
                  ))}
                </Invoicedue>
              </Features>
            </Subcontent>
          </Projectcontent>
        </Group>
      </AppLayout>
    </>
  );
};

export default Invoice;

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from '../../../helpers/common';
import { LinkModalInterface, ProjectFileData } from '../../../interfaces/ProjectInterface';
import { FILE_TYPES } from '../../../global/constants';
import { captureException } from '../../../services/logService';
import {
  addFileData,
  createNewDoc,
  updateDocumentData,
  updateFileData,
  updateProjectResource
} from '../../../services/projectServices';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import {
  DropboxModal,
  Icon,
  ModalBlock,
  ModalButton,
  ModalContent,
  ModalForm,
  ModalHeader,
  ModalInput,
  ModalTitle
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { rootStore } from '../../../mobx/rootStore';

interface FileData {
  onClose: () => void;
  loadFilesData?: () => void;
  modalData?: LinkModalInterface;
  itemData?: ProjectFileData | null;
  folderId?: string | null;
  updateDocName?: (name: string) => void;
  isProjectResource?: boolean;
}

export const FilesModal: React.FC<FileData> = (props) => {
  const { onClose, loadFilesData, itemData, modalData, folderId, updateDocName, isProjectResource = false } = props;
  //States
  const [loading, setLoading] = useState(false);

  //Mobx store variable
  const { projectStore } = rootStore;
  const { currentProject } = projectStore;

  //Other variable
  const fileNameWithoutExtension =
    itemData?.name && itemData?.name?.split('.')?.length > 1
      ? itemData?.name.split('.')?.slice(0, -1).join('.')
      : itemData?.name || '';
  const [docName, setDocName] = useState(fileNameWithoutExtension);
  const history = useHistory();
  const dispatch = useDispatch();

  const createNewFile = useCallback(async () => {
    let payload: {
      Name: string;
      ProjectId: string;
      UploadFileType: number | undefined;
      ParentId: string | null;
      IsBlocksuite: boolean;
      FileType?: number | null;
      IsSystemFile?: boolean;
      Size?: number | null;
      Url?: string | null;
      IsEdge?: boolean;
    } = {
      Name: docName,
      ProjectId: currentProject?.id || '',
      UploadFileType: modalData?.modalType,
      ParentId: folderId || null,
      IsBlocksuite: modalData?.modalType !== FILE_TYPES.FOLDER
    };
    if (modalData?.modalType === FILE_TYPES.FOLDER) {
      payload.FileType = null;
      payload.IsSystemFile = true;
      payload.Size = null;
      payload.Url = null;
    } else if (modalData?.modalType === FILE_TYPES.WHITEBOARD) {
      payload.IsEdge = true;
    }
    return modalData?.modalType === FILE_TYPES.FOLDER
      ? await dispatch(addFileData(payload))
      : await dispatch(createNewDoc(payload));
  }, [currentProject?.id, dispatch, docName, folderId, modalData?.modalType]);

  const handleFolderUpdate = useCallback(async () => {
    let newFolderName;
    if (itemData?.uploadFileType === FILE_TYPES.UPLOAD) {
      newFolderName = docName + (itemData?.name ? `.${itemData.name.split('.').pop()}` : '');
    } else {
      newFolderName = docName;
    }
    let response;
    if (isProjectResource) {
      const payload = {
        label: newFolderName
      };
      response = await dispatch(updateProjectResource(currentProject?.id, itemData?.id, payload));
      if (response && itemData?.id) {
        currentProject?.updateResource(itemData?.id, { label: newFolderName });
      }
    } else {
      if (itemData?.uploadFileType === FILE_TYPES.DOCS || itemData?.uploadFileType === FILE_TYPES.WHITEBOARD) {
        const payload = { Name: docName };
        response = await dispatch(updateDocumentData(itemData?.id, payload));
      } else {
        const payload = { Name: newFolderName };
        response = await dispatch(updateFileData(itemData?.id, payload));
      }
    }
    if (response) {
      dispatch(setSuccessMessage('File Updated!'));
    }
    return response;
  }, [currentProject, dispatch, docName, isProjectResource, itemData?.id, itemData?.name, itemData?.uploadFileType]);

  const handleResponseSuccess = useCallback(
    (response: any) => {
      if (!response) {
        throw new Error(`Error creating new ${modalData?.name?.toLowerCase()}`);
      }
      onClose();
      loadFilesData?.();
      updateDocName?.(docName);
      const docId = response?.documentDetails?.['_id'];
      if (!docId) return;

      if (modalData?.modalType !== FILE_TYPES.FOLDER) {
        const path = `/projects/files/${currentProject?.id}/docs/${docId}?${
          folderId ? `folder=${folderId}&` : ''
        }new=true`;
        history.push(path);
      }
      dispatch(setSuccessMessage(`${modalData?.name} Added!`));
    },
    [
      currentProject?.id,
      dispatch,
      docName,
      folderId,
      history,
      loadFilesData,
      modalData?.modalType,
      modalData?.name,
      onClose,
      updateDocName
    ]
  );

  const createItem = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isEmpty(docName)) {
        dispatch(setErrorMessage(`Please enter a ${modalData?.name?.toLowerCase()} name`));
        return;
      }
      try {
        setLoading(true);
        let response;
        if (modalData?.modalType === FILE_TYPES.FOLDER && !isEmpty(itemData)) {
          response = await handleFolderUpdate();
        } else {
          response = await createNewFile();
        }
        handleResponseSuccess(response);
      } catch (err) {
        captureException(err);
        console.log(`Error creating ${modalData?.name?.toLowerCase()}: `, err);
      } finally {
        setLoading(false);
      }
    },
    [
      docName,
      dispatch,
      modalData?.name,
      modalData?.modalType,
      itemData,
      handleResponseSuccess,
      handleFolderUpdate,
      createNewFile
    ]
  );

  return (
    <>
      <DropboxModal>
        <ModalBlock>
          <ModalHeader>
            <ModalTitle>
              {isEmpty(itemData?.id)
                ? modalData?.title
                : itemData?.uploadFileType === FILE_TYPES.FOLDER
                ? 'Rename folder'
                : 'Rename file'}
            </ModalTitle>
          </ModalHeader>
          <Icon onClick={onClose}>
            <SVGIcon name='modal-cross-sign-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
          </Icon>
        </ModalBlock>
        <ModalContent onSubmit={createItem}>
          <ModalForm>
            <ModalInput
              type='text'
              placeholder={modalData?.placeholder}
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
              autoFocus
            />
          </ModalForm>
          <ModalButton>
            <Button
              type={'submit'}
              title={isEmpty(itemData?.id) ? modalData?.title : 'Rename'}
              isLoading={loading}
              modelbtn={true}
            />
          </ModalButton>
        </ModalContent>
      </DropboxModal>
    </>
  );
};

export default FilesModal;

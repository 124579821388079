import { makeAutoObservable } from 'mobx';
import { createObservableNotification, NotificationModel } from '../models/notification';
import { RawNotification } from '../interfaces/notification';
import { INBOX_FILTER_TYPE, NOTIFICATION_TYPE } from '../../global/constants';
import { genericUpsert, genericRemove } from '../common';

export class NotificationStore {
  notifications: NotificationModel[] = [];
  loading: boolean = true;
  filterType: number = INBOX_FILTER_TYPE.ALL;

  constructor() {
    makeAutoObservable(this);
  }

  get filteredNotifications() {
    const currentTime = new Date().getTime();
    const notificationList = this.notifications
      .filter((item) => item.date.getTime() < currentTime)
      .sort((a, b) => b.date.getTime() - a.date.getTime());
    if (this.filterType === INBOX_FILTER_TYPE.ASSIGN_TO_ME) {
      return notificationList.filter((notification) => notification.type === NOTIFICATION_TYPE.TASK);
    } else if (this.filterType === INBOX_FILTER_TYPE.MENTION) {
      return notificationList.filter((notification) => notification.type === NOTIFICATION_TYPE.COMMENT);
    }
    return notificationList;
  }

  setNotifications(rawNotifications: RawNotification[]) {
    this.notifications = rawNotifications.map(createObservableNotification);
    this.loading = false;
  }

  upsertNotifications(rawNotifications: RawNotification[]) {
    const result = genericUpsert(this.notifications, rawNotifications, createObservableNotification);

    return {
      newNotifications: result.newItems,
      updatedNotifications: result.updatedItems
    };
  }

  removeNotifications(notificationIds: string[]) {
    this.notifications = genericRemove(this.notifications, notificationIds);
  }

  setFilterType(filter: number) {
    this.filterType = filter;
  }
}

import { io, Socket } from 'socket.io-client';
import { REALTIME_WS_URL } from '../global/environment';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { rootStore } from '../mobx/rootStore';
const BUFFER_TIME = 1000;

const latestUpdate = new Map<string, any>();
const updateTimer = new Map<string, NodeJS.Timeout>();
let socket: Socket | null = null;

const bufferUpdate = (docId: string, updateFn: () => void) => {
  // Always store the latest update function
  latestUpdate.set(docId, updateFn);

  // If a timer already exists, do not reset it
  if (updateTimer.has(docId)) {
    return;
  }

  // Set a new timer to execute the latest stored update
  const timer = setTimeout(() => {
    const latestFn = latestUpdate.get(docId);
    if (latestFn) {
      latestFn(); // Apply the most recent update
      latestUpdate.delete(docId);
    }
    updateTimer.delete(docId);
  }, BUFFER_TIME);

  updateTimer.set(docId, timer);
};

export const connectToWebsocket =
  (currentWorkspaceId: string, currentUserId: string) => (dispatch: Dispatch<AnyAction>) => {
    if (socket) {
      socket.disconnect();
    }

    socket = io(REALTIME_WS_URL);

    socket.on('connect', () => {
      console.log('Connected to Socket server!');
      socket?.send({ type: 'join_workspace', payload: currentWorkspaceId });
      socket?.send({ type: 'set_user_id', payload: currentUserId });
    });
    socket.on('disconnect', () => {
      console.log('Disconnected to Socket server!');
    });

    socket.onAny((event: any, payload: any) => {
      const { fullDocument } = payload;
      if (!fullDocument || !fullDocument['_id']) return;

      bufferUpdate(fullDocument['_id'], () => handleEvent(event, fullDocument, dispatch));
    });
  };

const handleEvent = (event: string, fullDocument: any, dispatch: Dispatch<AnyAction>) => {
  switch (event) {
    case 'notifications-insert':
    case 'notifications-update':
      const notificationToUpsert = formatRealTimeNotification(fullDocument);
      rootStore.notificationStore.upsertNotifications([notificationToUpsert]);
      break;

    case 'notifications-delete':
      const notificationId = fullDocument['_id'];
      rootStore.notificationStore.removeNotifications([notificationId]);
      break;
  }
};

const formatRealTimeNotification = (fullDocument: any) => {
  return {
    date: fullDocument?.SnoozeDate ?? fullDocument?.CreatedTime,
    description: fullDocument?.Description,
    id: fullDocument?.['_id'],
    isRead: fullDocument?.IsRead,
    referenceId: fullDocument?.ReferenceId,
    referenceId2: fullDocument?.ReferenceId2,
    referenceId3: fullDocument?.ReferenceId3,
    title: fullDocument?.Title,
    type: fullDocument?.Type,
    userId: fullDocument?.UserId,
    createdBy: fullDocument?.Username === 'Github' ? 'github' : fullDocument?.CreatedBy,
    createdTime: fullDocument?.CreatedTime
  };
};

import axios from 'axios';
import { clearToken, saveToken, setupToken } from '../helpers/authTokenHelpers';
import { getAPIErrorReason, isElectronApp, isEmail, isEmpty, isIOS, isIpad, isReactNative } from '../helpers/common';
import {
  setAuthLoader,
  setAuthValidationError,
  setCurrentUser,
  setInviteInput,
  setUserProfileData
} from '../actions/authActions';
import { appInit } from './appService';
import jwt_decode from 'jwt-decode';
import { REACT_APP_APIURL, REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { PasswordDetailInterface, ResetPasswordDetailInterface } from '../interfaces/SettingsInterface';
import { COLOR_THEME, COLOR_THEME_TYPE } from '../global/constants';
import { updateThemeMode } from '../actions/settingActions';
import { clearAnlayticsUser, trackAnalyticActivity } from './analyticsService';
import { AnyAction, Dispatch } from 'redux';
import { captureException } from './logService';
import { CustomWindow } from '../interfaces/OneSignalInterface';
import LocalDbService from './localDbService';
import { updateUserPreference } from '../helpers/firebaseHelper';
import { ForgotPayloadInterface, LoginPayloadInterface, SignUpPayloadInterface } from '../interfaces/AuthInterface';
import { ACCOUNT_ANALYTICS } from '../global/analyticsConstants';
import { nanoid } from 'nanoid';
import { detect } from 'detect-browser';

declare const window: CustomWindow;

//get device info
const getDeviceInfo = () => {
  let storedDeviceId = localStorage.getItem('deviceId');
  if (!storedDeviceId) {
    storedDeviceId = nanoid();
    localStorage.setItem('deviceId', storedDeviceId);
  }
  if (isReactNative()) {
    return {
      deviceId: storedDeviceId,
      deviceType: `${isIpad() ? 'iPad' : 'Mobile'} on ${isIOS() ? 'iOS' : 'Android'}`
    };
  }
  const browser = detect();
  if (isElectronApp()) {
    return {
      deviceId: storedDeviceId,
      deviceType: `Teamcamp Desktop on ${browser?.os}`
    };
  }
  const deviceType =
    browser?.name && browser?.os
      ? `${browser.name.charAt(0).toUpperCase() + browser.name?.slice(1)} on ${browser.os}`
      : '';
  return {
    deviceId: storedDeviceId,
    deviceType
  };
};

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login: any = (obj: LoginPayloadInterface) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (!obj || !obj.username) {
      dispatchAuthError('Username is Required', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    }
    const payload = {
      ...(obj || {}),
      deviceInfo: getDeviceInfo()
    };
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/auth/signin`, payload);
    const { data } = response;
    if (data?.error) {
      dispatchAuthError(data?.error, dispatch);
      return false;
    }
    if (data) {
      dispatch(setLoginDetail(data));
      dispatch(setUserProfileData(data));
      const result = await dispatch(appInit());
      trackAnalyticActivity(ACCOUNT_ANALYTICS.SIGNED_IN);
      const userInfo = {
        id: data?.id,
        email: data?.email,
        name: data?.given_name
      };
      if (window && typeof (window as any)?.Intercom === 'function') {
        (window as any).Intercom('update', userInfo);
      }
      if ((window as any).posthog) {
        (window as any).posthog.identify(
          userInfo.id, // Replace 'distinct_id' with your user's unique identifier
          { email: userInfo.email, name: userInfo.name } // optional: set additional user properties
        );
      }
      dispatchAuthSucess('Login successfull', dispatch);
      LocalDbService.openDatabase();
      return result;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to login please try again';
    captureException(error);
    dispatchAuthError(error, dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc set login token and set user
 */
export const setLoginDetail = (item: any) => async (dispatch: Dispatch<AnyAction>) => {
  const domain = '.teamcamp.app';
  const decoded: any = jwt_decode(item?.access_token);
  const expirationDate = new Date(decoded?.exp * 1000);
  document.cookie = `cross-domain-email=${
    item?.email
  }; expires=${expirationDate.toUTCString()}; path=/; domain=${domain}`;
  document.cookie = `cross-domain-name=${
    item?.given_name
  }; expires=${expirationDate.toUTCString()}; path=/; domain=${domain}`;
  document.cookie = `login=true; expires=${expirationDate.toUTCString()}; path=/; domain=${domain}`;
  document.cookie = `logged-in-user=${item?.id}; expires=${expirationDate.toUTCString()}; path=/; domain=${domain}`;

  saveToken(item.access_token);
  await UserPreferenceSingleton.getInstance().setCurrentUser(item);
  await updateUserPreference({ theme_mode: COLOR_THEME.LIGHT });
  dispatch(updateThemeMode({ propsName: 'theme', value: COLOR_THEME_TYPE.LIGHT }));
  dispatch(setCurrentUser(item));
};

/**
 * @desc Log user out
 */
export const logout: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

    if (window.electronApi?.unsubscribeFcm) {
      window.electronApi.unsubscribeFcm();
    }
    if (isReactNative()) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'unsubscribe-fcm', data: { accessToken: userDetails.access_token } })
      );
    }

    await window.OneSignal.push(function () {
      window.OneSignal.deleteTags(['userid', 'email']);
    });
    window.OneSignal.push(function () {
      window.OneSignal.setSubscription(false);
    });
  } catch (error) {}
  const options = { withCredentials: true };
  const domain = '.teamcamp.app';
  const pastExpirationDate = new Date(0).toUTCString(); // Set expiration time to a past date
  document.cookie = `cross-domain-email=; expires=${pastExpirationDate}; path=/; domain=${domain}`;
  document.cookie = `cross-domain-name=; expires=${pastExpirationDate}; path=/; domain=${domain}`;
  document.cookie = `login=; expires=${pastExpirationDate}; path=/; domain=${domain}`;
  document.cookie = `logged-in-user=; expires=${pastExpirationDate}; path=/; domain=${domain}`;
  axios.delete(`${REACT_APP_APIURL}/membership/logOff`, options);
  trackAnalyticActivity(ACCOUNT_ANALYTICS.SIGNED_OUT);
  clearAnlayticsUser();

  /**
   * Remove token from localStorage
   * Remove auth header for future requests
   * Set current user to {} which will set isAuthenticated to false
   */
  clearToken();
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  UserPreferenceSingleton.removeInstance();
  dispatch(updateThemeMode({ propsName: 'theme', value: COLOR_THEME_TYPE.LIGHT }));
  LocalDbService.deleteDatabase();
  return true;
};

export const validateIsLoggedIn = () => {
  try {
    const token = setupToken();
    if (token) return true;
    return false;
  } catch (e) {
    captureException(e);
    console.log('Error', e);
    return false;
  }
};

export const signUp: any = (payload: SignUpPayloadInterface) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (isEmpty(payload.name)) {
      dispatchAuthError('Please Enter Name', dispatch);
      return false;
    } else if (isEmpty(payload.email)) {
      dispatchAuthError('Please Enter Email', dispatch);
      return false;
    } else if (!isEmail(payload.email)) {
      dispatchAuthError('Please Enter valid Email', dispatch);
      return false;
    } else if (isEmpty(payload.password)) {
      dispatchAuthError('Please Enter Password', dispatch);
      return false;
    }
    dispatch(setAuthLoader(true));
    const newPayload = {
      ...(payload || {}),
      deviceInfo: getDeviceInfo()
    };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/auth/signup`, newPayload);
    const { data } = response;
    if (data?.error) {
      dispatchAuthError(data?.error, dispatch);
      return false;
    }
    if (data) {
      dispatch(setLoginDetail(data?.userDetails));
      dispatch(setUserProfileData(data?.userDetails));
      trackAnalyticActivity(ACCOUNT_ANALYTICS.CREATED);
      const result = await dispatch(appInit(false, false));
      dispatchAuthSucess('Signup successfull', dispatch);
      LocalDbService.openDatabase();
      return result;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to Sign up please try again';
    captureException(error);
    dispatchAuthError(error, dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

export const forgotPassword: any = (payload: ForgotPayloadInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (isEmpty(payload.email)) {
      dispatchAuthError('Please Enter Email', dispatch);
      return false;
    } else if (!isEmail(payload.email)) {
      dispatchAuthError('Please Enter valid Email', dispatch);
      return false;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/auth/forgotPassword`, payload);
    const { data } = response;
    if (data?.error) {
      dispatchAuthError(data?.error, dispatch);
      return false;
    }
    if (data) {
      dispatchAuthSucess(data?.message, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to forgot password please try again';
    captureException(error);
    dispatchAuthError(error, dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc change password detail
 */
export const changePasswordDetail: any =
  (payloadData: PasswordDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setAuthValidationError(''));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        ...payloadData,
        userId: userDetails?.id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/User/changePassword`, payload);
      const { data } = response;
      if (data?.error) {
        dispatchAuthError(data?.error, dispatch);
        return;
      } else if (data) {
        dispatchAuthSucess(data?.message, dispatch);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to chnage password please try again';
      captureException(error);
      dispatchAuthError(error, dispatch);
      return false;
    } finally {
      dispatch(setAuthLoader(false));
    }
  };

/**
 * @desc reset password detail
 */
export const resetPasswordDetail: any =
  (payload: ResetPasswordDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setAuthValidationError(''));
      if (isEmpty(payload.email) || payload.email.trim() === '') {
        dispatchAuthError('Email is required', dispatch);
        return;
      } else if (!isEmail(payload.email)) {
        dispatchAuthError('Please enter a valid email address', dispatch);
        return;
      } else if (isEmpty(payload.password)) {
        dispatchAuthError('Please Enter Password', dispatch);
        return false;
      } else if (isEmpty(payload.confirmPassword)) {
        dispatchAuthError('Please Enter Confirm Password', dispatch);
        return false;
      } else if (payload.password !== payload.confirmPassword) {
        dispatchAuthError('Password and Confirm Password must be same', dispatch);
        return false;
      }
      dispatch(setAuthLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/auth/resetPassword`, payload);
      const { data } = response;
      if (data?.error) {
        dispatchAuthError(data?.error, dispatch);
        return false;
      }
      if (data) {
        dispatchAuthSucess(data.message, dispatch);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to reset password please try again';
      captureException(error);
      dispatchAuthError(error, dispatch);
      return false;
    } finally {
      dispatch(setAuthLoader(false));
    }
  };

/**
 * @desc Project - Get Invitation Details
 * @param {*}
 */
export const getInvitationDetails: any = (invitation_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const params = {
      invitationId: invitation_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/company/getInvitation`, { params });
    const { data } = response;
    dispatch(setInviteInput(data));
    return true;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get invitation details please try again';
    captureException(error);
    dispatchAuthError(error, dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Firebase - Get Firebase token
 * @param {*} workspace_id
 */
export const getFirebaseToken: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const headers = {
      workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/firebase/getFirebaseToken`, { headers });
    const { data } = response;
    if (data?.token) {
      UserPreferenceSingleton.getInstance().setFirebaseCustomToken(data?.token);
      return data?.token;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Firebase token please try again';
    captureException(error);
    dispatchAuthError(error, dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

function dispatchAuthError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
function dispatchAuthSucess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
export function dispatchAuthValidationError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setAuthValidationError(msg));
}

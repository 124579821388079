import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import AppLayout from '../../component/appLayout';
import {
  CUSTOMER_MEMBER_ROLE,
  INVOICE_STATUS_TYPE,
  INVOICE_TYPE,
  MENU_OPTIONS,
  INVOICE_STATUS,
  InvoiceDraftMoreMenuItem,
  InvoiceMoreMenuItems
} from '../../global/constants';
import { currencySymbols } from '../../global/row';
import { calculateDueTime, getFormattedDate, getUrlParam, isEmpty } from '../../helpers/common';
import { ROUTES } from '../../routes/urls';
import { deleteInvoice, getInvoiceList, getInvoiceViewData } from '../../services/invoiceService';
import { Content, Heading, Title } from '../project/header/styles';
import {
  TableHeader,
  UserName,
  Tabledata,
  Table,
  TableDetail,
  PersonName,
  InvoiceNo,
  InvoiceDate,
  Amount,
  Project,
  StatusPAID,
  Pagedata,
  ButtonBlock,
  Headerblock,
  Group,
  Empty,
  Arrowbtn,
  Dropbox,
  Downarrow,
  Paginations,
  Leftdiv,
  Left,
  Right,
  ShowRow,
  Rowtext,
  Rightdiv,
  Text,
  Buttons,
  PlusIcon,
  HeaderLeft,
  ResponsiveNavbarIcon,
  Icon,
  Blankdiv,
  UserDetail,
  OptionMenu,
  DropdownDiv
} from './styles';
import { getCustomerList, getCustomerProjectDetail } from '../../services/customerServices';
import EmptyState from '../../component/emptyState';
import invoiceempty from '../../assets/images/emptystates/invoice-empty.svg';
import { setFilteredInvoiceList, setInvoiceDetail, setInvoiceListFilteredWise } from '../../actions/invoiceActions';
import Deletemodal from '../../component/models/deleteModel';
import InvoiceLoading from '../../component/loading/invoiceLoding';
import Button from '../../component/button';
import ModalCustom from '../../component/models/modal';
import { setSuccessMessage } from '../../actions/messageActions';
import invoicedarkempty from '../../assets/images/emptystates/invoicedarkempty.svg';
import Invoicemodal from '../../component/models/invoicemodal';
import { InvoiceListFilteredWiseInterface, projectIdInterface } from '../../interfaces/InvoiceInterface';
import { captureException } from '../../services/logService';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { INVOICE_ANALYTICS } from '../../global/analyticsConstants';
import { useMobileDevice } from '../../global/useMobile';
import Responsivnavbar from '../../component/navbar/responsivenavbar';
import { Dropdown, DropdownItem } from '../../component/Dropdown';

const Invoices: React.FC = () => {
  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  //States
  const [isOpen, setIsOpen] = useState(false);
  const [customerListIndex, setCustomerListIndex] = useState({ start: 1, end: 10 });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<InvoiceListFilteredWiseInterface | null>(null);
  const [deleteModelopen, setDeleteModalOpen] = useState(false);
  const [openData, setOpenData] = useState<InvoiceListFilteredWiseInterface[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector, invoice: invoiceSelector } = stateSelector || {};
  const { invoices, filteredInvoices, invoiceListFilteredWise } = invoiceSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id, customerRole, isOwner, isAdmin } = workspace;
  const { themeMode } = settingsSelector;

  //Other variable
  const history = useHistory();
  const existingPageData = UserPreferenceSingleton.getInstance().getInvoicePagination();
  const storedRowsPerPage = existingPageData?.[workspace_id];
  const [pageData, setPageData] = useState(() => {
    return {
      currentPage: 1,
      numOfData: storedRowsPerPage ? Number(storedRowsPerPage) : 100
    };
  });
  const filterType: number = Number(getUrlParam(history.location.search, 'type'));
  const invoicesData = filterType === INVOICE_TYPE.ALL ? invoices : openData;
  const rowOptions = [10, 20, 50, 100];
  const getImageSource = () => (themeMode?.theme === 'dark' ? invoicedarkempty : invoiceempty);
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  // manage pagination data
  const handleIndexChange = useCallback(
    (currentPage: number, numOfData: number) => {
      const startIndex = Math.min((currentPage - 1) * numOfData + 1, invoicesData?.length ?? 0);
      const endIndex = Math.min(currentPage * numOfData, invoicesData?.length ?? 0);
      setCustomerListIndex({ start: startIndex, end: endIndex });
    },
    [invoicesData?.length]
  );

  // load initial data
  const loadData = useCallback(
    async () => {
      try {
        if (!isEmpty(workspace_id)) {
          setLoading(true);
          if (storedRowsPerPage && pageData.numOfData !== Number(storedRowsPerPage)) {
            setPageData({
              ...pageData,
              numOfData: Number(storedRowsPerPage)
            });
          }
          handleIndexChange(pageData?.currentPage, pageData?.numOfData);
          if (workspace?.isAdmin || workspace?.isOwner) await dispatch(getCustomerList());
          const result = await dispatch(getInvoiceList(workspace));
          let updatedList;
          const startingIndex = pageData?.numOfData * (pageData?.currentPage - 1);
          if (filterType === INVOICE_TYPE.ALL) {
            updatedList = result;
            const subData = updatedList?.slice(startingIndex, startingIndex + pageData?.numOfData);
            dispatch(setFilteredInvoiceList(subData));
            dispatch(setInvoiceListFilteredWise(updatedList));
          } else {
            updatedList = result?.filter((item: InvoiceListFilteredWiseInterface) => item?.statusText !== 'PAID');
            setOpenData(updatedList);
            const subData = updatedList?.slice(startingIndex, startingIndex + pageData?.numOfData);
            dispatch(setFilteredInvoiceList(subData));
            dispatch(setInvoiceListFilteredWise(updatedList));
          }
          loadPaginationData(pageData?.currentPage, pageData?.numOfData, updatedList);
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspace_id, storedRowsPerPage, pageData, handleIndexChange, dispatch]
  );

  // manage invoice type
  const trackActivity = useCallback(() => {
    if (filterType === INVOICE_TYPE.ALL) {
      trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_LIST, { view: 'all' });
    } else {
      trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_LIST, { view: 'open' });
    }
  }, [filterType]);

  useEffect(() => {
    loadData();
    trackActivity();
  }, [loadData, trackActivity]);

  useEffect(() => {
    return () => {
      dispatch(setInvoiceListFilteredWise([]));
      dispatch(setFilteredInvoiceList([]));
    };
  }, [dispatch]);

  // handle click event for new invoice
  const handleClick = useCallback(() => {
    trackAnalyticActivity(INVOICE_ANALYTICS.NEW_INVOICE_CLICKED);
    setIsOpen(!isOpen);
  }, [isOpen]);

  // open delete model
  const openDeleteModel = useCallback((item: InvoiceListFilteredWiseInterface) => {
    setItem(item);
    setDeleteModalOpen(true);
  }, []);

  // close delete model
  const closeDeleteModel = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  // edit invoice
  const onInvoiceEdit = useCallback(
    async (item: InvoiceListFilteredWiseInterface) => {
      const response = await Promise.all([
        dispatch(getInvoiceViewData(item?.id)),
        dispatch(getCustomerProjectDetail(workspace_id, item?.customer?.id))
      ]);
      if (item?.statusText === 'DRAFT') {
        trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_DRAFT);
      }
      dispatch(setInvoiceDetail(response[0]));
      history.push(`/invoices/update/${item?.id}?customer=${item?.customer?.id}`);
    },
    [dispatch, history, workspace_id]
  );

  // delete invoice
  const onClickDeleteInvoice = useCallback(
    async (item: { id: string }) => {
      try {
        setLoading(true);
        const result = await dispatch(deleteInvoice(item?.id));
        if (result) {
          setDeleteModalOpen(false);
          trackAnalyticActivity(INVOICE_ANALYTICS.DELETED);
          setIsOpen(false);
          dispatch(setSuccessMessage('Invoice deleted successfully!'));
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, loadData]
  );

  // load pagination data
  const loadPaginationData = useCallback(
    (currentPage: number, numOfData: number, list?: InvoiceListFilteredWiseInterface[]) => {
      if (!list) {
        list = invoiceListFilteredWise;
      }
      const startingIndex = numOfData * (currentPage - 1);
      const endingIndex =
        startingIndex + numOfData > invoicesData?.length ? invoicesData?.length : startingIndex + numOfData;
      const subData = invoicesData?.slice(startingIndex, endingIndex);
      if (subData.length > 0) {
        dispatch(setFilteredInvoiceList(subData));
      } else {
        dispatch(setFilteredInvoiceList(list));
      }
    },
    [invoicesData, invoiceListFilteredWise, dispatch]
  );

  // for list all open invoices
  const onClickOpen = useCallback(() => {
    history.push(`${history.location.pathname}?type=${INVOICE_TYPE.OPEN}`);
    const updatedList = invoicesData?.filter((item) => item?.statusText !== 'PAID');
    setPageData((prevData) => ({
      ...(prevData || {}),
      currentPage: 1
    }));
    dispatch(setInvoiceListFilteredWise(updatedList));
    loadPaginationData(1, pageData?.numOfData, updatedList);
  }, [dispatch, invoicesData, pageData, history, loadPaginationData]);

  // for list of all invoices
  const onClickAll = useCallback(() => {
    history.push(`${history.location.pathname}?type=${INVOICE_TYPE.ALL}`);
    const updatedList = invoicesData;
    setPageData((prevData) => ({
      ...(prevData || {}),
      currentPage: 1
    }));
    dispatch(setInvoiceListFilteredWise(updatedList));
    loadPaginationData(1, pageData?.numOfData, updatedList);
  }, [dispatch, invoicesData, pageData, history, loadPaginationData]);

  // redirect to invoice details page
  const onClickInvoice = useCallback(
    (invoice: InvoiceListFilteredWiseInterface) => {
      history.push(`/invoices/view/${invoice?.id}`);
    },
    [history]
  );

  // handle close model
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  // for make duplicate invoice
  const onDuplicateInvoice = useCallback(
    async (data: InvoiceListFilteredWiseInterface) => {
      try {
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const projectResult = await dispatch(getCustomerProjectDetail(workspace_id, data?.customer?.id));
        const updatedItem = data?.items?.map((item) => {
          if (!isEmpty(item?.projectId)) {
            const project = projectResult?.find((ele: { id: projectIdInterface }) => ele?.id === item?.projectId);
            return { ...item, projectId: project };
          } else {
            return item;
          }
        });
        const invoiceData: any = {
          ...data,
          currency: data?.currency,
          date: new Date().toISOString(),
          userId: userDetails?.id,
          customerId: data?.customer?.id,
          emails: [],
          items: updatedItem,
          isSendMeCopy: false,
          dueDate: data?.dueDays ? new Date(new Date().setDate(new Date().getDate() + data.dueDays)).toISOString() : ''
        };
        const fieldsToDelete = [
          'id',
          'status',
          'paidAmount',
          'statusText',
          'number',
          'invoicePayment',
          'customer',
          'dueAmount'
        ];
        fieldsToDelete.forEach((field) => delete invoiceData?.[field]);
        dispatch(setInvoiceDetail(invoiceData));
        history.push(`${ROUTES.ADD_NEW_INVOICES_2}?customer=${data?.customer?.id}`);
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [dispatch, workspace_id, history]
  );

  // handle dropdown click
  const handleDropdownClick = useCallback(
    (key: number | string, item: InvoiceListFilteredWiseInterface) => {
      key = Number(key);
      setSelectedItem(null);
      if (key === MENU_OPTIONS.VIEW) {
        onClickInvoice(item);
      } else if (key === MENU_OPTIONS.EDIT) {
        onInvoiceEdit(item);
      } else if (key === MENU_OPTIONS.DELETE) {
        openDeleteModel(item);
      } else if (key === MENU_OPTIONS.DUPLICATE) {
        onDuplicateInvoice(item);
      }
    },
    [onClickInvoice, onDuplicateInvoice, onInvoiceEdit, openDeleteModel]
  );

  // handle menu click for rows per page
  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      const rowsPerPage = parseInt(key);
      const pageData = {
        ...(existingPageData || {}),
        [workspace_id]: rowsPerPage
      };
      UserPreferenceSingleton.getInstance().setInvoicePagination(pageData);
      setPageData((prevPageData) => {
        // Recalculate current page based on the new number of rows per page
        const newPage = Math.floor(((prevPageData.currentPage - 1) * prevPageData.numOfData) / rowsPerPage) + 1;
        const updatedPageData = { ...prevPageData, currentPage: newPage, numOfData: rowsPerPage };
        handleIndexChange(newPage, rowsPerPage);
        loadPaginationData(newPage, rowsPerPage);
        return updatedPageData;
      });
    },
    [existingPageData, handleIndexChange, loadPaginationData, workspace_id]
  );

  // for redirect to forward and backward page
  const onChangePage = useCallback(
    (direction: 'Forward' | 'Backward') => {
      setPageData((prevPageData) => {
        let updatedPage = prevPageData.currentPage;
        if (
          direction === 'Forward' &&
          prevPageData.currentPage * prevPageData.numOfData < (invoicesData?.length ?? 0)
        ) {
          updatedPage += 1;
        } else if (direction === 'Backward' && prevPageData.currentPage > 1) {
          updatedPage -= 1;
        }
        handleIndexChange(updatedPage, prevPageData.numOfData);
        loadPaginationData(updatedPage, prevPageData.numOfData);
        return { ...prevPageData, currentPage: updatedPage };
      });
    },
    [handleIndexChange, invoicesData?.length, loadPaginationData]
  );

  const handleDropdownOpen = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const renderMoreMenuItems = useMemo(
    () => (item: InvoiceListFilteredWiseInterface) => {
      const selectedMenuItems = item?.status === INVOICE_STATUS.DRAFT ? InvoiceDraftMoreMenuItem : InvoiceMoreMenuItems;
      return (
        <>
          {selectedMenuItems.map((menuItem) => (
            <DropdownItem
              key={menuItem.key}
              label={menuItem.label}
              onClick={() => handleDropdownClick(menuItem.key, item)}
              tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
            />
          ))}
        </>
      );
    },
    [handleDropdownClick]
  );

  // get filtered invoices based on the filter type
  const filteredInvoiceList = useMemo(() => {
    if (!filteredInvoices?.length) return null;
    return (
      <Table>
        <TableHeader>
          <UserName>
            <h1>Name</h1>
          </UserName>
          <Tabledata>
            <h1>Invoice no</h1>
            <h4>Invoice Date</h4>
            <h2>Amount</h2>
            <h3>Status</h3>
          </Tabledata>
          <div className='blank-div'>
            <Blankdiv />
          </div>
        </TableHeader>
        {filteredInvoices?.map((item, index) => {
          const ref = currencySymbols.find((x) => x.code === item?.currency);
          const symbol = ref?.symbol;
          if (!buttonRef?.current[index]) {
            buttonRef.current[index] = React.createRef<HTMLDivElement>();
          }

          return (
            <UserDetail key={item?.id}>
              <TableDetail isActive={selectedItem === item?.id} onClick={() => onClickInvoice(item)}>
                <PersonName isBlackText={isEmpty(item?.customer?.firstName) && isEmpty(item?.customer?.lastName)}>
                  {!isEmpty(item?.customer?.firstName) && !isEmpty(item?.customer?.lastName) && (
                    <h1>
                      {item?.customer?.firstName} {item?.customer?.lastName}
                    </h1>
                  )}
                  <p>{item?.customer?.companyName}</p>
                </PersonName>
                <Project>
                  <InvoiceNo>{item?.number}</InvoiceNo>
                  <InvoiceDate>
                    <h1>{getFormattedDate(new Date(item?.date))}</h1>
                    <p>{calculateDueTime(item?.dueDate)?.dueTime}</p>
                  </InvoiceDate>
                  <Amount>
                    <h2>
                      {symbol} {item?.totalAmount}
                    </h2>
                    {item?.status !== INVOICE_STATUS_TYPE.PAID && (
                      <p>
                        Due {symbol} {item?.dueAmount}
                      </p>
                    )}
                  </Amount>
                  <StatusPAID status={item?.statusText}>{item?.statusText}</StatusPAID>
                </Project>
                <DropdownDiv>
                  {customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS && (isAdmin || isOwner) && (
                    <Dropdown
                      activeClassName='active'
                      content={
                        <OptionMenu>
                          <Icon onClick={() => setSelectedItem((prev) => (prev === null ? item?.id : null))}>
                            <SVGIcon
                              name='more-icon'
                              width='18'
                              height='18'
                              viewBox='0 0 18 18'
                              fill='none'
                              className='invoice-more-icon'
                            />
                          </Icon>
                        </OptionMenu>
                      }
                      trigger='click'
                      onOutsideClick={() => setSelectedItem(null)}>
                      {renderMoreMenuItems(item)}
                    </Dropdown>
                  )}
                </DropdownDiv>
              </TableDetail>
            </UserDetail>
          );
        })}
      </Table>
    );
  }, [customerRole, filteredInvoices, isAdmin, isOwner, onClickInvoice, renderMoreMenuItems, selectedItem]);

  return (
    <>
      <AppLayout>
        <Group>
          <Pagedata>
            <Content>
              <Headerblock>
                <HeaderLeft>
                  {isMobile && (
                    <ResponsiveNavbarIcon>
                      <Responsivnavbar backNavigationPath='/home' />
                    </ResponsiveNavbarIcon>
                  )}
                  <Heading>
                    <Icon>
                      <SVGIcon
                        name='invoice-header-icon'
                        width='28'
                        height='28'
                        viewBox='0 0 28 28'
                        className='invoice-icon'
                      />
                    </Icon>
                    <Title>Invoices</Title>
                  </Heading>
                </HeaderLeft>
                {!isMobile && (
                  <Buttons>
                    <ButtonBlock isActive={filterType === INVOICE_TYPE.OPEN || !filterType} onClick={onClickOpen}>
                      Open
                    </ButtonBlock>
                    <ButtonBlock isActive={filterType === INVOICE_TYPE.ALL} onClick={onClickAll}>
                      All
                    </ButtonBlock>
                  </Buttons>
                )}
              </Headerblock>
              {filteredInvoices?.length > 0 &&
                customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS &&
                (isAdmin || isOwner) &&
                (isMobile ? (
                  <PlusIcon onClick={handleClick}>
                    <SVGIcon name='task-plus-icon' width='26' height='26' viewBox='0 0 32 33' className='circle' />
                  </PlusIcon>
                ) : (
                  <Button
                    title='New invoice'
                    onClick={handleClick}
                    iconName='plus-icon'
                    type='button'
                    iconSize={18}
                    iconViewBox='0 0 18 18'
                    iconColor='#fff'
                    modelbtn={true}
                  />
                ))}
            </Content>
            {loading && (filteredInvoices?.length === 0 || !filteredInvoices) && <InvoiceLoading />}
            {!loading && filteredInvoices?.length === 0 && (
              <Empty>
                <EmptyState
                  header={'You have no invoice records'}
                  title={'You can create new invoice by clicking the button below.'}
                  image={getImageSource()}
                  name={'New invoice'}
                  onButtonClick={handleClick}
                  hideButton={!(isAdmin || isOwner)}
                />
              </Empty>
            )}
            {isMobile && (
              <Buttons>
                <ButtonBlock isActive={filterType === INVOICE_TYPE.OPEN || !filterType} onClick={onClickOpen}>
                  Open
                </ButtonBlock>
                <ButtonBlock isActive={filterType === INVOICE_TYPE.ALL} onClick={onClickAll}>
                  All
                </ButtonBlock>
              </Buttons>
            )}
            {filteredInvoiceList}
          </Pagedata>
          {filteredInvoices?.length > 0 && (
            <Paginations>
              <Leftdiv>
                <Text>Show</Text>
                <Dropdown
                  isMinWidth={110}
                  content={
                    <ShowRow onClick={() => handleDropdownOpen()}>
                      <Rowtext>{`${pageData.numOfData} Rows`}</Rowtext>
                      <Downarrow>
                        <SVGIcon
                          name='left-arrow-icon'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          className={dropdownOpen ? 'arrow-open stroke-color' : 'stroke-color'}
                        />
                      </Downarrow>
                    </ShowRow>
                  }
                  trigger='click'>
                  <Dropbox>
                    {rowOptions.map((rows) => (
                      <DropdownItem
                        key={rows}
                        label={`${rows} Rows`}
                        onClick={() => handleMenuClick({ key: String(rows) })}
                      />
                    ))}
                  </Dropbox>
                </Dropdown>
              </Leftdiv>
              <Rightdiv>
                <Text>{`${customerListIndex?.start} - ${
                  customerListIndex?.end > invoicesData?.length ? invoicesData?.length : customerListIndex?.end
                } of ${invoicesData?.length}`}</Text>
                <Arrowbtn>
                  <Left onClick={() => onChangePage('Backward')}>
                    <SVGIcon
                      name='left-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='stroke-color'
                    />
                  </Left>
                  <Right onClick={() => onChangePage('Forward')}>
                    <SVGIcon
                      name='left-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='stroke-color'
                    />
                  </Right>
                </Arrowbtn>
              </Rightdiv>
            </Paginations>
          )}
        </Group>
      </AppLayout>
      <ModalCustom open={isOpen} onClose={handleClose} width={381}>
        <Invoicemodal closemodel={handleClose} />
      </ModalCustom>
      <ModalCustom open={deleteModelopen} onClose={closeDeleteModel} width={334}>
        <Deletemodal
          onClose={closeDeleteModel}
          onOk={() => {
            if (item) onClickDeleteInvoice(item);
          }}
          loading={loading}
          modaltitle='Delete Invoice?'
          description='Are you sure to want to delete this invoice?'
        />
      </ModalCustom>
    </>
  );
};

export default Invoices;

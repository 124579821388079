import React from 'react';
import { RadioContainer, RadioInput, RadioLabel } from './styles';

interface RadioButtonProps {
  id: string;
  name: string;
  value: string | number;
  checked: boolean;
  label: string;
  onChange: (value: string | number) => void;
  className?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ id, name, value, checked, label, onChange, className }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <RadioContainer>
      <RadioInput type='radio' id={id} name={name} value={value} checked={checked} onChange={handleChange} />
      <RadioLabel className={className} htmlFor={id}>
        {label}
      </RadioLabel>
    </RadioContainer>
  );
};

export default RadioButton;

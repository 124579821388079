import { makeAutoObservable } from 'mobx';
import { Label, ProjectProgress, RawProject } from '../interfaces/project';
import { rootStore } from '../rootStore';
import { ProjectResourceDetailInterface, StatusListInterface } from '../../interfaces/ProjectInterface';
import { CustomerDetailsInterface } from '../../interfaces/CustomerInterface';
import { calculateTaskProgress, getDefaultProgress } from '../../services/projectServices';

export class ProjectModel {
  // Common
  id: string;
  name: string;
  description: string | null;

  // Dates
  createdOn: Date;
  dueDate: Date | null;

  // Relational
  createdById: string;
  customerId: string;
  companyId: string;
  usersIds: string[];

  // Others
  index: number;
  isArchived: boolean;
  isEstimate: boolean;
  isFavorite: boolean;
  isGroupEnabled: boolean;
  // isPriority: boolean;
  isMilestone: boolean;
  labelsEnabled: boolean;
  labelsList: Label[];
  multiAssignee: boolean;
  priority: {
    enabled: boolean;
    default: number;
  };
  statusData: StatusListInterface[];
  statusEnable: boolean;
  // taskGroups: TaskGroup[];
  // milestones: Milestone[];
  customer: CustomerDetailsInterface | null;
  githubRepository: number | null;
  resources: ProjectResourceDetailInterface[];

  progressData: ProjectProgress = getDefaultProgress();

  constructor(rawProject: RawProject) {
    makeAutoObservable(this);

    // Common
    this.id = rawProject.id;
    this.name = rawProject.name;
    this.description = rawProject.description;
    this.createdOn = new Date(rawProject.createdOn);
    this.dueDate = rawProject.dueDate ? new Date(rawProject.dueDate) : null;

    // Relational
    this.createdById = rawProject.createdBy;
    this.customerId = rawProject.customerId;
    this.companyId = rawProject.companyId;
    this.usersIds = rawProject.users;

    // Others
    this.index = this.createdOn.getTime();
    this.isArchived = rawProject.isArchived ?? false;
    this.isEstimate = rawProject.isEstimate ?? false;
    this.isFavorite = rawProject.isFavorite ?? false;
    this.isGroupEnabled = rawProject.isGroupEnabled ?? false;
    // this.isPriority = rawProject.isPriority ?? false;
    this.isMilestone = rawProject.isMilestone ?? false;
    this.labelsEnabled = rawProject.labelsEnabled || false;
    this.labelsList = rawProject.labelsList || [];
    this.multiAssignee = rawProject.multiAsignee || false;
    this.priority = rawProject.priority ?? { enabled: false, default: 0 };
    this.statusData = rawProject.status ?? [];
    this.statusEnable = rawProject.statusEnable ?? false;
    // this.taskGroups = rawProject.taskGroups ?? [];
    // this.milestones = rawProject.milestones ?? [];
    this.customer = rawProject.customer ?? null;
    this.githubRepository = rawProject.githubRepository ?? null;
    this.resources = rawProject.resources ?? [];

    this.updateProgressData();
  }

  get users() {
    return rootStore.workspaceStore.currentWorkspace?.users.filter((user) => this.usersIds.includes(user.id)) || [];
  }

  get defaultStatus() {
    return this.statusData.find((status) => status.Default) || this.statusData[0] || {};
  }

  get tasks() {
    return rootStore.taskStore.tasks.filter((task) => task.projectId === this.id) ?? [];
  }

  setFavorite(isFavorite: boolean) {
    this.isFavorite = isFavorite;
  }

  setIndex(index: number) {
    this.index = index;
  }

  addUser(userId: string) {
    this.usersIds.push(userId);
  }

  removeUser(userId: string) {
    this.usersIds = this.usersIds.filter((id) => id !== userId);
  }

  addResource(resource: ProjectResourceDetailInterface) {
    this.resources.push(resource);
  }

  removeResource(resourceId: string) {
    this.resources = this.resources.filter((r) => r['_id'] !== resourceId);
  }

  updateResource(resourceId: string, resourceUpdate: { reference?: string; label?: string }) {
    this.resources = this.resources.map((r) => {
      if (r['_id'] === resourceId) {
        const Label = resourceUpdate.label ?? r.Label;
        const Reference = resourceUpdate.reference ?? r.Reference;
        return { ...r, Label, Reference };
      }
      return r;
    });
  }
  setGroupEnabled(isGroupEnabled: boolean) {
    this.isGroupEnabled = isGroupEnabled;
  }

  setPriorityEnabled(priorityEnabled: boolean) {
    this.priority.enabled = priorityEnabled;
  }

  setDefaultPriority(defaultPriority: number) {
    this.priority.default = defaultPriority;
  }

  setStatusEnabled(statusEnabled: boolean) {
    this.statusEnable = statusEnabled;
  }

  setLabelsEnabled(labelsEnabled: boolean) {
    this.labelsEnabled = labelsEnabled;
  }

  setMultiAssignee(multiAssignee: boolean) {
    this.multiAssignee = multiAssignee;
  }

  setEstimate(isEstimate: boolean) {
    this.isEstimate = isEstimate;
  }

  setMilestone(isMilestone: boolean) {
    this.isMilestone = isMilestone;
  }

  *updateProgressData() {
    this.progressData = yield calculateTaskProgress(this.id);
  }
}

export const createObservableProject = (rawProject: RawProject) => {
  return new ProjectModel(rawProject);
};

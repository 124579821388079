import { makeAutoObservable } from 'mobx';
import { NotificationStore } from './stores/notificationStore';
import { WorkspaceStore } from './stores/workspaceStore';
import { ProjectStore } from './stores/projectStore';
import { TaskStore } from './stores/taskStore';
export class RootStore {
  notificationStore: NotificationStore = new NotificationStore();
  workspaceStore: WorkspaceStore = new WorkspaceStore();
  projectStore: ProjectStore = new ProjectStore();
  taskStore: TaskStore = new TaskStore();

  constructor() {
    makeAutoObservable(this);
  }
}

export const rootStore = new RootStore();

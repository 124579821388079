import styled from 'styled-components';
import { Typography } from '../../global/theme';

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid var(--border-secondary);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
  margin: 0;

  &:checked {
    border-color: var(--brand-primary);
    background-color: var(--brand-primary);

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: white;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const RadioLabel = styled.label`
  ${Typography.body_md};
  color: var(--text-secondary);
  cursor: pointer;
  letter-spacing: 0;
`;

export { RadioContainer, RadioInput, RadioLabel };

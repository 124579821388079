import { RawNotification } from './interfaces/notification';
import { RawProject } from './interfaces/project';
import { RawTask } from './interfaces/task';
import { NotificationModel } from './models/notification';
import { ProjectModel } from './models/project';
import { TaskModel } from './models/task';

type RawItem = RawTask | RawProject | RawNotification;
type ObservableItem = TaskModel | ProjectModel | NotificationModel;

/**
 * Generic function to upsert items in a store
 * @param items Current items in the store
 * @param rawItems New raw items to upsert
 * @param createObservableItem Function to create an observable item from raw data
 * @param additionalUpdateLogic Optional function to apply additional update logic
 * @returns Object containing new and updated items
 */
export function genericUpsert<T extends ObservableItem, R extends RawItem>(
  items: T[],
  rawItems: R[],
  createObservableItem: (rawItem: R) => T,
  additionalUpdateLogic?: (existingItem: T, updatedItem: T) => void
) {
  const indexMap = new Map<string, number>(items.map((item, index) => [(item as any).id, index]));

  const newItems: T[] = [];
  const updatedItems: T[] = [];

  for (const rawItem of rawItems) {
    const itemIndex = indexMap.get((rawItem as any).id);
    if (typeof itemIndex === 'number') {
      const updatedItem = createObservableItem(rawItem);

      if (additionalUpdateLogic) {
        additionalUpdateLogic(items[itemIndex], updatedItem);
      }

      items[itemIndex] = updatedItem;
      updatedItems.push(updatedItem);
    } else {
      const newItem = createObservableItem(rawItem);
      items.push(newItem);
      newItems.push(newItem);
    }
  }

  return { newItems, updatedItems };
}

/**
 * Generic function to remove items from a store
 * @param items Current items in the store
 * @param itemIds IDs of items to remove
 * @returns The filtered array of items
 */
export function genericRemove<T extends ObservableItem>(items: T[], itemIds: string[]): T[] {
  return items.filter((item) => !itemIds.includes((item as any).id));
}

import styled, { css } from 'styled-components';
import { Typography } from '../../../../../global/theme';

interface TaskButtonProps {
  isActive?: boolean;
  isHide?: boolean;
}

const Container = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px 10px 0;
  background-color: var(--background-secondary);
  margin: 0 -24px 24px 0;
  z-index: 999;
  border-bottom: 0.5px solid var(--border-primary);
  ::after {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    position: absolute;
    top: 61px;
    pointer-events: none;
  }
  @media (max-width: 449px) {
    padding: 0;
    margin-left: -16px;
    flex-direction: column;
    border-bottom: none;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-left: 24px;
  @media (max-width: 449px) {
    padding-left: 0;
  }
`;

const Title = styled.h1`
  ${Typography.heading_md}
  color:var(--text-primary);
  margin: 0;
`;

const Buttonblock = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Taskbutton = styled.button<TaskButtonProps>`
  background-color: var(--background-secondary);
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding: 3px 12px;
  border: 0.5px solid transparent;
  cursor: pointer;
  height: 26px;
  :hover {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 36px;
  }

  ${(props: TaskButtonProps) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 36px;
      padding: 3px 12px;
      ${Typography.body_md_medium}
      color:var(--text-primary);
    `}
`;

const Taskicons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 26px;
  @media (max-width: 449px) {
    align-items: center;
    .hidebutton {
      display: none;
    }
    .responsive-button {
      height: 26px;
    }
  }
`;

const Filter = styled.div<TaskButtonProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 3px 15px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 36px;
  box-shadow: var(--shadow-button);
  &:hover {
    background-color: var(--background-secondary);
  }
  .filters-icon-color {
    fill: var(--text-secondary);
  }
  ${(props: TaskButtonProps) =>
    props.isHide &&
    css`
      border: none;
      background: none;
      box-shadow: none;
      padding: 8px;
      :hover {
        background: var(--neutral-secondary);
        border-radius: 16px;
      }
    `}
  @media (max-width: 449px) {
    padding: 8px;
    border: none;
    box-shadow: unset;
  }
`;

const Selecttext = styled.div<TaskButtonProps>`
  ${Typography.body_md_medium}
  color:var(--text-primary);
  margin: 0;
  ${(props: TaskButtonProps) =>
    props.isHide &&
    css`
      display: none;
    `}
  @media (max-width: 449px) {
    display: none;
  }
`;
const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;
const Buttonblocks = styled.div`
  gap: 5px;
  cursor: pointer;
  display: none;
  @media (max-width: 449px) {
    display: flex;
    padding: 12px 16px;
    width: 100%;
    background-color: var(--background-secondary);
  }
`;
const Div = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 449px) {
    background-color: var(--background-primary);
    padding: 12px 16px;
    border-bottom: 0.5px solid var(--border-primary);
    width: 100%;
  }
`;
export {
  Container,
  Content,
  Title,
  Taskbutton,
  Buttonblock,
  Taskicons,
  Filter,
  Selecttext,
  ResponsiveNavbarIcon,
  Buttonblocks,
  Div
};

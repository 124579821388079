/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { LinkModalInterface, ProjectDetailInterface } from '../../interfaces/ProjectInterface';
import { addFileData, getCurrentProjectDetails, updateProjectData } from '../../services/projectServices';
import {
  Container,
  Content,
  Title,
  Taskbutton,
  Buttonblock,
  Options,
  SameIcon,
  PlusButton,
  Icon,
  DropText,
  TextBlock,
  Modalhead,
  Headline,
  Taskicons,
  Filter,
  Data,
  Taskdrop,
  TaskDropdown,
  Property,
  DisplayProperty,
  TaskBox,
  StatusBox,
  Hiddennow,
  Status,
  Maincontent,
  LastIcon,
  StarWithTitle,
  ThreeDotIcon,
  ResponsiveContent,
  ResponsiveNavbarWithTitle,
  ResponsiveTopNavbar,
  DIV,
  TaskListName,
  SVGDiv,
  GroupDiv,
  Switch,
  TaskFilterDiv
} from './styles';
import {
  FILES_LIST_TYPE,
  FILE_TYPES,
  LINK_FILE_DATA,
  MENU_OPTIONS,
  SHOW_COMPLETED_TASK_BY_DATE,
  SHOW_COMPLETED_TASK_FILTER_NAME,
  SHOW_TASK_BY_ORDER,
  SHOW_TASK_FILTER_TYPE,
  SHOW_TASK_GROUPING_TYPE,
  TASK_LIST_TYPE
} from '../../global/constants';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import Deletemodal from '../models/deleteModel';
import { ROUTES } from '../../routes/urls';
import CreateNewProjectModal from '../models/createNewProjectModal';
import { clearProjectDetail, setFilesListType, setProjectDetail } from '../../actions/projectActions';
import { getFirebaseUrlsFromFiles, getUrlParam, isEmpty } from '../../helpers/common';
import {
  setShowTaskStatus,
  updateCreateTaskInput,
  setTasksFilterData,
  updateTaskFilter
} from '../../actions/taskActions';
import CreateTaskModal from '../models/createTaskModal';
import { getTaskGroupList } from '../../services/taskServices';
import { setErrorMessage, setSuccessMessage } from '../../actions/messageActions';
import ModalCustom from '../models/modal';
import Milestonemodal from '../models/milestoneModal';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { captureException } from '../../services/logService';
import { getUserPreferenceFieldData, updateUserPreference } from '../../helpers/firebaseHelper';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { Location } from 'history';
import Responsivnavbar from '../navbar/responsivenavbar';
import { Select } from 'antd';
import { PROJECT_ANALYTICS } from '../../global/analyticsConstants';
import { Dropdown, DropdownDivider, DropdownItem } from '../Dropdown';
import { completedTaskMenu } from '../../global/row';
import { LinkFileModal } from '../models/linkFileModal';
import { FilesModal } from '../models/filesmodal';
import TaskFilterCustomDropdown from '../taskFilterDropdown/taskFilterDropdown';
interface Props {
  loadFilesData?: () => void;
  otherTaskList?: () => void;
  loadTaskList?: () => void;
  isLeftPadding?: boolean;
  setUploadFile?: (value: boolean) => void;
  setUploadedFileDetail?: (item: any) => void;
}

const Accounting: React.FC<Props> = (props) => {
  //States
  const [openTaskModel, setOpenTaskModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [isOpenMilestoneModal, setIsOpenMilestoneModal] = useState(false);
  const [isFileModalOpen, setIsFileModelOpen] = useState(false);
  const [modalData, setModalData] = useState<LinkModalInterface>();
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isOpenCreatePojectModal, setIsOpenCreatePojectModal] = useState(false);
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);
  const [isOpenGroupmenu, setIsOpenGroupmenu] = useState(false);
  const [isOpenCompletedTaskDropdown, setIsOpenCompletedTaskDropdown] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, project: projectSelector, task: taskSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { currentProject, newProjectItem, filesViewListType } = projectSelector;
  const { name, customer } = newProjectItem || {};
  const { taskGroups, taskFilter } = taskSelector;
  //Other variables
  const history = useHistory();
  const params: { id: string } = useParams();
  const location: Location = useLocation();
  const dispatch = useDispatch();
  const folderId = getUrlParam(history.location.search, 'folder');

  const menuItems = [
    { key: 1, label: 'Edit' },
    { key: 3, label: 'Settings' },
    { key: 4, label: 'Archive' }
  ];

  const createTaskModel = useCallback(async () => {
    dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
    await dispatch(getTaskGroupList(currentProject?.id, true));
    if (taskGroups?.length > 0) {
      dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
    }
    dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
    setOpenTaskModel(true);
  }, [currentProject, dispatch, taskGroups]);

  const loadData = useCallback(
    async (isFalse = false) => {
      if (!isEmpty(workspace_id) && (params?.id !== currentProject?.id || isFalse)) {
        const projectDetails: ProjectDetailInterface = await dispatch(
          getCurrentProjectDetails(workspace_id, params?.id)
        );
        const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
        const validUser = projectDetails?.users?.find((user) => user?.id === currentUser?.id);
        if (!validUser) {
          history.push('/');
          return;
        }
      }
      const filterOptionLocal = (await getUserPreferenceFieldData('tasks_filter_type')) || {};
      const currentProjectFilter = filterOptionLocal[params?.id];
      if (
        currentProject?.id &&
        !currentProject?.priority?.enabled &&
        currentProjectFilter?.order === SHOW_TASK_BY_ORDER.PRIORITY
      ) {
        filterOptionLocal[params?.id] = {
          ...currentProjectFilter,
          order: SHOW_TASK_BY_ORDER.DUE_DATE
        };
        await updateUserPreference({ tasks_filter_type: filterOptionLocal });
      }
      const getGroupingType = () => {
        if (currentProject?.statusEnable) return SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS;
        if (currentProject?.isGroupEnabled) return SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP;
        return SHOW_TASK_GROUPING_TYPE.DEFAULT;
      };
      const getOrderType = () => {
        if (currentProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.DEFAULT) return SHOW_TASK_BY_ORDER.CUSTOM;
        if (currentProjectFilter?.order === SHOW_TASK_BY_ORDER.CUSTOM) return SHOW_TASK_BY_ORDER.CREATED_DATE;
        return currentProjectFilter?.order;
      };
      if (
        (currentProject?.id &&
          currentProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE &&
          !currentProject?.isMilestone) ||
        (currentProject?.id &&
          currentProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS &&
          (!currentProject?.statusEnable ||
            (currentProject?.statusEnable && currentProject?.statusData?.length === 0))) ||
        (currentProject?.id &&
          currentProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY &&
          !currentProject?.priority?.enabled) ||
        (currentProject?.id &&
          currentProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP &&
          !currentProject?.isGroupEnabled)
      ) {
        filterOptionLocal[params?.id] = {
          ...currentProjectFilter,
          grouping: getGroupingType(),
          order: getOrderType()
        };
        await updateUserPreference({ tasks_filter_type: filterOptionLocal });
      }
      const getOrderTypePriority = () => {
        if (
          currentProjectFilter?.viewType === TASK_LIST_TYPE.CALANDAR_VIEW &&
          (currentProjectFilter?.order === SHOW_TASK_BY_ORDER.DUE_DATE || !currentProjectFilter?.order)
        )
          return SHOW_TASK_BY_ORDER.PRIORITY;
        if (currentProjectFilter?.order || currentProjectFilter?.order === 0) return currentProjectFilter.order;
        if (currentProjectFilter?.viewType === TASK_LIST_TYPE.CALANDAR_VIEW) return SHOW_TASK_BY_ORDER.PRIORITY;
        return SHOW_TASK_BY_ORDER.DUE_DATE;
      };
      const updatedProjectFilter = {
        ...currentProjectFilter,
        order: getOrderTypePriority()
      };

      dispatch(
        setShowTaskStatus(
          updatedProjectFilter?.showTask >= 0 ? updatedProjectFilter?.showTask : SHOW_TASK_FILTER_TYPE.OPEN
        )
      );

      dispatch(
        updateTaskFilter({
          propsName: 'groupingStatus',
          value: updatedProjectFilter?.grouping >= 0 ? updatedProjectFilter?.grouping : SHOW_TASK_GROUPING_TYPE.DEFAULT
        })
      );

      dispatch(
        updateTaskFilter({
          propsName: 'orderByStatus',
          value: updatedProjectFilter?.order >= 0 ? updatedProjectFilter?.order : SHOW_TASK_BY_ORDER.DUE_DATE
        })
      );
      dispatch(setFilesListType(updatedProjectFilter?.files || FILES_LIST_TYPE.GRID));

      dispatch(
        updateTaskFilter({ propsName: 'taskViewType', value: updatedProjectFilter?.viewType || TASK_LIST_TYPE.TASKS })
      );

      const getTaskGrouping = () => {
        if (currentProject?.statusEnable) return 'Status';
        if (currentProject?.isGroupEnabled) return 'Group';
        return 'No Grouping';
      };

      let tasks: string | null;
      if (
        currentProject?.statusEnable &&
        updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS
      ) {
        tasks = 'Status';
      } else if (
        currentProject?.isGroupEnabled &&
        updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP
      ) {
        tasks = 'Group';
      } else if (
        currentProject?.priority?.enabled &&
        updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY
      ) {
        tasks = 'Priority';
      } else if (updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.NO_GROUPING) {
        tasks = 'No Grouping';
      } else if (updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER) {
        tasks = 'User';
      } else if (
        currentProject?.isMilestone &&
        updatedProjectFilter?.grouping === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE
      ) {
        tasks = 'Milestone';
      } else {
        tasks = getTaskGrouping();
      }
      dispatch(updateTaskFilter({ propsName: 'groupName', value: tasks }));
      let order: string | null;
      if (
        updatedProjectFilter?.order === SHOW_TASK_BY_ORDER.PRIORITY &&
        (currentProject?.priority?.enabled || currentProjectFilter?.viewType === TASK_LIST_TYPE?.CALANDAR_VIEW)
      ) {
        order = 'Priority';
      } else if (updatedProjectFilter?.order === SHOW_TASK_BY_ORDER.CREATED_DATE) {
        order = 'Created Date';
      } else if (updatedProjectFilter?.order === SHOW_TASK_BY_ORDER.CUSTOM) {
        order = 'Custom';
      } else {
        order = 'Due Date';
      }
      dispatch(updateTaskFilter({ propsName: 'orderByName', value: order }));
      let group: string | null;
      if (updatedProjectFilter?.showTask === SHOW_TASK_FILTER_TYPE.ALL) {
        group = 'All';
      } else if (updatedProjectFilter?.showTask === SHOW_TASK_FILTER_TYPE.CLOSE) {
        group = 'Close';
      } else {
        group = 'Open';
      }
      dispatch(setTasksFilterData(group));
    },
    [workspace_id, params?.id, currentProject, dispatch, history]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const isTask = useMemo(() => {
    return location?.pathname === `/projects/details/${params?.id}/tasks`;
  }, [params?.id, location?.pathname]);
  const isFile = useMemo(() => {
    return location?.pathname === `/projects/details/${params?.id}/files`;
  }, [params?.id, location?.pathname]);
  const isMilestone = useMemo(() => {
    return location?.pathname === `/projects/details/${params?.id}/milestone`;
  }, [params?.id, location?.pathname]);
  const isMessages = useMemo(() => {
    return location?.pathname === `/projects/details/${params?.id}/messages`;
  }, [params?.id, location?.pathname]);
  const isOverview = useMemo(() => {
    return location?.pathname === `/projects/details/${params?.id}/overview`;
  }, [params?.id, location?.pathname]);

  const OpenMilestoneModal = () => {
    setIsOpenMilestoneModal(true);
  };

  const isCloseModal = () => {
    setIsOpenMilestoneModal(false);
  };

  const Openarchivemodel = () => {
    setOpenArchiveModal(true);
  };

  const Closearchivemodel = () => {
    setOpenArchiveModal(false);
  };

  const closeTaskModel = () => {
    setOpenTaskModel(false);
  };

  const archive = useCallback(async () => {
    try {
      setLoading(true);
      if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't archive project.`));
      const updatePayload = {
        isArchived: true
      };
      const result = await dispatch(updateProjectData(params?.id, updatePayload));
      if (result) {
        trackAnalyticActivity(PROJECT_ANALYTICS.DELETED);
        history.push(ROUTES.ALL_PROJECT);
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, history, params?.id, workspace]);

  const openLinkFileModal = useCallback((data: LinkModalInterface) => {
    setModalData(data);
    setIsLinkModalOpen(true);
  }, []);

  const closeLinkFileModal = useCallback(() => {
    setIsLinkModalOpen(false);
  }, []);

  const openFileModal = useCallback((data: LinkModalInterface) => {
    setModalData(data);
    setIsFileModelOpen(true);
  }, []);

  const closeFileModel = useCallback(() => {
    setIsFileModelOpen(false);
  }, []);

  const onChangeTaskListType = useCallback(
    async (listType: number) => {
      const filterOrderLocal = await getUserPreferenceFieldData('tasks_filter_type');
      const currentProjectFilter = filterOrderLocal?.[params?.id];
      const getOrderType = () => {
        if (currentProjectFilter?.order === SHOW_TASK_BY_ORDER.DUE_DATE && listType === TASK_LIST_TYPE.CALANDAR_VIEW)
          return SHOW_TASK_BY_ORDER.PRIORITY;
        if (currentProjectFilter?.order === SHOW_TASK_BY_ORDER.CUSTOM && listType === TASK_LIST_TYPE.CALANDAR_VIEW)
          return SHOW_TASK_BY_ORDER.CREATED_DATE;
        return currentProjectFilter?.order;
      };
      const orderType = getOrderType();
      let orderByName: string;
      if (orderType === SHOW_TASK_BY_ORDER.PRIORITY) {
        orderByName = 'Priority';
      } else if (orderType === SHOW_TASK_BY_ORDER.DUE_DATE) {
        orderByName = 'Due Date';
      } else if (orderType === SHOW_TASK_BY_ORDER.CREATED_DATE) {
        orderByName = 'Created Date';
      } else if (orderType === SHOW_TASK_BY_ORDER.CUSTOM) {
        orderByName = 'Custom';
      } else {
        orderByName = 'Due Date';
      }
      filterOrderLocal[params?.id] = {
        ...currentProjectFilter,
        viewType: listType,
        order: orderType
      };
      await updateUserPreference({ tasks_filter_type: filterOrderLocal });
      dispatch(updateTaskFilter({ propsName: 'taskViewType', value: listType || TASK_LIST_TYPE.TASKS }));
      dispatch(updateTaskFilter({ propsName: 'orderByName', value: orderByName }));
      dispatch(
        updateTaskFilter({
          propsName: 'orderByStatus',
          value: orderType >= 0 ? orderType : SHOW_TASK_BY_ORDER.DUE_DATE
        })
      );
      if (props.loadTaskList) props.loadTaskList();
    },
    [dispatch, params?.id, props]
  );

  const openEditPojectModal = useCallback(() => {
    dispatch(setProjectDetail(currentProject));
    setIsOpenCreatePojectModal(true);
  }, [currentProject, dispatch]);

  const closeEditPojectModal = useCallback(() => {
    setIsOpenCreatePojectModal(false);
    dispatch(clearProjectDetail());
  }, [dispatch]);

  const CancelModel = useCallback(() => {
    if (!isEmpty(name) || !isEmpty(customer)) {
      setConfirmationModalOpen(true);
    } else {
      setIsOpenCreatePojectModal(false);
      dispatch(clearProjectDetail());
    }
  }, [customer, dispatch, name]);

  const closeEditModal = () => {
    setConfirmationModalOpen(false);
    setIsOpenCreatePojectModal(false);
    dispatch(clearProjectDetail());
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const convertBase64 = useCallback((file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const item = {
          fileContent: fileReader.result,
          fileType: file?.type,
          name: file?.name,
          size: file?.size,
          file: file
        };
        resolve(item);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }, []);

  const handleFileRead = useCallback(
    async (file: File) => {
      if (file?.size > 2.5e8) {
        dispatch(setErrorMessage('Please upload less than 250 MB file.'));
        return false;
      }
      const newPromise = convertBase64(file);
      try {
        const value = await Promise.all([newPromise]);
        return value[0];
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [convertBase64, dispatch]
  );

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const files = event.target.files;
        if (props?.setUploadFile) {
          props?.setUploadFile(true);
        }
        if (!files) return;
        // Convert the FileList to an array
        const filesArray = Array.from(files);
        // Use handleFileRead for each file to prepare file data
        const filePromises = filesArray.map((file) => handleFileRead(file));
        const fileDataArray: any[] = await Promise.all(filePromises);

        // Log fileDataArray to verify the data for multiple files
        if (props?.setUploadedFileDetail) {
          props?.setUploadedFileDetail(fileDataArray);
        }

        if (!isEmpty(fileDataArray)) {
          // Upload files to Firebase
          const fileUrls = await getFirebaseUrlsFromFiles(fileDataArray, 'files/', dispatch);
          const payloads = fileUrls?.map((fileUrl, index) => ({
            FileType: fileDataArray[index]?.fileType,
            IsSystemFile: true,
            Name: fileDataArray[index]?.name,
            ParentId: folderId ? folderId : null,
            ProjectId: params?.id,
            Size: Number(fileDataArray[index]?.size),
            UploadFileType: FILE_TYPES.UPLOAD,
            Url: fileUrl
          }));
          // Dispatch each payload
          for (const payload of payloads) {
            await dispatch(addFileData(payload));
          }
          if (props?.setUploadFile) {
            props?.setUploadFile(false);
          }
          if (props?.setUploadedFileDetail) {
            props?.setUploadedFileDetail({});
          }
          dispatch(setSuccessMessage('File uploaded successfully!'));
          if (props?.loadFilesData) props?.loadFilesData();
          event.target.value = '';
        }
      } catch (error) {
        console.error('Error uploading files:', error);
        dispatch(setErrorMessage('Error uploading files'));
      }
    },
    [dispatch, folderId, handleFileRead, params?.id, props]
  );

  const handleTaskCompletedByRecency = useCallback(
    async (value: boolean) => {
      dispatch(updateTaskFilter({ propsName: 'showRecentlyCompletedTask', value: value }));
      const filterOptionLocal = (await getUserPreferenceFieldData('tasks_filter_type')) || {};
      const currentProjectFilter = filterOptionLocal?.[params?.id];
      filterOptionLocal[params?.id] = {
        ...(currentProjectFilter || {}),
        showRecentlyCompletedTask: value
      };
      await updateUserPreference({ tasks_filter_type: filterOptionLocal });
      if (props.loadTaskList) props.loadTaskList();
    },
    [dispatch, params?.id, props]
  );

  const onChangeCompletedTaskViewDate = useCallback(
    async (key: number | string) => {
      key = Number(key);
      dispatch(updateTaskFilter({ propsName: 'showCompletedTaskByFilter', value: key }));
      const filterOptionLocal = (await getUserPreferenceFieldData('tasks_filter_type')) || {};
      const currentProjectFilter = filterOptionLocal?.[params?.id];
      filterOptionLocal[params?.id] = {
        ...(currentProjectFilter || {}),
        showCompletedTask: key
      };
      await updateUserPreference({ tasks_filter_type: filterOptionLocal });
      if (props.loadTaskList) props.loadTaskList();
    },
    [dispatch, params?.id, props]
  );

  const onChangeGroupingFilter = useCallback(
    async (key: string | number) => {
      key = Number(key);
      let tasks: string | null;
      if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) {
        tasks = 'Status';
      } else if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP) {
        tasks = 'Group';
      } else if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY) {
        tasks = 'Priority';
      } else if (key === SHOW_TASK_GROUPING_TYPE.NO_GROUPING) {
        tasks = 'No Grouping';
      } else if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER) {
        tasks = 'User';
      } else if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE) {
        tasks = 'Milestone';
      } else {
        tasks = '';
      }
      dispatch(updateTaskFilter({ propsName: 'groupName', value: tasks }));
      const filterGroupLocal = await getUserPreferenceFieldData('tasks_filter_type');
      const currentProjectFilter = filterGroupLocal?.[params?.id];
      const getOrderBasedOnGrouping = () => {
        if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP || key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS)
          return SHOW_TASK_BY_ORDER.CUSTOM;
        if (currentProjectFilter?.order === SHOW_TASK_BY_ORDER.CUSTOM) return SHOW_TASK_BY_ORDER.CREATED_DATE;
        return currentProjectFilter?.order;
      };
      if (
        key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP ||
        key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS ||
        (key !== SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP &&
          key !== SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS &&
          currentProjectFilter?.order === SHOW_TASK_BY_ORDER.CUSTOM)
      ) {
        dispatch(
          updateTaskFilter({
            propsName: 'orderByName',
            value:
              key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP || key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS
                ? 'Custom'
                : 'Created Date'
          })
        );
        dispatch(
          updateTaskFilter({
            propsName: 'orderByStatus',
            value:
              key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP || key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS
                ? SHOW_TASK_BY_ORDER.CUSTOM
                : SHOW_TASK_BY_ORDER.CREATED_DATE
          })
        );
      }
      filterGroupLocal[params?.id] = {
        ...(currentProjectFilter || {}),
        grouping: key,
        order: getOrderBasedOnGrouping()
      };
      await updateUserPreference({ tasks_filter_type: filterGroupLocal });
      dispatch(
        updateTaskFilter({
          propsName: 'groupingStatus',
          value: key >= 0 ? key : SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP
        })
      );
      if (key === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) {
        await handleTaskCompletedByRecency(true);
        await onChangeCompletedTaskViewDate(SHOW_COMPLETED_TASK_BY_DATE.LAST_MONTH);
      }
      if (props?.otherTaskList) props.otherTaskList();
    },
    [dispatch, handleTaskCompletedByRecency, onChangeCompletedTaskViewDate, params?.id, props]
  );

  const onChangeOrderByFilter = useCallback(
    async (key: string | number) => {
      key = Number(key);
      let tasks: string | null;
      if (key === SHOW_TASK_BY_ORDER.CUSTOM) {
        tasks = 'Custom';
      } else if (key === SHOW_TASK_BY_ORDER.PRIORITY) {
        tasks = 'Priority';
      } else if (key === SHOW_TASK_BY_ORDER.DUE_DATE) {
        tasks = 'Due Date';
      } else if (key === SHOW_TASK_BY_ORDER.CREATED_DATE) {
        tasks = 'Created Date';
      } else {
        tasks = 'Due Date';
      }
      dispatch(updateTaskFilter({ propsName: 'orderByName', value: tasks }));
      const filterOrderLocal = await getUserPreferenceFieldData('tasks_filter_type');
      const currentProjectFilter = filterOrderLocal?.[params?.id];
      filterOrderLocal[params?.id] = { ...(currentProjectFilter || {}), order: key };
      await updateUserPreference({ tasks_filter_type: filterOrderLocal });
      dispatch(updateTaskFilter({ propsName: 'orderByStatus', value: key >= 0 ? key : SHOW_TASK_BY_ORDER.DUE_DATE }));
      if (props?.otherTaskList) props.otherTaskList();
    },
    [dispatch, params?.id, props]
  );

  const onClickProjectSetting = useCallback(() => {
    history.push(`/projects/details/${params?.id}/setting`);
  }, [history, params?.id]);

  const onChangeFilesListType = useCallback(
    async (listType: number) => {
      const filterGroupLocal = await getUserPreferenceFieldData('tasks_filter_type');
      const currentProjectFilter = filterGroupLocal?.[params?.id];
      filterGroupLocal[params?.id] = { ...(currentProjectFilter || {}), files: listType };
      dispatch(setFilesListType(listType));
    },
    [dispatch, params?.id]
  );

  const handleCheckboxChange = useCallback(async () => {
    const newShowSubTask = !taskFilter?.showSubTask;
    dispatch(updateTaskFilter({ propsName: 'showSubTask', value: newShowSubTask }));
    let filterOrderLocal = await getUserPreferenceFieldData('tasks_filter_type');
    const currentProjectFilter = filterOrderLocal?.[params?.id];
    filterOrderLocal[params?.id] = {
      ...(currentProjectFilter || {}),
      showSubTask: newShowSubTask
    };
    await updateUserPreference({ tasks_filter_type: filterOrderLocal });
    if (props?.otherTaskList) props.otherTaskList();
  }, [dispatch, params?.id, props, taskFilter?.showSubTask]);

  const handleEmptyGroup = useCallback(async () => {
    const newShowEmptyGroup = !taskFilter?.showEmptyGroup;
    dispatch(updateTaskFilter({ propsName: 'showEmptyGroup', value: newShowEmptyGroup }));
    let filterOrderLocal = await getUserPreferenceFieldData('tasks_filter_type');
    const currentProjectFilter = filterOrderLocal?.[params?.id];
    filterOrderLocal[params?.id] = {
      ...(currentProjectFilter || {}),
      showEmptyGroup: newShowEmptyGroup
    };
    await updateUserPreference({ tasks_filter_type: filterOrderLocal });
  }, [dispatch, params?.id, taskFilter?.showEmptyGroup]);

  const handleClick = useCallback(
    (key: number | string) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        openEditPojectModal();
      } else if (key === MENU_OPTIONS.SETTING) {
        onClickProjectSetting();
      } else if (key === MENU_OPTIONS.ARCHIVE) {
        Openarchivemodel();
      }
    },
    [onClickProjectSetting, openEditPojectModal]
  );

  const getGroupName = useCallback(() => {
    if (!isEmpty(taskFilter?.groupName)) return taskFilter?.groupName;
    if (currentProject?.isGroupEnabled) return 'Group';
    return 'No Grouping';
  }, [taskFilter?.groupName, currentProject?.isGroupEnabled]);

  const renderDropdwon = () => {
    return (
      <>
        {menuItems.map((item) => (
          <DropdownItem
            label={item.label}
            key={item?.key}
            tone={item.label === 'Archive' ? 'critical' : 'primary'}
            onClick={() => handleClick(item.key)}
          />
        ))}
      </>
    );
  };

  const groupmenuItems = [
    { key: SHOW_TASK_GROUPING_TYPE.NO_GROUPING, label: 'No Grouping' },
    currentProject?.isGroupEnabled && { key: SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP, label: 'Group' },
    currentProject?.priority?.enabled && { key: SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY, label: 'Priority' },
    { key: SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER, label: 'User' },
    currentProject?.isMilestone && { key: SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE, label: 'Milestone' },
    currentProject?.statusEnable &&
      currentProject?.statusData?.length > 0 && { key: SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS, label: 'Status' }
  ].filter((item): item is { key: number; label: string } => Boolean(item));

  const groupingDropdown = useMemo(() => {
    return groupmenuItems.map((item) => (
      <DropdownItem key={item?.key} label={item.label} onClick={() => onChangeGroupingFilter(item.key)} />
    ));
  }, [groupmenuItems, onChangeGroupingFilter]);

  const onGroupButtonClick = useCallback(() => {
    setIsOpenGroupmenu(!isOpenGroupmenu);
  }, [isOpenGroupmenu]);

  const completedDropdown = useMemo(() => {
    return completedTaskMenu.map((item) => (
      <DropdownItem key={item.key} label={item.label} onClick={() => onChangeCompletedTaskViewDate(item.key)} />
    ));
  }, [onChangeCompletedTaskViewDate]);

  const onTaskButtonClick = useCallback(() => {
    setIsOpenCompletedTaskDropdown(!isOpenCompletedTaskDropdown);
  }, [isOpenCompletedTaskDropdown]);

  // get ordering menu items for filtering
  const orderingMenuItems = useCallback(() => {
    const items = [];

    // Add 'Custom' option if grouping status matches certain types and view type is not 'Calendar View'.
    if (
      (taskFilter?.groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS ||
        taskFilter?.groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP ||
        taskFilter?.groupingStatus === SHOW_TASK_GROUPING_TYPE.DEFAULT) &&
      taskFilter?.taskViewType !== TASK_LIST_TYPE.CALANDAR_VIEW
    ) {
      items.push({ key: SHOW_TASK_BY_ORDER.CUSTOM, label: 'Custom' });
    }

    // Add 'Priority' option if priority is enabled.
    if (currentProject?.priority?.enabled) {
      items.push({ key: SHOW_TASK_BY_ORDER.PRIORITY, label: 'Priority' });
    }

    // Add 'Due Date' option if view type is not 'Calendar View'.
    if (taskFilter?.taskViewType !== TASK_LIST_TYPE.CALANDAR_VIEW) {
      items.push({ key: SHOW_TASK_BY_ORDER.DUE_DATE, label: 'Due Date' });
    }

    // Always include 'Created Date'.
    items.push({ key: SHOW_TASK_BY_ORDER.CREATED_DATE, label: 'Created Date' });

    return items;
  }, [currentProject?.priority?.enabled, taskFilter?.groupingStatus, taskFilter?.taskViewType]);

  const orderingMenuDropdown = useMemo(() => {
    const orderingMenuData = orderingMenuItems();
    return orderingMenuData.map((item) => (
      <DropdownItem key={item.key} label={item.label} onClick={() => onChangeOrderByFilter(item.key)} />
    ));
  }, [onChangeOrderByFilter, orderingMenuItems]);

  const onButtonClickOrdering = useCallback(() => {
    setIsOrderDropdownOpen(!isOrderDropdownOpen);
  }, [isOrderDropdownOpen]);

  const handleFileClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    document.getElementById('upload')?.click();
  };

  const renderDropdownItems = useMemo(() => {
    return (
      <>
        <DropdownItem
          iconName='filescreen-dropdown-file-icon'
          iconSize={16}
          label='Start a new doc'
          onClick={() => openFileModal(LINK_FILE_DATA.DOCS)}
          iconTone='fill'
        />
        <DropdownItem
          iconName='white-board-icon'
          iconSize={16}
          label='Whiteboard'
          onClick={() => openFileModal(LINK_FILE_DATA.WHITEBOARD)}
          iconTone='fill'
        />
        <DropdownItem
          iconName='filescreen-dropdown-folder-icon'
          iconSize={16}
          label='Make a new folder'
          onClick={() => openFileModal(LINK_FILE_DATA.FOLDER)}
          iconTone='fill'
        />
        <DropdownItem
          iconName='upload-icon'
          iconSize={16}
          label='Upload files'
          iconTone='fill'
          onClick={(e?: React.MouseEvent) => handleFileClick(e as React.MouseEvent)}
        />
        <DropdownDivider />
        <TextBlock>Or link to files from …</TextBlock>
        <DropdownItem
          iconName='filescreen-google-drive-icon'
          iconSize={16}
          label='Google Drive'
          onClick={() => openLinkFileModal(LINK_FILE_DATA.DRIVE)}
        />
        <DropdownItem
          iconName='filescreen-dropbox-icon'
          iconSize={16}
          label='Dropbox'
          onClick={() => openLinkFileModal(LINK_FILE_DATA.DROPBOX)}
        />
        <DropdownItem
          iconName='filescreen-onedrive-icon'
          iconSize={16}
          label='Onedrive'
          onClick={() => openLinkFileModal(LINK_FILE_DATA.ONEDRIVE)}
        />
        <DropdownItem
          iconName='notion-icon-16x16'
          iconSize={16}
          label='Notion'
          onClick={() => openLinkFileModal(LINK_FILE_DATA.NOTION)}
        />
        <DropdownItem
          iconName='filescreen-figma-icon'
          iconSize={16}
          label='Figma'
          onClick={() => openLinkFileModal(LINK_FILE_DATA.FIGMA)}
        />
      </>
    );
  }, [openFileModal, openLinkFileModal]);

  return (
    <>
      <Maincontent>
        <Container style={{}} isLeftPadding={props?.isLeftPadding}>
          <Content>
            <StarWithTitle>
              {!window.location.hostname.startsWith('embed') && <Title className='web'>{currentProject?.name}</Title>}
            </StarWithTitle>
            <Buttonblock>
              {isEmpty(workspace?.customerId) && !workspace?.customerRole && (
                <Taskbutton
                  isActive={isOverview}
                  onClick={() => history.push(`/projects/details/${params?.id}/overview`)}>
                  Overview
                </Taskbutton>
              )}
              <Taskbutton isActive={isTask} onClick={() => history.push(`/projects/details/${params?.id}/tasks`)}>
                Tasks
              </Taskbutton>
              {currentProject?.isMilestone && (
                <Taskbutton
                  isActive={isMilestone}
                  onClick={() => history.push(`/projects/details/${params?.id}/milestone`)}>
                  Milestones
                </Taskbutton>
              )}
              <Taskbutton isActive={isFile} onClick={() => history.push(`/projects/details/${params?.id}/files`)}>
                Files
              </Taskbutton>
              <Taskbutton
                isActive={isMessages}
                onClick={() => history.push(`/projects/details/${params?.id}/messages`)}>
                Messages
              </Taskbutton>
              {(workspace?.isAdmin || workspace?.isOwner) && (
                <Dropdown
                  content={
                    <ThreeDotIcon>
                      <Icon>
                        <SVGIcon
                          className='svgicon'
                          name='three-dots-horizontal-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        />
                      </Icon>
                    </ThreeDotIcon>
                  }
                  trigger='click'>
                  {renderDropdwon()}
                </Dropdown>
              )}
            </Buttonblock>
          </Content>
          <ResponsiveContent>
            <ResponsiveTopNavbar>
              <ResponsiveNavbarWithTitle>
                <Responsivnavbar backNavigationPath='/home' />
                {!window.location.hostname.startsWith('embed') && (
                  <Title className='reponsivetitle'>{currentProject?.name}</Title>
                )}
              </ResponsiveNavbarWithTitle>
              {isTask && (
                <>
                  <Taskicons>
                    <TaskFilterCustomDropdown
                      trigger={
                        <Filter>
                          <SVGIcon
                            name='filters-icon'
                            width='26'
                            height='26'
                            viewBox='0 0 26 26'
                            fill='none'
                            className='filter'
                          />
                          <SVGIcon
                            name='filter-responsive-icon'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            className='responsive-filter'
                          />
                        </Filter>
                      }
                      content={
                        <TaskDropdown>
                          <Taskdrop istasklist>
                            <Data>
                              <Options>
                                <SameIcon
                                  isCardActive={
                                    taskFilter?.taskViewType === TASK_LIST_TYPE.TASKS ||
                                    isEmpty(taskFilter?.taskViewType) ||
                                    !taskFilter?.taskViewType
                                  }
                                  onClick={() => onChangeTaskListType(TASK_LIST_TYPE.TASKS)}>
                                  <DIV>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='three-parallel-line-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 14 14'
                                        className='line-fill-color'
                                      />
                                    </SVGDiv>
                                    <TaskListName
                                      isCardActive={
                                        taskFilter?.taskViewType === TASK_LIST_TYPE.TASKS ||
                                        isEmpty(taskFilter?.taskViewType) ||
                                        !taskFilter?.taskViewType
                                      }>
                                      List
                                    </TaskListName>
                                  </DIV>
                                </SameIcon>
                                <SameIcon
                                  isCardActive={taskFilter?.taskViewType === TASK_LIST_TYPE.TASK_GRID}
                                  onClick={() => onChangeTaskListType(TASK_LIST_TYPE.TASK_GRID)}>
                                  <DIV>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='layout-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 14 14'
                                        className='fill-color'
                                      />
                                    </SVGDiv>
                                    <TaskListName isCardActive={taskFilter?.taskViewType === TASK_LIST_TYPE.TASK_GRID}>
                                      Grid
                                    </TaskListName>
                                  </DIV>
                                </SameIcon>
                                <SameIcon
                                  isCardActive={taskFilter?.taskViewType === TASK_LIST_TYPE.CALANDAR_VIEW}
                                  onClick={() => onChangeTaskListType(TASK_LIST_TYPE.CALANDAR_VIEW)}>
                                  <DIV>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='calendar-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 18 18'
                                        className='calendar-fill-color'
                                      />
                                    </SVGDiv>
                                    <TaskListName
                                      isCardActive={taskFilter?.taskViewType === TASK_LIST_TYPE.CALANDAR_VIEW}>
                                      Calendar
                                    </TaskListName>
                                  </DIV>
                                </SameIcon>
                              </Options>
                            </Data>
                            <TaskFilterDiv>
                              {taskFilter?.taskViewType !== TASK_LIST_TYPE.CALANDAR_VIEW && (
                                <Data>
                                  <GroupDiv>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='group-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 16 16'
                                        fill='none'
                                      />
                                    </SVGDiv>
                                    <DropText>Grouping</DropText>
                                  </GroupDiv>

                                  <Dropdown
                                    content={
                                      <Status onClick={onGroupButtonClick}>
                                        <p>{getGroupName()}</p>
                                        <SVGIcon
                                          name='dropdown-icon'
                                          width='20'
                                          height='20'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          className={isOpenGroupmenu ? 'dropdown-icon fill-color' : 'fill-color'}
                                        />
                                      </Status>
                                    }
                                    trigger='click'>
                                    {groupingDropdown}
                                  </Dropdown>
                                </Data>
                              )}
                              <Data>
                                <GroupDiv>
                                  <SVGDiv>
                                    <SVGIcon
                                      name='small-filters-icon'
                                      width='14'
                                      height='14'
                                      viewBox='0 0 14 14'
                                      className='filters-icon-color'
                                    />
                                  </SVGDiv>
                                  <DropText>Ordering</DropText>
                                </GroupDiv>
                                <Dropdown
                                  content={
                                    <Status onClick={onButtonClickOrdering}>
                                      <p>{!isEmpty(taskFilter?.orderByName) ? taskFilter?.orderByName : 'Due Date'}</p>
                                      <SVGIcon
                                        name='dropdown-icon'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        className={isOrderDropdownOpen ? 'dropdown-icon fill-color' : 'fill-color'}
                                      />
                                    </Status>
                                  }
                                  trigger='click'>
                                  {orderingMenuDropdown}
                                </Dropdown>
                              </Data>

                              <Hiddennow>
                                <Data>
                                  <DropText>Ordering</DropText>
                                  <Select
                                    style={{ width: 90 }}
                                    className='styles'
                                    showSearch
                                    suffixIcon={
                                      <SVGIcon
                                        name='dropdown-icon'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                      />
                                    }
                                    placeholder='Priority'
                                    options={[
                                      {
                                        value: '1',
                                        label: 'High'
                                      },
                                      {
                                        value: '2',
                                        label: 'Low'
                                      },
                                      {
                                        value: '3',
                                        label: 'Medium'
                                      },
                                      {
                                        value: '4',
                                        label: 'urgent'
                                      }
                                    ]}
                                  />
                                </Data>
                              </Hiddennow>
                              <Hiddennow>
                                <Property>
                                  <p>Display Property</p>
                                  <DisplayProperty>
                                    <TaskBox>Priority</TaskBox>
                                    <StatusBox>Status</StatusBox>
                                    <TaskBox>Due Date</TaskBox>
                                    <TaskBox>Estimate</TaskBox>
                                    <TaskBox>Assignee</TaskBox>
                                  </DisplayProperty>
                                </Property>
                              </Hiddennow>
                            </TaskFilterDiv>
                          </Taskdrop>
                          <TaskFilterDiv>
                            {((taskFilter?.groupingStatus === SHOW_TASK_GROUPING_TYPE.DEFAULT &&
                              currentProject?.statusEnable) ||
                              taskFilter?.groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) && (
                              <Taskdrop istasklist>
                                <Data>
                                  <GroupDiv>
                                    <DropText>Task completed by recency</DropText>
                                  </GroupDiv>
                                  <Switch>
                                    <label className='switch'>
                                      <input
                                        type='checkbox'
                                        checked={taskFilter?.showRecentlyCompletedTask}
                                        onChange={() =>
                                          handleTaskCompletedByRecency(!taskFilter?.showRecentlyCompletedTask)
                                        }
                                      />
                                      <span className='slider round'></span>
                                    </label>
                                  </Switch>
                                </Data>
                                {taskFilter?.taskViewType !== TASK_LIST_TYPE.CALANDAR_VIEW && (
                                  <Data>
                                    <GroupDiv>
                                      <DropText>Completed tasks</DropText>
                                    </GroupDiv>

                                    <Dropdown
                                      content={
                                        <Status onClick={onTaskButtonClick}>
                                          <p>
                                            {SHOW_COMPLETED_TASK_FILTER_NAME[taskFilter?.showCompletedTaskByFilter]}
                                          </p>
                                          <SVGIcon
                                            name='dropdown-icon'
                                            width='20'
                                            height='20'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            className={
                                              isOpenCompletedTaskDropdown ? 'dropdown-icon fill-color' : 'fill-color'
                                            }
                                          />
                                        </Status>
                                      }>
                                      {completedDropdown}
                                    </Dropdown>
                                  </Data>
                                )}
                              </Taskdrop>
                            )}
                            <Taskdrop>
                              <Data>
                                <GroupDiv>
                                  <DropText>Show sub-tasks</DropText>
                                </GroupDiv>
                                <Switch>
                                  <label className='switch'>
                                    <input
                                      type='checkbox'
                                      checked={taskFilter?.showSubTask}
                                      onChange={handleCheckboxChange}
                                    />
                                    <span className='slider round'></span>
                                  </label>
                                </Switch>
                              </Data>
                              {taskFilter?.taskViewType !== TASK_LIST_TYPE.CALANDAR_VIEW && (
                                <Data>
                                  <GroupDiv>
                                    <DropText>Show empty groups</DropText>
                                  </GroupDiv>
                                  <Switch>
                                    <label className='switch'>
                                      <input
                                        type='checkbox'
                                        checked={taskFilter?.showEmptyGroup}
                                        onChange={handleEmptyGroup}
                                      />
                                      <span className='slider round'></span>
                                    </label>
                                  </Switch>
                                </Data>
                              )}
                            </Taskdrop>
                          </TaskFilterDiv>
                        </TaskDropdown>
                      }
                    />

                    <LastIcon onClick={createTaskModel}>
                      <SVGIcon name='task-plus-icon' width='26' height='26' viewBox='0 0 32 33' className='circle' />
                    </LastIcon>
                  </Taskicons>
                </>
              )}

              {isFile && (
                <Taskicons>
                  <TaskFilterCustomDropdown
                    trigger={
                      <Filter>
                        <SVGIcon
                          name='filters-icon'
                          width='26'
                          height='26'
                          viewBox='0 0 26 26'
                          fill='none'
                          className='filter'
                        />
                        <SVGIcon
                          name='filter-responsive-icon'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          className='responsive-filter'
                        />
                      </Filter>
                    }
                    content={
                      <TaskDropdown>
                        <Taskdrop iswidth={true}>
                          <Data>
                            <Options>
                              <SameIcon
                                className='files-filter'
                                isCardActive={filesViewListType === FILES_LIST_TYPE.GRID || isEmpty(filesViewListType)}
                                onClick={() => onChangeFilesListType(FILES_LIST_TYPE.GRID)}>
                                <DIV>
                                  <SVGDiv>
                                    <SVGIcon
                                      name='layout-icon'
                                      width='14'
                                      height='14'
                                      viewBox='0 0 14 14'
                                      className='fill-color'
                                    />
                                  </SVGDiv>
                                  <TaskListName
                                    isCardActive={
                                      filesViewListType === FILES_LIST_TYPE.GRID || isEmpty(filesViewListType)
                                    }>
                                    Grid
                                  </TaskListName>
                                </DIV>
                              </SameIcon>
                              <SameIcon
                                className='files-filter'
                                isCardActive={filesViewListType === FILES_LIST_TYPE.LIST}
                                onClick={() => onChangeFilesListType(FILES_LIST_TYPE.LIST)}>
                                <DIV>
                                  <SVGDiv>
                                    <SVGIcon
                                      name='three-parallel-line-icon'
                                      width='14'
                                      height='14'
                                      viewBox='0 0 14 14'
                                      className='line-fill-color'
                                    />
                                  </SVGDiv>
                                  <TaskListName isCardActive={filesViewListType === FILES_LIST_TYPE.LIST}>
                                    List
                                  </TaskListName>
                                </DIV>
                              </SameIcon>
                            </Options>
                          </Data>
                        </Taskdrop>
                      </TaskDropdown>
                    }
                  />
                  {/* <AntdDropdown
                    trigger={['click']}
                    placement='bottomLeft'
                    dropdownRender={() => (
                      <>
                        <TaskDropdown>
                          <Taskdrop iswidth={true}>
                            <Data>
                              <Options>
                                <SameIcon
                                  className='files-filter'
                                  isCardActive={
                                    filesViewListType === FILES_LIST_TYPE.GRID || isEmpty(filesViewListType)
                                  }
                                  onClick={() => onChangeFilesListType(FILES_LIST_TYPE.GRID)}>
                                  <DIV>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='layout-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 14 14'
                                        className='fill-color'
                                      />
                                    </SVGDiv>
                                    <TaskListName
                                      isCardActive={
                                        filesViewListType === FILES_LIST_TYPE.GRID || isEmpty(filesViewListType)
                                      }>
                                      Grid
                                    </TaskListName>
                                  </DIV>
                                </SameIcon>
                                <SameIcon
                                  className='files-filter'
                                  isCardActive={filesViewListType === FILES_LIST_TYPE.LIST}
                                  onClick={() => onChangeFilesListType(FILES_LIST_TYPE.LIST)}>
                                  <DIV>
                                    <SVGDiv>
                                      <SVGIcon
                                        name='three-parallel-line-icon'
                                        width='14'
                                        height='14'
                                        viewBox='0 0 14 14'
                                        className='line-fill-color'
                                      />
                                    </SVGDiv>
                                    <TaskListName isCardActive={filesViewListType === FILES_LIST_TYPE.LIST}>
                                      List
                                    </TaskListName>
                                  </DIV>
                                </SameIcon>
                              </Options>
                            </Data>
                          </Taskdrop>
                        </TaskDropdown>
                      </>
                    )}>
                    <Filter>
                      <SVGIcon
                        name='filters-icon'
                        width='26'
                        height='26'
                        viewBox='0 0 26 26'
                        fill='none'
                        className='filter'
                      />
                      <SVGIcon
                        name='filter-responsive-icon'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        className='responsive-filter'
                      />
                    </Filter>
                  </AntdDropdown> */}
                  <Dropdown
                    isMinWidth={196}
                    isRemoveMaxHeight={true}
                    content={
                      <LastIcon>
                        <SVGIcon name='task-plus-icon' width='26' height='26' viewBox='0 0 32 33' className='circle' />
                      </LastIcon>
                    }
                    trigger='click'>
                    {renderDropdownItems}
                  </Dropdown>
                  <input type='file' id='upload' hidden multiple onChange={onFileUpload} />
                </Taskicons>
              )}

              {isMilestone && (
                <PlusButton onClick={OpenMilestoneModal}>
                  <SVGIcon name='task-plus-icon' width='26' height='26' viewBox='0 0 32 33' className='circle' />
                </PlusButton>
              )}
            </ResponsiveTopNavbar>
          </ResponsiveContent>
        </Container>
      </Maincontent>
      <ModalCustom open={openTaskModel} onClose={closeTaskModel} isCreateTask={true}>
        <CreateTaskModal loadData={props?.loadTaskList} onCancel={closeTaskModel} />
      </ModalCustom>
      <ModalCustom open={openArchiveModal} onClose={Closearchivemodel} width={334}>
        <Deletemodal
          onClose={Closearchivemodel}
          onOk={archive}
          isArchive={true}
          loading={loading}
          modaltitle='Archive Project'
          description='Confirming archive? You can restore it later if needed.'
        />
      </ModalCustom>
      <ModalCustom open={isFileModalOpen} onClose={closeFileModel} width={462}>
        <FilesModal
          onClose={closeFileModel}
          modalData={modalData}
          folderId={folderId}
          loadFilesData={props?.loadFilesData && props.loadFilesData}
        />
      </ModalCustom>
      <ModalCustom open={isLinkModalOpen} onClose={closeLinkFileModal} width={462}>
        <LinkFileModal
          onClose={closeLinkFileModal}
          modalData={modalData}
          loadFilesData={props.loadFilesData}
          accounting={true}
        />
      </ModalCustom>
      <ModalCustom open={isOpenCreatePojectModal} onClose={CancelModel} width={632}>
        <Modalhead>
          <Headline>Edit Project</Headline>
          <Icon onClick={CancelModel}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
          </Icon>
        </Modalhead>
        <CreateNewProjectModal
          handleCloseModal={closeEditPojectModal}
          onClickCancel={CancelModel}
          loadProjectData={() => loadData(true)}
        />
      </ModalCustom>
      <ModalCustom width={462} open={isOpenMilestoneModal} onClose={isCloseModal}>
        <Milestonemodal onClose={isCloseModal} projectId={params?.id} />
      </ModalCustom>
      <ModalCustom open={confirmationModalOpen} onClose={closeConfirmationModal} width={334}>
        <Deletemodal
          isYes={true}
          onClose={closeConfirmationModal}
          onOk={closeEditModal}
          modaltitle='Confirmation Model'
          description='Are you sure you want close this model?'
        />
      </ModalCustom>
    </>
  );
};
export default Accounting;

import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Rightrow, ButtonBlock, Row1, Icons } from './styles';
import Avatar from '../../avatar/Avatar';
import { isEmpty } from 'lodash';
import { Box, Leftrow, Row, Subtitle, Title } from '../style';

interface Props {
  currentProject: any;
  setMainModel: (value: boolean) => void;
  OpenDeleteMemberModel: (id: string) => void;
}

const TeamsSettingCard: React.FC<Props> = (props) => {
  const { currentProject, setMainModel, OpenDeleteMemberModel } = props;
  return (
    <Box>
      <Row>
        <Leftrow>
          <Title>Team</Title>
          <Subtitle>Manage who is member of this project</Subtitle>
        </Leftrow>
        <Rightrow onClick={() => setMainModel(true)}>
          <p>Manage</p>
        </Rightrow>
      </Row>
      <Row1>
        {currentProject?.users &&
          currentProject?.users?.map((item: any) => {
            //Remove any
            return (
              !isEmpty(item?.id) && (
                <ButtonBlock key={item?.id}>
                  <Avatar
                    imgSrc={item?.profile_photo || ''}
                    name={item?.name ? item?.name : 'U N'}
                    size={24}
                    isNotBorder
                  />
                  <p>{item?.name}</p>
                  <Icons onClick={() => OpenDeleteMemberModel(item?.id)}>
                    <SVGIcon name='cross-icon' width='10' height='10' viewBox='0 0 10 10' className='closeicon' />
                  </Icons>
                </ButtonBlock>
              )
            );
          })}
      </Row1>
    </Box>
  );
};

export default TeamsSettingCard;

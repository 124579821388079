/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  Btns,
  Icon,
  Icons,
  Modalhead,
  Modalheadleft,
  Textfied,
  Inputs,
  Detail,
  DescriptionSection,
  OverLayDiv,
  Textarea,
  Headtitle,
  ButtonDiv,
  AttachFile,
  Uploaddiv,
  DocumentIcon
} from './style';
import AssignBox from '../../dropdowns/assignPopup';
import CalendarPopUp from '../../calendarPopUp';
import {
  isEmpty,
  getFloatTimeValue,
  convertBase64ToFile,
  getFirebaseUrlFromFile,
  handleFileUpload,
  isMediaRecordingSupported
} from '../../../helpers/common';
import {
  clearCreateTaskInput,
  updateCreateTaskInput,
  setProjectTaskGroupsList,
  updateMyTaskDetailsData,
  setCreateTaskInput
} from '../../../actions/taskActions';
import TextArea from 'antd/es/input/TextArea';
import Button from '../../button';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import Statusdropdown from '../../dropdowns/statusDropdown';
import Prioritydropdown from '../../dropdowns/priorityDropdown';
import { COMMENT_TYPE, FILE_TYPES } from '../../../global/constants';
import { useHistory, useParams } from 'react-router-dom';
import { setErrorMessage } from '../../../actions/messageActions';
import {
  createNewComment,
  createNewTask,
  updateIndexDbProjectTasks,
  updateRecurringTaskDetails,
  updateTaskDetails
} from '../../../services/taskServices';
import Estimatedropdown from '../../dropdowns/estimatedropdown';
import ModalCustom from '../modal';
import Deletemodal from '../deleteModel';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { getMilestoneList, getProjectFiles } from '../../../services/projectServices';
import { captureException } from '../../../services/logService';
import { addTaskInProjectTemplate, updateTaskInProjectTemplate } from '../../../actions/settingActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { StatusListInterface } from '../../../interfaces/ProjectInterface';
import {
  CommentFilesInterface,
  TaskDetailsInterface,
  CreateTaskInputInterface
} from '../../../interfaces/TaskInterface';
import TaskStatusDropdown from '../../dropdowns/taskStatusDropdown';
import { setProjectFiles } from '../../../actions/projectActions';
import { useMobileDevice } from '../../../global/useMobile';
import RenderExistingFile from '../../renderExistingFile';
import { getTaskTemplateList } from '../../../services/settingServices';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';
import { LabelDropdown } from '../../dropdowns/labeldropdown/labelDropdownMenu';
import DescriptionInputBox from '../../inputBox/descriptionInputBox';
import { TASK_ANALYTICS } from '../../../global/analyticsConstants';
import SnapRecordModal from '../snapRecordModal';
import { Dropdown, DropdownItem } from '../../Dropdown';
import RenderProjectList from './RenderProjectList';
import CommentDropdown from '../../dropdowns/commentDropdown/commentdropdown';
import Drawer from '../../customDrawer/customDrawer';
type DropdownPlacementType =
  | 'bottomLeft'
  | 'bottom'
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomCenter'
  | 'bottomRight'
  | 'top';
interface Props {
  onCancel?: Function;
  loadData?: () => void;
  isFromShortcut?: boolean;
  isFromMyTask?: boolean;
  istaskDetail?: boolean;
  onProjectChange?: (value: boolean) => void;
  isTemplateProject?: boolean;
  isFromSubList?: boolean;
  DropdownPlacement?: DropdownPlacementType;
  isRecurringTask?: boolean;
  data?: TaskDetailsInterface;
  isTaskDetailPreview?: boolean;
}

interface LinkObject {
  href: string;
  name: string;
}
const CreateTaskModal: React.FC<Props> = (props) => {
  const {
    loadData,
    onCancel,
    isFromShortcut = false,
    istaskDetail,
    isFromMyTask = false,
    onProjectChange,
    isTemplateProject = false,
    isFromSubList = false,
    isRecurringTask,
    data,
    isTaskDetailPreview
  } = props;
  //Refs
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inlineRef = useRef<any>(null);
  //States
  const [loading, setLoading] = useState(false);
  const params: { id: string } = useParams();
  const [isEstimateDropdownOpen, setIsEstimateDropdownOpen] = useState(false);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [closeModel, setCloseModel] = useState(false);
  const history = useHistory();
  const [fileUploading, setFileUploading] = useState(false);
  const [selectFile, setSelectFile] = useState<boolean>(false);
  const [attachment, setAttachment] = useState(false);
  const [isSnapModalOpen, setIsSnapModalOpen] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    task: taskSelector,
    workspace: workspaceSelector
  } = stateSelector || {};
  const { currentProject, projectTaskMilestoneList, uploadFilePercent, projectAllFiles } = projectSelector;
  const { projectTemplateDetails, taskTemplateList } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { createTaskInput } = taskSelector;
  const { projectId } = createTaskInput;
  const { id: workspace_id } = workspace;
  //Other variable
  const [updatedFile, setUpdatedFile] = useState<CommentFilesInterface[]>(createTaskInput?.Files || []);
  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const loadMilestoneData = useCallback(async () => {
    if (createTaskInput?.projectId?.isMilestone && !isEmpty(workspace?.id)) {
      const milestoneList = await dispatch(getMilestoneList(createTaskInput?.projectId?.id, true));
      if (createTaskInput?.milestoneId) {
        const currentTaskMilestone = milestoneList?.find((x: any) => x?.id === createTaskInput?.milestoneId);
        await dispatch(updateCreateTaskInput({ propsName: 'milestoneId', value: currentTaskMilestone }));
      }
    }
  }, [
    createTaskInput?.milestoneId,
    createTaskInput?.projectId?.id,
    createTaskInput?.projectId?.isMilestone,
    dispatch,
    workspace?.id
  ]);

  const loadProjectfile = useCallback(async () => {
    try {
      await dispatch(getProjectFiles(currentProject?.id, currentProject?.users));
    } catch (error) {
      console.log('error', error);
    }
  }, [currentProject?.id, currentProject?.users, dispatch]);

  useEffect(() => {
    if (updatedFile && updatedFile.length > 0) {
      dispatch(
        updateCreateTaskInput({
          propsName: 'Files',
          value: updatedFile
        })
      );
    }
  }, [dispatch, updatedFile]);

  useEffect(() => {
    loadProjectfile();
  }, [loadProjectfile]);

  useEffect(() => {
    loadMilestoneData();
    return () => {
      dispatch(setProjectTaskGroupsList([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateCreateTaskInput({ propsName: 'start', value: null }));
    dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: null }));
    dispatch(getTaskTemplateList());
  }, [dispatch]);

  const onChangeInputField = useCallback(
    async (propsName: string, value: string) => {
      dispatch(updateCreateTaskInput({ propsName, value }));
    },
    [dispatch]
  );

  const clearData = useCallback(() => {
    if (!isEmpty(createTaskInput?.name) || !isEmpty(createTaskInput?.description) || !isEmpty(createTaskInput.Files)) {
      setCloseModel(true);
    } else {
      dispatch(clearCreateTaskInput());
      dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
      dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: [] }));
      if (onCancel) onCancel();
    }
  }, [createTaskInput.Files, createTaskInput?.description, createTaskInput?.name, dispatch, onCancel]);

  const close = useCallback(() => {
    setCloseModel(false);
    dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: [] }));
  }, [dispatch]);

  const onOnkDeleteModel = useCallback(() => {
    setCloseModel(false);
    dispatch(clearCreateTaskInput());
    dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
    if (onCancel) onCancel();
  }, [dispatch, onCancel]);

  const extractLinkObject = useCallback(async (htmlSnippet: string) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlSnippet;
    const linkElement = tempElement.querySelectorAll('a');
    const content = tempElement.querySelector('p');
    const extractedLinks: LinkObject[] = [];

    linkElement.forEach((linkElement) => {
      const href = linkElement.getAttribute('href');
      const name = linkElement.textContent?.trim() || '';

      extractedLinks.push({
        href: href || '',
        name: name.replace(/\s+/g, '-')
      });
    });
    return { content, extractedLinks };
  }, []);

  const loadFileData = useCallback(async () => {
    if (
      !isEmpty(createTaskInput?.id) &&
      (!isEmpty(createTaskInput?.description) || !isEmpty(createTaskInput?.uploadedFiles))
    ) {
      const fileName = createTaskInput?.description;
      if (fileName) extractLinkObject(fileName);
    }
  }, [createTaskInput, extractLinkObject]);

  useEffect(() => {
    loadFileData();
  }, [loadFileData]);

  const onCreateTask = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const taskInput: CreateTaskInputInterface = JSON.parse(JSON.stringify(createTaskInput));
      if (isEmpty(taskInput?.name)) {
        dispatch(setErrorMessage('Please add title for task'));
        return;
      }
      if (isEmpty(taskInput?.projectId?.id)) {
        dispatch(setErrorMessage('Please select a project for the task at the top of the modal.'));
        return;
      }
      try {
        setLoading(true);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const uploadedFiles: CommentFilesInterface[] = createTaskInput?.Files || [];
        let descriptionData = createTaskInput?.description || '';
        const pattern = /(^<p><br><\/p>\s*)|(<p><br><\/p>\s*$)/;
        if (!isEmpty(descriptionData)) {
          while (pattern.test(descriptionData)) {
            descriptionData = descriptionData.replace(pattern, '');
          }
          if (descriptionData.match(/<br\s*>\s*<\/p>$/))
            descriptionData = descriptionData.replace(/<br\s*>\s*<\/p>$/, '</p>');
        }
        const tempElement = document.createElement('div');
        tempElement.innerHTML = descriptionData || '';
        const linkElements = tempElement.querySelectorAll('img');
        let promises: Promise<void>[] = [];
        linkElements.forEach((linkElement) => {
          // Create a promise for each linkElement
          const myPromise = (async function () {
            const srclink = linkElement?.getAttribute('src');
            if (srclink && !isEmpty(srclink)) {
              if (
                !srclink?.startsWith('https://firebasestorage') &&
                !srclink?.startsWith('https://figma') &&
                !srclink?.startsWith('/static') &&
                !srclink?.startsWith('https://')
              ) {
                try {
                  const fileData = await convertBase64ToFile(srclink);
                  const fileUrl = await getFirebaseUrlFromFile({ file: fileData }, 'files/', dispatch);
                  if (fileUrl) {
                    descriptionData = descriptionData?.replace(srclink, fileUrl);
                  }
                } catch (error) {
                  console.error('Error processing image URL', error);
                }
              }
            }
          })(); // Immediately invoke the async function
          promises.push(myPromise);
        });
        // Wait for all promises to resolve
        await Promise.all(promises).then(() => {});
        let response;
        let taskDesc, taskDue;
        if (!isEmpty(taskInput?.id)) {
          if (isEmpty(taskInput?.description)) {
            taskDesc = descriptionData || '';
          } else {
            taskDesc = descriptionData;
          }
          taskDue = isEmpty(taskInput?.dueDate) ? undefined : taskInput?.dueDate;

          let subscribersClone = JSON.parse(JSON.stringify(taskInput?.taskSubscribers));
          const taskUsers = taskInput?.users
            ?.map((item) => {
              const userFromData = taskInput?.taskUsers?.find((x) => item?.id === x['_id']);
              if (isEmpty(userFromData)) {
                subscribersClone?.push(item?.id);
                return {
                  _id: item?.id,
                  CreatedBy: userDetails?.id,
                  CreatedTime: new Date().toISOString(),
                  UpdatedBy: userDetails?.id,
                  UpdatedTime: new Date().toISOString()
                };
              } else {
                return userFromData;
              }
            })
            .filter((user) => user !== undefined);

          const updatedFiles = taskInput?.Files?.map((file) => {
            const data = {
              fileType: file?.file_type,
              name: file?.name,
              href: file?.href,
              size: file?.size,
              uploadFileType: file?.uploadFileType
            };
            return data;
          });
          onChangeInputField('description', descriptionData);
          const labelIdList = createTaskInput?.labels?.map((item: { id: string }) => {
            return item?.id;
          });
          if (isTemplateProject) {
            const taskData = {
              id: taskInput?.id,
              companyId: workspace_id,
              createdOn: createTaskInput.createdOn,
              createdBy: createTaskInput.createdBy,
              updatedOn: new Date().toISOString(),
              updatedBy: userDetails?.id,
              groupId: projectId?.isGroupEnabled ? taskInput?.groupId?.id : null,
              isArchived: false,
              description: descriptionData,
              name: taskInput?.name,
              projectId: createTaskInput?.projectId?.id,
              projectName: createTaskInput?.projectId?.name,
              status: false,
              dueDate: taskDue,
              files: uploadedFiles,
              priority: projectTemplateDetails?.priority?.enabled ? taskInput?.priority : null,
              taskUsers: taskUsers,
              taskSubscribers: subscribersClone,
              statusId: projectId?.statusEnable
                ? createTaskInput?.statusId ?? projectId?.defaultStatus?.['_id']
                : undefined,
              estimate:
                typeof taskInput?.estimate === 'number'
                  ? taskInput?.estimate
                  : getFloatTimeValue(taskInput?.estimate || ''),
              labels: labelIdList || []
            };
            dispatch(updateTaskInProjectTemplate({ taskId: taskInput?.id, value: taskData }));
            dispatch(clearCreateTaskInput());
            dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
            dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: [] }));
            if (onCancel) onCancel();
            return;
          }

          const payloadTask = {
            projectId: projectId?.id,
            name: taskInput?.name,
            description: taskDesc || '',
            dueDate: !isEmpty(taskDue) ? taskDue : null,
            dueDateUnset: isEmpty(taskDue) ? true : null,
            taskUsers: taskUsers?.map((x) => x?.['_id']),
            groupId:
              projectId?.isGroupEnabled && !isEmpty(createTaskInput?.groupId?.id) ? createTaskInput?.groupId?.id : null,
            estimateTime:
              typeof taskInput?.estimate === 'number'
                ? taskInput?.estimate
                : getFloatTimeValue(taskInput?.estimate || ''),
            priority: currentProject?.priority?.enabled ? createTaskInput?.priority : null,
            files: !isEmpty(taskInput?.Files) ? updatedFiles : [],
            milestoneId: !isEmpty(createTaskInput?.milestoneId?.id) ? createTaskInput?.milestoneId?.id : undefined,
            milestoneIdUnset: isEmpty(createTaskInput?.milestoneId?.id) ? true : null,
            labels: labelIdList,
            statusId: projectId?.statusEnable ? createTaskInput?.statusId || projectId?.defaultStatus?.['_id'] : null
          };
          if (isRecurringTask) {
            response = await dispatch(updateRecurringTaskDetails(taskInput?.id, payloadTask));
          } else {
            response = await dispatch(updateTaskDetails(taskInput?.id, payloadTask));
            await dispatch(
              createNewComment(taskInput?.id, {
                Type: COMMENT_TYPE.UPDATE_TASK
              })
            );
          }
          trackAnalyticActivity(TASK_ANALYTICS.UPDATED);
        } else {
          onChangeInputField('description', descriptionData);
          const labelIdList = createTaskInput?.labels?.map((item) => {
            return item?.id;
          });
          if (isTemplateProject) {
            const uniqueId = nanoid();
            const taskUsers = taskInput?.users?.map((item) => {
              return {
                _id: item?.id,
                CreatedBy: userDetails?.id,
                CreatedTime: new Date().toISOString(),
                UpdatedBy: userDetails?.id,
                UpdatedTime: new Date().toISOString()
              };
            });
            let subscribersClone = [userDetails?.id];
            taskInput?.users?.forEach((item) => {
              if (item?.id !== userDetails?.id) {
                subscribersClone?.push(item?.id);
              }
            });
            const taskData = {
              id: String(uniqueId),
              companyId: workspace_id,
              createdOn: new Date().toISOString(),
              createdBy: userDetails?.id,
              updatedOn: new Date().toISOString(),
              updatedBy: userDetails?.id,
              groupId: projectId?.isGroupEnabled ? taskInput?.groupId?.id : null,
              isArchived: false,
              description: descriptionData,
              name: taskInput?.name,
              projectId: createTaskInput?.projectId?.id,
              projectName: createTaskInput?.projectId?.name,
              status: false,
              dueDate: taskInput?.dueDate,
              files: !isEmpty(uploadedFiles) ? uploadedFiles : [],
              priority: projectTemplateDetails?.priority?.enabled ? taskInput?.priority ?? 0 : 0,
              taskUsers: taskUsers,
              taskSubscribers: subscribersClone,
              estimate: getFloatTimeValue(taskInput?.estimate || ''),
              labels: labelIdList || [],
              statusId: projectId?.statusEnable
                ? createTaskInput?.statusId ?? projectId?.defaultStatus?.['_id']
                : undefined
            };
            dispatch(addTaskInProjectTemplate(taskData));
            dispatch(clearCreateTaskInput());
            dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
            dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: [] }));
            if (onCancel) onCancel();
            return;
          }
          const taskUsers = taskInput?.users?.map((item) => {
            return item?.id;
          });
          const updatedFileName = uploadedFiles?.map((item) => {
            return {
              name: item?.name,
              href: item?.href,
              size: item?.size,
              fileType: item?.file_type,
              uploadFileType: item?.uploadFileType
            };
          });
          const payload: any = {
            name: taskInput?.name,
            projectId: createTaskInput?.projectId?.id,
            groupId: projectId?.isGroupEnabled ? taskInput?.groupId?.id : null,
            dueDate: taskInput?.dueDate,
            description: descriptionData,
            status: false,
            taskUsers: taskUsers,
            estimateTime:
              typeof taskInput?.estimate === 'number'
                ? taskInput?.estimate
                : getFloatTimeValue(taskInput?.estimate || ''),
            priority: currentProject?.priority?.enabled ? taskInput?.priority : null,
            files: !isEmpty(uploadedFiles) ? updatedFileName : [],
            milestoneId: !isEmpty(createTaskInput?.milestoneId?.id) ? createTaskInput?.milestoneId?.id : null,
            labels: labelIdList || [],
            parentTaskId: !isEmpty(createTaskInput?.parentTaskId) ? createTaskInput?.parentTaskId : null,
            statusId: projectId?.statusEnable
              ? createTaskInput?.statusId ?? projectId?.defaultStatus?.['_id']
              : undefined
          };

          if (createTaskInput?.recurringInterval) {
            payload.recurringInterval = createTaskInput.recurringInterval?.toLowerCase();
            payload.start = createTaskInput.start?.toLowerCase();
          }
          response = await dispatch(createNewTask(payload));
          const eventProperties = {
            source: isFromShortcut ? 'shourtcut' : isFromMyTask ? 'my task' : 'task list'
          };
          trackAnalyticActivity(TASK_ANALYTICS.CREATED, eventProperties);
        }
        if (response) {
          if (!isEmpty(taskInput?.id) && istaskDetail && !isFromSubList) {
            history.push(`/projects/details/${projectId?.id}/tasks?task=${taskInput?.id}`);
          }
          dispatch(clearCreateTaskInput());
          dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
          dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: [] }));
          if (onCancel) onCancel();
          if (createTaskInput?.projectId?.id !== params?.id) {
            const newProjectId = createTaskInput?.projectId?.id;
            await dispatch(updateIndexDbProjectTasks(newProjectId, workspace?.id));
          }
          if (
            onProjectChange &&
            createTaskInput?.projectId?.id !== params?.id &&
            istaskDetail &&
            !isTaskDetailPreview
          ) {
            onProjectChange(true);
          } else {
            if (loadData) loadData();
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createTaskInput,
      createTaskInput?.statusId,
      createTaskInput?.parentTaskId,
      dispatch,
      onChangeInputField,
      isTemplateProject,
      projectId?.id,
      projectId?.isGroupEnabled,
      projectId?.statusEnable,
      projectId?.defaultStatus,
      currentProject?.priority?.enabled,
      isRecurringTask,
      workspace_id,
      projectTemplateDetails?.priority?.enabled,
      onCancel,
      isFromShortcut,
      isFromMyTask,
      istaskDetail,
      isFromSubList,
      params?.id,
      isTaskDetailPreview,
      history,
      onProjectChange,
      loadData
    ]
  );

  const onUpdateStatus = useCallback(
    async (status: StatusListInterface, previousStatus: StatusListInterface) => {
      if (status['_id'] !== previousStatus['_id']) {
        dispatch(updateCreateTaskInput({ propsName: 'statusId', value: status['_id'] }));
      }
    },
    [dispatch]
  );

  const handleFileInput = useCallback(() => {
    queueMicrotask(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    });
  }, []);

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (!files || files.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }

      try {
        setFileUploading(true);
        const fileArray = Array.from(files); // Convert FileList to Array

        //call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(createTaskInput?.Files || [], fileArray, dispatch, inlineRef);
        const value = [...(createTaskInput?.Files || []), ...(updatedFiles || [])];
        setUpdatedFile(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setFileUploading(false);
      }
    },
    [createTaskInput?.Files, dispatch]
  );

  const onSelectFile = useCallback(
    (item: any) => {
      const parts = item?.name?.split('.');
      const extension = parts[parts.length - 1];
      let fileUrl;
      if (item?.uploadFileType === FILE_TYPES.DOCS || item?.uploadFileType === FILE_TYPES.WHITEBOARD) {
        if (!isEmpty(item?.parentId) && !isEmpty(item?.id)) {
          fileUrl = `/projects/files/${params?.id}/docs/${item?.id}?folder=${item?.parentId}`;
        } else if (!isEmpty(item?.id) && isEmpty(item?.parentId)) {
          fileUrl = `/projects/files/${params?.id}/docs/${item?.id}`;
        }
      } else {
        fileUrl = item?.url;
      }
      const sizeInMB = item?.size / (1024 * 1024);
      let sizeValue;
      if (sizeInMB >= 1) {
        sizeValue = `${sizeInMB.toFixed(2)} MB`;
      } else {
        const sizeInKB = item?.size / 1024;
        sizeValue = `${sizeInKB.toFixed(2)} KB`;
      }
      if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
        inlineRef.current.addImageInContent(fileUrl);
      } else if (extension === 'mp4' || extension === 'mov' || extension === 'mkv' || extension === 'webm') {
        inlineRef.current.addRecordingCard(fileUrl, item?.name);
      } else {
        const newObj: CommentFilesInterface = {
          name: item?.name,
          href: fileUrl || '',
          size: sizeValue,
          file_type:
            item?.uploadFileType === FILE_TYPES.DOCS
              ? 'docs'
              : item?.uploadFileType === FILE_TYPES.WHITEBOARD
              ? 'white'
              : extension,
          isCreatedDoc: true,
          uploadFileType: Number(item?.uploadFileType)
        };
        dispatch(updateCreateTaskInput({ propsName: 'Files', value: [...(createTaskInput.Files || []), newObj] }));
      }
    },
    [createTaskInput.Files, dispatch, params?.id]
  );

  const attachmentPage = useCallback(() => {
    const withoutParentData = projectAllFiles?.filter((x) => isEmpty(x?.parentId));
    if (!projectId?.name) {
      dispatch(setErrorMessage('Please select the project'));
      return;
    }
    dispatch(setProjectFiles(withoutParentData));
    if (mobile) {
      setSelectFile(true);
    } else {
      setAttachment(true);
    }
  }, [dispatch, mobile, projectAllFiles, projectId?.name]);

  const handleOpenSnapModal = useCallback(() => {
    setIsSnapModalOpen(true);
  }, []);

  const handleCloseSnapModal = useCallback(() => {
    setIsSnapModalOpen(false);
  }, []);

  // render milestone data
  const renderMilestoneData = useMemo(() => {
    return projectTaskMilestoneList?.map((mileStone) => (
      <DropdownItem
        key={mileStone?.id}
        label={mileStone?.milestoneName}
        onClick={() => {
          if (createTaskInput?.milestoneId?.id === mileStone?.id) {
            dispatch(updateCreateTaskInput({ propsName: 'milestoneId', value: null }));
          } else {
            dispatch(updateCreateTaskInput({ propsName: 'milestoneId', value: mileStone }));
          }
        }}></DropdownItem>
    ));
  }, [createTaskInput?.milestoneId?.id, dispatch, projectTaskMilestoneList]);

  if (selectFile) {
    return (
      <RenderExistingFile
        setAttachment={() => setAttachment(false)}
        setSelectFile={(value: boolean) => setSelectFile(value)}
        isCreateTaskModal={true}
        onSelectFile={onSelectFile}
      />
    );
  }

  const onClose = () => {
    setAttachment(false);
  };

  const templateData = taskTemplateList.map((template) => ({
    key: template['_id'] || '',
    label: template.Title || 'undefined'
  }));

  const handleDocumentIconClick = (templateId: string | number | undefined) => {
    const taskInput: CreateTaskInputInterface = JSON.parse(JSON.stringify(createTaskInput));
    if (isEmpty(taskInput?.projectId?.id)) {
      dispatch(setErrorMessage('Please select a project for the task at the top of the modal.'));
      return;
    }
    const selectedTemplate = taskTemplateList.find((template) => template['_id'] === templateId);
    let assignedUsers: UsersWorkspaceInterface[] = [];
    assignedUsers =
      (selectedTemplate?.Assignee?.map((assigneeId: string) =>
        workspace?.users?.find((user: UsersWorkspaceInterface) => user?.id === assigneeId)
      ) as UsersWorkspaceInterface[]) || [];
    const matchedProjectUsers = assignedUsers?.filter((assignedUser: UsersWorkspaceInterface) =>
      projectId?.users?.some((projectUser: UsersWorkspaceInterface) => projectUser?.id === assignedUser?.id)
    );
    if (selectedTemplate) {
      dispatch(
        setCreateTaskInput({
          ...(taskInput || {}),
          description: selectedTemplate?.Description || '',
          name: selectedTemplate?.Title || '',
          priority: selectedTemplate?.Priority || 0,
          users: matchedProjectUsers || [],
          Files: selectedTemplate?.Attachment
        })
      );
    }
  };

  return (
    <>
      <Modalhead>
        <Modalheadleft>
          <RenderProjectList isFromMyTask />
          {projectId?.isMilestone && projectTaskMilestoneList?.length > 0 && (
            <>
              <SVGIcon name='dot-icon' viewBox='0 0 15 15' width='15' height='15' className='dot-icon' />
              <Dropdown
                content={
                  <Headtitle>
                    {!isEmpty(createTaskInput?.milestoneId)
                      ? createTaskInput?.milestoneId?.milestoneName
                      : 'Select Milestone'}
                  </Headtitle>
                }
                trigger='click'>
                {renderMilestoneData}
              </Dropdown>
            </>
          )}
        </Modalheadleft>
        <Icon onClick={clearData}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='svgicon' />
        </Icon>
      </Modalhead>
      <Inputs>
        <Textfied>
          <TextArea
            style={{ minHeight: 'auto' }}
            className='taskarea'
            placeholder='Task Title'
            autoSize
            autoFocus={true}
            value={createTaskInput?.name}
            onChange={(e) => onChangeInputField('name', e.target.value)}
          />
        </Textfied>
        <Textarea>
          <DescriptionSection>
            <DescriptionInputBox
              ref={inlineRef}
              valueContent={createTaskInput?.description!}
              setValueContent={(value: string) => dispatch(updateCreateTaskInput({ propsName: 'description', value }))}
              uploadedFiles={createTaskInput?.Files || []}
              setUploadedFiles={setUpdatedFile}
              setPropsLoading={(value: boolean) => setLoading(value)}
              setStateLoading={(value: boolean) => setFileUploading(value)}
              handleOpenSnapModal={handleOpenSnapModal}
              handleFileInput={handleFileInput}
            />
          </DescriptionSection>
        </Textarea>
      </Inputs>
      {fileUploading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
      <Btns>
        <Icons>
          {(isTemplateProject
            ? projectTemplateDetails?.statusEnable && projectTemplateDetails?.statusData?.length > 0
            : projectId?.statusEnable && projectId?.statusData?.length > 0) && (
            <TaskStatusDropdown
              defaultStatus={projectId?.defaultStatus}
              statusList={projectId?.statusData}
              currentStatusId={createTaskInput?.statusId}
              isCreateTask={true}
              onUpdateStatus={(status, previousStatus) => onUpdateStatus(status, previousStatus)}
            />
          )}
          <Detail>
            <AssignBox
              setIsDropdownOpen={setIsAssignDropdownOpen}
              isDropdownOpen={isAssignDropdownOpen}
              isHumanIcon={true}
              projectUsers={projectId?.users}
              isUpdateReducer={true}
              isCreateTaskModal={true}
              isMultiAssignee={createTaskInput?.projectId?.multiAsignee || createTaskInput?.projectId?.multiAssignee}
            />
          </Detail>
          {!isTemplateProject && (
            <Detail>
              <CalendarPopUp
                isUpdateReducer={true}
                loadData={loadData}
                isRecurringTask={isRecurringTask}
                isHideText={true}
                data={data}></CalendarPopUp>
            </Detail>
          )}
          {projectId?.isGroupEnabled && (
            <Detail>
              <Statusdropdown isCreateTaskModal value={createTaskInput?.groupId} />
            </Detail>
          )}
          {projectId?.isEstimate && (
            <Detail>
              <Estimatedropdown
                isEstimateDropdownOpen={isEstimateDropdownOpen}
                setIsEstimateDropdownOpen={(value: boolean) => setIsEstimateDropdownOpen(value)}
                createTask={true}
                taskData={createTaskInput}
                setEstimate={(value: string) => dispatch(updateCreateTaskInput({ propsName: 'estimate', value }))}
              />
            </Detail>
          )}

          {projectId?.priority?.enabled && (
            <Detail>
              <Prioritydropdown taskData={createTaskInput} icon={true} currentProject={projectId} isCreateTask={true} />
            </Detail>
          )}

          {projectId?.labelsEnabled && (
            <LabelDropdown taskData={createTaskInput} currentProject={projectId} isCreateTask={true} />
          )}
        </Icons>

        <ButtonDiv>
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
          <div style={{ display: 'flex', gap: 4 }}>
            {!createTaskInput?.id && taskTemplateList?.length > 0 && (
              <Dropdown
                content={
                  <DocumentIcon>
                    <SVGIcon
                      name='document-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 18 18'
                      className='template-doc-icon'
                    />
                  </DocumentIcon>
                }
                trigger='click'>
                {templateData.map((item) => (
                  <DropdownItem
                    key={item.key}
                    label={item.label}
                    onClick={(key) => handleDocumentIconClick(item.key)}
                  />
                ))}
              </Dropdown>
            )}
            <Dropdown
              activeClassName='active'
              content={
                <AttachFile>
                  <SVGIcon name='attachment-icon' width='16' height='16' viewBox='0 0 18 18' className='svg' />
                </AttachFile>
              }
              trigger='click'>
              <CommentDropdown onClickUploadFile={handleFileInput} onClickSelectFile={attachmentPage} />
            </Dropdown>
          </div>
          <Button
            onClick={onCreateTask}
            title={createTaskInput?.id ? 'Update Task' : 'Create Task'}
            isLoading={loading || fileUploading}
            size={'small'}
          />
          {/* select file drawer */}
          <Drawer
            placement='right'
            onClose={onClose}
            open={attachment}
            width='350px'
            bodyStyle={{ padding: 0, scrollbarWidth: 'none' }}>
            <RenderExistingFile
              setAttachment={() => setAttachment(false)}
              isCreateTaskModal
              onSelectFile={onSelectFile}
            />
          </Drawer>
        </ButtonDiv>
      </Btns>
      {isEstimateDropdownOpen && (
        <OverLayDiv className='dropdown-overlay' onClick={() => setIsEstimateDropdownOpen(false)} />
      )}

      <ModalCustom open={closeModel} onClose={close} width={334}>
        <Deletemodal
          isYes={true}
          onClose={close}
          onOk={onOnkDeleteModel}
          modaltitle='Confirmation Model'
          description='Are you sure you want close this model?'
        />
      </ModalCustom>
      {isAssignDropdownOpen && (
        <OverLayDiv
          className='dropdown-overlay'
          onClick={() => {
            setIsAssignDropdownOpen(false);
          }}
        />
      )}
      {isMediaRecordingSupported() && (
        <ModalCustom open={isSnapModalOpen} onClose={handleCloseSnapModal} width={258}>
          <SnapRecordModal handleCloseModal={handleCloseSnapModal} />
        </ModalCustom>
      )}
    </>
  );
};

export default CreateTaskModal;
